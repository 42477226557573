import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import {
  ButtonEmpty,
  Comparators,
  Direction,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPageHeader,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from 'ui';
import Currency from '@app/components/Currency/Currency';
import { SOV_LABEL, STREAMS_LABEL } from '@app/components/Layout/SideNav/constants';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { RenewalFlyout } from '@app/cx/Properties/RenewalFlyout/RenewalFlyout';
import AddStreamModal from '@app/cx/Stream/AddModal/AddStreamModal';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { useGetStreamsQuery } from '@app/graphql/queries/streams/__generated__/GetStreams.generated';
import { IGraphQLStream } from '@app/queries/streams/types';
import { formatDate } from '@app/utils/format';
import { Helmet } from '../Helmet';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import NoSovPage from '../NoSovPage/NoSovPage';
import SovProcessingPage from '../SovProcessingPage/SovProcessingPage';
import { ProcessingBadge, StyledBadge } from './Portfolios.emotion';
import { makeEffectiveStreams } from './utils';

export const Portfolios = () => {
  const { showFlyout } = useFlyout();
  const { account } = useAuth();
  const { stream, permissions, loading: streamLoading } = useStreamContext();
  const [sortField, setSortField] = useState<string>('effectiveDate');
  const [sortDirection, setSortDirection] = useState<Direction>('desc');
  const [touched, setTouched] = useState(false);
  const isAdmin = account?.permissions?.admin;
  const hasNonViewManagedOrgs = account?.managedOrgs?.some(
    (o) => o.editDocuments || o.editProjects || o.editProperties || o.editSubmissions,
  );
  const { selectedOrganization } = useContext(UserSessionContext);
  const organizationName = selectedOrganization?.name || account?.docUploadOrgs?.[0]?.name || null;
  const useOrgInQuery = !!(isAdmin || hasNonViewManagedOrgs);

  const history = useHistory();
  const { loading, error, data, refetch } = useGetStreamsQuery({
    skip: useOrgInQuery && !organizationName,
    variables: {
      orgName: useOrgInQuery ? organizationName : null,
    },
  });

  const { loading: orgInfoLoading, data: orgInfoData } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  const [isModalShown, setIsModalShown] = useState(false);

  if (streamLoading || loading || orgInfoLoading) {
    return <LoadingSpinnerV2 variant="xxl" label="Loading" dataTestId="loading-spinner" />;
  }

  const isEnterpriseAccount = orgInfoData?.orgInfo?.orgInfo?.orgLevel === 'Enterprise';

  if (isEnterpriseAccount) {
    history.push('/home');
  }

  if (error) {
    return (
      <EuiPanel paddingSize="l" hasBorder={false} hasShadow={false}>
        <EuiPageHeader pageTitle={`You have no ${STREAMS_LABEL}`} />
      </EuiPanel>
    );
  }

  const onTableChange = ({ sort }: any) => {
    if (sort) {
      setSortField(sort?.field);
      setSortDirection(sort?.direction);
      setTouched(true);
    }
  };

  const hasNoSOV = data?.streamsV2?.streams?.length === 0 || !data?.streamsV2?.streams;

  const hasOneProcessingSOV =
    data?.streamsV2?.streams?.length === 1 &&
    data?.streamsV2?.streams[0].submissionState === 'Processing In Progress';

  const streamName = data?.streamsV2?.streams?.[0]?.name || '';
  if (hasOneProcessingSOV) {
    return <SovProcessingPage streamName={streamName} />;
  }

  if (hasNoSOV) {
    return <NoSovPage />;
  }

  const isProcessing = stream?.submissionStatus === 'Processing In Progress';
  const renderBadge = (stream: IGraphQLStream) => {
    if (isProcessing) {
      return <ProcessingBadge>Processing</ProcessingBadge>;
    }
    if (stream.expiryDate && moment.unix(stream.expiryDate).isBefore(moment())) {
      return <StyledBadge>Expired</StyledBadge>;
    }
    return null;
  };

  const effectiveStreams = makeEffectiveStreams(data);

  let items = [];

  if (sortField && touched) {
    items = effectiveStreams
      .slice(0)
      .sort(Comparators.property(sortField, Comparators.default(sortDirection)));
  } else {
    items = effectiveStreams;
  }
  const columns = [
    {
      field: 'name',
      name: 'Name',
      render: (name, stream) => (
        <EuiFlexGroup>
          <EuiFlexItem grow={4}>{name}</EuiFlexItem>
          {renderBadge(stream)}
        </EuiFlexGroup>
      ),
      sortable: true,
      width: '40%',
    },
    {
      field: 'brokerEmail',
      name: 'Primary Contact',
      render: (brokerEmail) => (
        <EuiFlexGroup>
          {brokerEmail ? <EuiLink href={`mailto:${brokerEmail}`}>{brokerEmail}</EuiLink> : '-'}
        </EuiFlexGroup>
      ),
      sortable: true,
      width: '20%',
    },
    {
      field: 'totalInsuredValue',
      name: `TIV ${stream?.displayCurrency ? `(${stream?.displayCurrency})` : ''}`,
      render: (tiv, stream) => (
        <EuiText size="s">
          {isProcessing ? null : <Currency value={tiv} currency={stream.displayCurrency} />}
        </EuiText>
      ),
      sortable: true,
      width: '15%',
    },
    {
      field: 'propertiesCount',
      name: '# properties',
      render: (propertiesCount) =>
        isProcessing ? null : <EuiText size="s">{propertiesCount}</EuiText>,
      sortable: true,
      width: '15%',
    },
    {
      field: 'effectiveDate',
      name: 'Effective Date',
      render: (effectiveDate) => <EuiText size="s">{formatDate(effectiveDate)}</EuiText>,
      sortable: true,
      width: '15%',
    },
    {
      field: 'expiryDate',
      name: 'Expiry Date',
      render: (expiryDate) => <EuiText size="s">{formatDate(expiryDate)}</EuiText>,
      sortable: true,
      width: '15%',
    },
  ];

  const getRowProps = (row) => ({
    className: `customRowClass ${
      row.expiryDate && moment.unix(row.expiryDate || 0).isBefore(moment()) ? 'expired' : ''
    }`,
    onClick: () => {
      history.push(`/streams/${row.slug}`);
    },
    style: { color: 'red' },
  });

  const isContributor = !orgInfoData?.orgInfo?.permissionsV2?.find(
    (permission) => permission.resource === 'Portfolio' && permission.action === 'View',
  );
  const pagetitle = !isContributor ? STREAMS_LABEL : 'My Properties';

  const sorting = {
    sort: {
      direction: sortDirection as Direction,
      field: sortField as any,
    },
  };

  if (isContributor) {
    return (
      <EuiPanel paddingSize="xl" hasBorder={false} hasShadow={false}>
        <Helmet title={pagetitle} />
        <EuiPageHeader
          pageTitle={pagetitle}
          rightSideItems={[
            (isAdmin || permissions.canEditAllProperties) && (
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiFlexItem data-testid="renew-sov-btn" grow={false}>
                  <ButtonEmpty
                    iconName="refresh"
                    onClick={() =>
                      showFlyout(
                        <RenewalFlyout
                          streamList={data.streamsV2.streams as unknown as IGraphQLStream[]}
                          source="click"
                          refetch={refetch}
                        />,
                      )
                    }
                    size="s"
                    label={`Renew ${SOV_LABEL}`}
                  />
                </EuiFlexItem>
                {isAdmin && (
                  <EuiFlexItem data-testid="add-stream-btn" grow={false}>
                    <ButtonEmpty
                      iconName="plusCircle"
                      onClick={() => setIsModalShown(true)}
                      size="s"
                      label={`Add ${SOV_LABEL}`}
                    />
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            ),
          ]}
        />
        <EuiSpacer size="s" />
        <EuiPanel paddingSize="l" hasShadow={false}>
          <EuiBasicTable
            data-testid="portfolio-my-properties"
            columns={[
              { ...columns[0], width: '41%' },
              { ...columns[1], width: '14%' },
              { ...columns[2], width: '45%' },
            ]}
            items={data?.streamsV2?.streams || []}
            rowProps={getRowProps}
          />
        </EuiPanel>
      </EuiPanel>
    );
  }
  return (
    <EuiPanel paddingSize="xl" hasBorder={false} hasShadow={false}>
      <Helmet title={STREAMS_LABEL} />
      <EuiPageHeader
        pageTitle={STREAMS_LABEL}
        rightSideItems={[
          (isAdmin || permissions.canEditAllProperties) && (
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem data-testid="renew-sov-btn" grow={false}>
                <ButtonEmpty
                  iconName="refresh"
                  onClick={() =>
                    showFlyout(
                      <RenewalFlyout
                        streamList={data.streamsV2.streams as unknown as IGraphQLStream[]}
                        source="click"
                        refetch={refetch}
                      />,
                    )
                  }
                  size="s"
                  label={`Renew ${SOV_LABEL}`}
                />
              </EuiFlexItem>
              {isAdmin && (
                <EuiFlexItem data-testid="add-stream-btn" grow={false}>
                  <ButtonEmpty
                    iconName="plusCircle"
                    onClick={() => setIsModalShown(true)}
                    size="s"
                    label={`Add ${SOV_LABEL}`}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          ),
        ]}
      />
      <EuiSpacer size="s" />
      <EuiPanel paddingSize="l" hasShadow={false}>
        <EuiBasicTable
          data-testid="portfolio-my-properties"
          columns={columns}
          items={items}
          rowProps={getRowProps}
          sorting={sorting}
          onChange={onTableChange}
        />
        {isModalShown && (
          <AddStreamModal
            hasMyPropertiesStream={false}
            onCloseModal={() => setIsModalShown(false)}
          />
        )}
      </EuiPanel>
    </EuiPanel>
  );
};
