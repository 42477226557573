import { createContext, ReactNode, useContext } from 'react';
import { ApolloError } from '@apollo/client';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { ValueCollectionConfig } from '@app/graphql/precheck/precheck.types';
import { useGetValueCollectionConfigQuery } from '@app/graphql/precheck/queries/getValueCollectionConfig/__generated__/getValueCollectionConfig.generated';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';

interface ValueCollectionConfigContextType {
  // Add your context state types here
  loading: boolean;
  error: ApolloError | null;
  config: ValueCollectionConfig;
  refetch?: () => Promise<any>;
}

const ValueCollectionConfigContext = createContext<ValueCollectionConfigContextType>({
  config: null,
  error: null,
  loading: false,
  refetch: () => Promise.resolve(),
});

interface ValueCollectionConfigProviderProps {
  children: ReactNode;
}

export const ValueCollectionConfigProvider = ({ children }: ValueCollectionConfigProviderProps) => {
  const client = usePrecheckClient();
  const { selectedOrganization } = useUserSession();

  const { data, loading, error, refetch } = useGetValueCollectionConfigQuery({
    client,
    variables: {
      input: {
        active: true,
        orgName: selectedOrganization?.name,
      },
    },
  });

  const activeConfig = data?.getValueCollectionConfig?.configs?.find((config) => config.active);

  const value = {
    config: activeConfig,
    error,
    loading,
    refetch,
  };

  return (
    <ValueCollectionConfigContext.Provider value={value}>
      {children}
    </ValueCollectionConfigContext.Provider>
  );
};

export const useValueCollectionConfig = () => useContext(ValueCollectionConfigContext);

export default ValueCollectionConfigContext;
