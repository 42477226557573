import { useState } from 'react';
import * as React from 'react';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiText,
  Spacer,
} from 'ui';
import { useAttributeMetadataEditorContext } from '../context/attributeMetadataEditorContext';
import { GroupedMetadata, HandleChange, SelectOption } from '../types';
import EditAttributeModalButtons from './EditAttributeModalButtons';
import EditAttributeModalGrid from './EditAttributeModalGrid';
import EditAttributeModalPropertyModal from './EditAttributeModalPropertyModal';
import EditAttributeModalRMCustom from './EditAttributeModalRMCustom';
import EditAttributeModalStandard from './EditAttributeModalStandard';

interface Props {
  parentOptions: SelectOption[];
}

const EditAttributeModal: React.FC<Props> = ({ parentOptions }) => {
  const { selectedAttribute, setSelectedAttribute } = useAttributeMetadataEditorContext();
  const [attributeValue, setAttributeValue] = useState<GroupedMetadata>({
    ...selectedAttribute.attribute,
  });
  const [dimension, setDimension] = useState<string | null>(attributeValue.dimension || null);
  const [subDimension, setSubDimension] = useState<string | null>(
    attributeValue.subdimension || null,
  );
  const scopeType = selectedAttribute.attribute.isCustom ? 'consumer' : attributeValue.scope;
  const [scope, setScope] = useState<string | null>(scopeType);

  const onClose = () => setSelectedAttribute(undefined);

  const handleChange: HandleChange = (fieldName, value) => {
    setAttributeValue((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <EuiModal data-testid="edit-attribute-modal" onClose={onClose}>
      <EuiModalHeader>
        <EuiText>
          <h3>{`Attribute: ${selectedAttribute.attribute.name}`}</h3>
        </EuiText>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm>
          <EuiFlexGroup direction="column">
            <EditAttributeModalStandard
              attributeValue={attributeValue}
              handleChange={handleChange}
            />
            <EuiFlexItem grow={false}>
              <Spacer size="s" />
            </EuiFlexItem>
            <EditAttributeModalGrid
              attributeValue={attributeValue}
              handleChange={handleChange}
              parentOptions={parentOptions}
            />
            {selectedAttribute.attribute.isCustom && (
              <>
                <EuiFlexItem grow={false}>
                  <Spacer size="s" />
                </EuiFlexItem>
                <EditAttributeModalPropertyModal
                  dimension={dimension}
                  setDimension={setDimension}
                  setSubDimension={setSubDimension}
                  subDimension={subDimension}
                />
                <EuiFlexItem grow={false}>
                  <Spacer size="s" />
                </EuiFlexItem>
                <EditAttributeModalRMCustom scope={scope} setScope={setScope} />
              </>
            )}
          </EuiFlexGroup>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EditAttributeModalButtons
          attributeValue={attributeValue}
          dimension={dimension}
          onClose={onClose}
          parentAttribute={selectedAttribute.parentAttribute}
          scope={scope}
          subDimension={subDimension}
        />
      </EuiModalFooter>
    </EuiModal>
  );
};

export default EditAttributeModal;
