import { Component, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import useLogger from '@app/hooks/useLogger';
import ErrorContainer from './ErrorContainer';

interface IErrorBoundaryProps {
  children: ReactElement;
  logger: any;
  history: any;
}

interface IErrorBoundaryState {
  error?: Error;
  eventId?: string;
}

function withHooks(Component) {
  return function WrappedComponent(props) {
    const logger = useLogger();
    const history = useHistory();
    return <Component {...props} logger={logger} history={history} />;
  };
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined, eventId: undefined };
  }

  componentDidCatch(error: Error, errorInfo: object) {
    // eslint-disable-next-line no-console
    console.error('Fatal Error: caught in ErrorBoundary', error, errorInfo);
    this.props.logger.error('Fatal Error', { e: error, errorInfo: errorInfo });

    this.setState({ error });
  }

  render() {
    const { children } = this.props;

    this.props.history.listen(() => {
      this.setState({ error: undefined });
    });

    const { error } = this.state;

    if (error) {
      return <ErrorContainer />;
    }

    return children;
  }
}

export default withHooks(ErrorBoundary);
