import { Dispatch, SetStateAction } from 'react';
import * as React from 'react';
import { Checkbox, EuiFlexGroup, EuiFlexItem, EuiText, Spacer } from 'ui';
// import { STREAMS_LABEL } from '@app/components/Layout/constants';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { SettingsDisplay, SettingsState } from './types';

interface Props {
  display: SettingsDisplay[];
  derivedFields?: string[];
  loading: boolean;
  settingsState: SettingsState;
  setSettingsState: Dispatch<SetStateAction<SettingsState>>;
}

const SubmissionSettingsBody: React.FC<Props> = ({
  display,
  loading,
  settingsState,
  setSettingsState,
}) => {
  const disabledCheckboxes = new Set(['name', 'effectiveDate']);
  const handleEnabledCheck = (id: string, checked: boolean) => {
    setSettingsState((prevState) => ({
      ...prevState,
      [id]: {
        enabled: checked,
      },
    }));
  };

  if (loading) {
    return <LoadingSpinnerV2 dataTestId="submission-settings-modal-body-spinner" />;
  }

  return (
    <EuiFlexGroup
      data-testid="submission-settings-modal-body-content"
      direction="column"
      gutterSize="s"
    >
      {display.map((displayGroup) => (
        <>
          <EuiFlexItem>
            <EuiText>{displayGroup.label || 'General'}</EuiText>
          </EuiFlexItem>
          {displayGroup.settings.map((setting) => (
            <>
              <EuiFlexItem key={`${setting.id}-enabled`}>
                <Checkbox
                  checked={settingsState[setting.id]?.enabled}
                  id={`${setting.id}-enabled`}
                  label={setting.label}
                  onChange={(event) => handleEnabledCheck(setting.id, event.target.checked)}
                  data-testid={`checkbox-enable-${setting.label}`}
                  disabled={disabledCheckboxes.has(setting.id)}
                />
              </EuiFlexItem>
            </>
          ))}
          <Spacer />
        </>
      ))}
    </EuiFlexGroup>
  );
};

export default SubmissionSettingsBody;
