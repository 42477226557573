import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTextColor, EuiTitle, Icon } from 'ui';

const StyledContainer = styled.div`
  margin: auto;
`;
const ErrorContainer: FC = () => (
  <StyledContainer>
    <EuiFlexGroup gutterSize="m" alignItems="center">
      <EuiFlexItem grow={false}>
        <Icon color="danger" name="alertCircle" size="xxxl" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiTitle size="l">
          <h1>Oops... something went wrong</h1>
        </EuiTitle>
        <EuiSpacer size="s" />
        <EuiText size="m">
          <EuiTextColor color="subdued">
            Please try again later. Or open a <Link to="/support">support ticket</Link> to get help.
          </EuiTextColor>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  </StyledContainer>
);

export default ErrorContainer;
