import { cloneElement, ReactElement, useState } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';
import { EuiPopover } from 'ui';
import DocumentLink from '@app/components/PropertiesGrid/GridCell/SourceLink/DocumentLink';
import { ProvenanceType } from '@app/components/PropertiesGrid/PropertiesGridTooltip/types';
import { useTracker } from '@app/hooks/useTracker';

const StyledPopover = styled(EuiPopover)<{ isFullWidth: boolean }>`
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};

  > div {
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};
  }
`;

interface Props {
  children: ReactElement;
  provenance: any;
  isFullWidth?: boolean;
}

const ProvenanceTooltip: React.FC<Props> = ({ children, provenance, isFullWidth = true }) => {
  const [isContentHovered, setIsContentHovered] = useState<boolean>(false);
  const [isChildHovered, setIsChildHovered] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const tracker = useTracker();

  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const id = setTimeout(() => setIsChildHovered(true), 500);
    setTimeoutId(id);
  };

  const handleMouseLeave = () => {
    if (timeoutId) {
      // cancels any mouse enter events to prevent flickering of popover
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    // This timeout is needed to allow time to hover over the content
    setTimeout(() => setIsChildHovered(false), 500);
  };

  return (
    <StyledPopover
      button={cloneElement(children, {
        onMouseLeave: handleMouseLeave,
        onMouseOver: handleMouseEnter,
        onScroll: handleMouseLeave,
      })}
      closePopover={() => setIsContentHovered(false)}
      isOpen={isContentHovered || isChildHovered}
      isFullWidth={isFullWidth}
    >
      <div
        onMouseOver={() => setIsContentHovered(true)}
        onMouseLeave={() => setIsContentHovered(false)}
      >
        {provenance?.sources?.length > 0 ? provenance?.sources[0] : 'Provenance Source Unavailable'}
        <DocumentLink
          document={
            provenance?.sourceDocuments?.length > 0 && provenance?.sourceDocuments[0]?.document
          }
          handleClick={(doc) => {
            tracker.track('Download Document', {
              download_type: ProvenanceType.Attribute,
              event_surface: 'property_modal',
              filename: doc.filename,
              url: doc.httpURL,
              // ...streamContext,
            });
          }}
        />
      </div>
    </StyledPopover>
  );
};

export default ProvenanceTooltip;
