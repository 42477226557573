import { memo } from 'react';
import { ButtonIcon } from 'ui';

export const HelpButton = memo(() => (
  <ButtonIcon
    className="topnav-help-button"
    iconName="help"
    size="m"
    aria-label="help button"
    color="primary"
  />
));
