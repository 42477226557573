export const classifications = [
  'Unknown',
  'Architectural Drawing',
  'BOMA',
  "Builder's Risk Document",
  'Business or Capital Plan',
  'Cleansed SOV',
  'Due Diligence PCA',
  'Environmental Report',
  'Fire Protection Drawing',
  'Flood Hazard Document',
  'FM Global Report',
  'General Plans',
  'Image',
  'Loss Engineering Report',
  'Loss History',
  'Mechanical Drawing',
  'New Property Reporting Form',
  'Offering Memorandum',
  'Other Property Specific Document',
  'PCA',
  'Roof Inspection Report',
  'Roof Warranty',
  'Seismic Assessment',
  'Site Survey',
  'SOV',
  'Structural Assessment',
  'Structural Drawing',
  'Sustainability Report',
  'Valuation Report',
  'Construction Drawing',
  'Fire Protection Report',
  'Certificate of Insurance',
  'Policy Document',
  'FM COPE Report',
  'Financial Document',
];

export const CUSTOMER_FACING_SOV_NAME = 'SOV';
