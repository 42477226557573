import styled from '@emotion/styled';
import { EuiFlexItem, EuiModal } from 'ui';

export const ButtonContainer = styled(EuiFlexItem)`
  ${({ size }: { size: string }) =>
    `
      flex-direction: row-reverse;
      > button {
        margin-left: ${size};
      }
  `}
`;

export const ModalContainer = styled(EuiModal)`
  ${({ size }: { size: string }) =>
    `
      flex-direction: row-reverse;
      > button {
        margin-left: ${size};
      }
  `}
`;
