import {
  GetSubmissionAttributeMetadataData,
  SettingsDisplay,
  SettingsState,
  UpdateSubmissionAttributeMetadataVariables,
} from './types';

export const getSettingsState = (
  data: GetSubmissionAttributeMetadataData['submissionAttributeMetadata'],
): SettingsState => {
  const state = {};

  data.forEach((attribute) => {
    state[attribute.name] = {
      enabled: attribute.enabled,
      required: attribute.required,
    };

    if (attribute.enumValues?.length > 0 && attribute.enumEditable) {
      attribute.enumValues.forEach((enumValue) => {
        state[enumValue.value] = {
          enabled: enumValue.enabled,
        };
      });
    }
  });

  return state;
};

export const getSettingsDisplay = (
  data: GetSubmissionAttributeMetadataData['submissionAttributeMetadata'],
): SettingsDisplay[] => {
  const display = [];

  const initialGroupNames = data.map((attribute) => attribute.group);

  const groupNames = [...new Set(initialGroupNames)];

  // get display info for enum values
  data.forEach((attribute) => {
    if (attribute.enumValues?.length > 0 && attribute.enumEditable) {
      const group = {
        label: attribute.displayName,
        settings: attribute.enumValues.map((enumValue) => ({
          id: enumValue.value,
          label: enumValue.displayName,
        })),
      };
      display.push(group);
    }
  });

  // get display info for other groups
  groupNames.forEach((groupName) => {
    // Find attributes that belong to the same group
    const attributes = data.filter(
      (attribute) => attribute.group === groupName && !attribute.enumEditable,
    );

    const group = {
      label: groupName,
      settings: attributes.map((attribute) => ({
        id: attribute.name,
        label: attribute.displayName,
      })),
    };

    if (group.label === null) {
      // filter out TIV since it is a calculated field
      const filteredGroupSettings = group.settings.filter((g) => g.id != 'totalInsuredValue');
      display.push({
        label: null,
        settings: filteredGroupSettings,
      });
    } else {
      display.push(group);
    }
  });

  return display;
};

export const getUpdateSubmission = (
  data: GetSubmissionAttributeMetadataData['submissionAttributeMetadata'],
  state: SettingsState,
): UpdateSubmissionAttributeMetadataVariables['input']['attributes'] =>
  data?.map((attribute) => {
    if (attribute.enumValues?.length > 0) {
      const finalEnumValues = attribute.enumValues
        .filter((enumValue) => state?.[enumValue.value]?.enabled)
        .map((enumValue) => enumValue.value);

      return {
        enabled: state[attribute.name]?.enabled || false,
        enumValues: finalEnumValues,
        name: attribute.name,
        required: state[attribute.name]?.required || false,
      };
    }

    return {
      enabled: state[attribute.name]?.enabled,
      enumValues: null,
      name: attribute.name,
      required: state[attribute.name]?.required || false,
    };
  });

export const getFilteredSubmissionData = (
  data: GetSubmissionAttributeMetadataData['submissionAttributeMetadata'],
): GetSubmissionAttributeMetadataData['submissionAttributeMetadata'] => {
  const filteredList = [];
  const omittedFields = ['createdAt', 'accountOrgName', 'submitterEmail'];
  data.forEach((attribute) => {
    if (!omittedFields.includes(attribute.name)) {
      filteredList.push(attribute);
    }
  });
  return filteredList;
};

export const getDerivedFields = (
  attributeData: GetSubmissionAttributeMetadataData['submissionAttributeMetadata'],
) => attributeData?.filter((i) => i.derived === true).map((i) => i.name);
