import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { getCodingData } from '@onarchipelago/cx/DocumentsModal/utils';
import { ExportCode, IDistillers, XlsxExportModes } from '@onarchipelago/cx/Stream/types';
import {
  Button,
  ButtonEmpty,
  ButtonGroup,
  ContextMenu,
  EuiCheckboxGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiText,
  EuiTextColor,
  useEuiTheme,
  useToast,
} from 'ui';
import ConfirmationDialog from '@app/components/ConfirmationDialog/ConfirmationDialog';
import { convertRuleGroupToFilters } from '@app/components/Filter/utils';
import { IS_DEV_ENV } from '@app/config';
import { useDecodedParams } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { IRouteProps } from '@app/cx/Properties/types';
import { useValidateChangeByStreamReportMutation } from '@app/graphql/queries/validateV2/__generated__/validateByStreamReport.generated';
import { AttributeFilter } from '@app/graphql/types';
import { getLocalStorageItem } from '@app/hooks/useLocalStorage';
import useStandardQuery from '@app/hooks/useStandardQuery';
import { TrackStreamPage, useTracker } from '@app/hooks/useTracker';
import { FEATURE_RAPID_EXPORT } from '@app/platform/SystemSettings/Flags/types';
// FIX ME
// @ts-ignore
import GET_GROUPS from '@app/queries/streams/PropertyGroupsQuery/getGroups.gql';
import {
  IGetPropertyGroupsData,
  IGetPropertyGroupsVariables,
} from '@app/queries/streams/PropertyGroupsQuery/types';
import { getTotalGroup } from '@app/queries/streams/PropertyGroupsQuery/utils';
import { IGraphQLStream } from '@app/queries/streams/types';
import {
  airExportHandler,
  archipelagoExportClickHandler,
  changeAnalysisExportHandler,
  exportPalClickHandler,
  Pal,
  rapidExportClickHandler,
  rmsExportHandler,
} from '@app/utils/export';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { getGroupByFromState, resolveFilters } from '../groupbyv2';
import { useStreamContext } from '../StreamProvider';
import { useStreamRenderStepTwo } from '../StreamRender/useStreamRenderStepTwo';
import { usePageState } from '../utils';
import {
  AD_AIR_KEY,
  AD_RAPID_KEY,
  AD_RMS_KEY,
  AD_XLSX_KEY,
  AIR_LABEL,
  ARCHIPELAGO_DATA_LABEL,
  CA_XLSX_KEY,
  CHANGE_ANALYSIS_LABEL,
  CSV_LABEL,
  LD_XLSX_KEY,
  LOSS_DATA_LABEL,
  PD_XLSX_KEY,
  POLICY_DATA_LABEL,
  RAPID_LABEL,
  RMS_LABEL,
  XLSX_LABEL,
} from './constants';

const ExportButton: FC<{ distillers: IDistillers }> = () => {
  const apolloClient = useApolloClient();
  const { selectedOrganization } = useUserSession();
  const { streamSlug } = useDecodedParams<IRouteProps>();
  const { getIdToken, account } = useAuth();
  const token = getIdToken ? getIdToken() : '';
  const [isPopoverOpen, setPopover] = useState(false);
  const [xlsxExportMode, setXlsxExportMode] = useState<XlsxExportModes>(XlsxExportModes.FILTERED);
  const [isLoading, setIsLoading] = useState(false);
  const [{ changesSince, currentSnapshot }] = usePageState();
  const toast = useToast();
  const history = useHistory();
  const { stream: currentStream, permissions } = useStreamContext();
  const { pageState } = useStreamRenderStepTwo(account);
  const { euiTheme } = useEuiTheme();
  const [filteredPropertyCount, setFilteredPropertyCount] = useState<number>();
  const [propertyStatusOptions, setPropertyStatusOptions] = useState<[]>();
  const [selectedPropertyStatuses, setSelectedPropertyStatuses] = useState<{
    [key: string]: boolean;
  }>({});

  const tracker = useTracker();
  const currentOrDefaultSnapshot =
    currentSnapshot || (!account?.permissions?.admin && currentStream?.defaultSnapshot) || '';

  const isExportAllowed = (code: ExportCode) =>
    account?.permissions?.admin || stream?.allowedExports?.includes(code);

  const isRapidExportEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_RAPID_EXPORT,
  );

  // anitpattern - needed to tell the download callback that a user navigated away or not
  const isMounted = useRef(true);
  const [promptInfo, setPromptInfo] = useState<{
    // Whether to show the prompt
    show: boolean;
    // The location to navigate to when user clicks yes
    pathname?: string;
  }>({
    pathname: undefined,
    show: false,
  });

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );
  const pageFilters = pageState.filters;

  const filters = convertRuleGroupToFilters(pageFilters);

  const stream = currentStream as IGraphQLStream;

  const { data } = useStandardQuery<IGetPropertyGroupsData, IGetPropertyGroupsVariables>(
    GET_GROUPS,
    {
      variables: {
        changesSince: changesSince || '',
        compareChanges: !!changesSince,
        currentSnapshot: currentSnapshot || '',
        filter: resolveFilters(filters, pageState.groupByV2, []) as AttributeFilter[],
        // NOTE: The API only supports grouping on 1 single attribute.
        groupBy: getGroupByFromState(pageState.groupByV2),
        streamSlug: stream?.slug || streamSlug,
      },
    },
    {
      errorMessage: (err) => err?.message,
    },
  );

  useEffect(() => {
    const totalGroup = data ? getTotalGroup(data, stream?.snapshots, !!changesSince) : null;
    const { filteredPropertyCount: filteredPropertyCountTG } = totalGroup || {};

    setFilteredPropertyCount(filteredPropertyCountTG);
    // If this is a Change Analysis view, show a submenu for filtering the output
    if (data?.compareChanges) {
      const subGroups = [];
      const selectedGroups = {};
      if (data?.compareChanges?.groups?.totalPropertyCount === 0) {
        subGroups.push({
          id: 'Ongoing',
          label: 'Ongoing',
        });
        selectedGroups['Ongoing'] = true;
      }
      data?.compareChanges?.groups?.subGroups?.forEach((subGroup) => {
        subGroups.push({
          id: subGroup.groupingAttribute.value,
          label: `${subGroup.groupingAttribute.value}`,
        });
        selectedGroups[subGroup.groupingAttribute.value] = true;
      });
      setSelectedPropertyStatuses(selectedGroups);
      // FIX ME
      // @ts-ignore
      setPropertyStatusOptions(subGroups);
    }
  }, [data]);

  // blocks navigating away when doing exports
  // FIX ME
  // @ts-ignore
  history.block((location: any): boolean => {
    // if promptInfo.show is true then we should allow to navigate away
    if (isLoading && promptInfo.show === false) {
      setPromptInfo({
        pathname: location.pathname,
        show: true,
      });
      return false; // returning false prevents the user from navigating away from the page
    }
    return true;
  });

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const button = (
    <ButtonEmpty
      onClick={onButtonClick}
      data-testid="export-button"
      data-tourid="export-button"
      loading={isLoading}
      iconSide="right"
      iconName="chevronDown"
      label="Export"
    />
  );

  const sectionTitle = (title: string, subtitle: string) => (
    <div className="exportbutton-section" style={{ color: euiTheme.colors.ink }}>
      <EuiText size="xs" color="primary">
        <h3 style={{ fontWeight: 700, marginBottom: 0 }}>{title}</h3>
        <p style={{ letterSpacing: 0 }}>
          <EuiTextColor color="subdued">{subtitle}</EuiTextColor>
        </p>
      </EuiText>
    </div>
  );

  const snapshotName = (snapshot: string | null) => snapshot || 'Latest';

  const errHandler = (e: any) => {
    if (!e) {
      return;
    }

    let errMsg = 'Error occured when exporting.';
    if (e instanceof Error) {
      const err = e as Error;
      if (err.message) {
        errMsg = err.message;
      }
    }
    toast({ title: errMsg, type: 'danger' });

    if (IS_DEV_ENV) {
      // only log error when in development mode
      console.error(e);
    }
  };

  const notifyMixPanel = (event: string, data: any) => {
    tracker.track(`${TrackStreamPage.prefix}: ${event}`, {
      data: data,
      event_surface: TrackStreamPage.event_surface,
      organization_name: stream.orgName,
      stream_name: stream.name,
      stream_slug: stream.slug,
    });
  };

  const visibleColumns = getLocalStorageItem<string[]>(
    account?.userId,
    `visibleColumns|${stream?.id}.v1`,
  );

  const xlsxExportClickHandler = () => {
    closePopover();
    setIsLoading(true);
    if (pageFilters && xlsxExportMode === XlsxExportModes.FILTERED) {
      notifyMixPanel('Exported Archipelago XLSX filtered', {
        currentOrDefaultSnapshot,
        filters,
      });
    } else {
      notifyMixPanel('Exported Archipelago XLSX', {
        currentOrDefaultSnapshot,
      });
    }
    archipelagoExportClickHandler({
      client: apolloClient,
      currentSnapshot: currentOrDefaultSnapshot,
      enabledFeatures: stream?.enabledFeatures,
      exportedCoding: getCodingData(ExportCode.ARCHIPELAGO),
      filters:
        pageFilters && xlsxExportMode === XlsxExportModes.FILTERED
          ? (resolveFilters(filters, pageState.groupByV2, []) as AttributeFilter[])
          : undefined,
      orgName: stream?.orgName,
      streamSlug: stream?.slug,
      visibleAttributes: visibleColumns,
    })
      .then((downloadUrl) => {
        if (isMounted.current) {
          // don't handle the callback when user has navigated away
          window.location.href = downloadUrl;
        }
      })
      .catch(errHandler)
      .finally(() => {
        setIsLoading(false);
      });
  };
  const xlsxNestedPanel = (
    <EuiPanel hasBorder={false} hasShadow={false}>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <ButtonGroup
            data-testid="xlsx-export-selector"
            data-tourid="xlsx-export-selector"
            size="l"
            idSelected={xlsxExportMode}
            legend="xlsx export selector"
            fullWidth
            onChange={(id: XlsxExportModes) => {
              setXlsxExportMode(id);
            }}
            options={[
              {
                'data-testid': 'xlsx-export-selector-all',
                id: 'all',
                label: `All properties (${currentStream?.propertiesCount || 'unknown'})`,
              },
              {
                'data-testid': 'xlsx-export-selector-filtered',
                id: 'filtered',
                label: `Filtered (${filteredPropertyCount || 'unknown'})`,
              },
            ]}
          />
        </EuiFlexItem>
        <EuiHorizontalRule margin="none" />
        <EuiFlexItem grow={false} style={{ alignSelf: 'flex-end' }}>
          <Button
            style={{ width: '200px' }}
            label="Download"
            fullWidth={false}
            onClick={() => {
              xlsxExportClickHandler();
              closePopover();
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );

  const dataExportItems = [];

  if (isExportAllowed(ExportCode.ARCHIPELAGO)) {
    dataExportItems.push({
      'data-testid': AD_XLSX_KEY,
      icon: 'tableDensityExpanded',
      key: AD_XLSX_KEY,
      label: XLSX_LABEL,
      nestedPanel: pageFilters
        ? {
            content: xlsxNestedPanel,
            title: 'Properties',
            width: 450,
          }
        : undefined,
      onClick: pageFilters ? undefined : xlsxExportClickHandler,
      panel: pageFilters ? 'nestedXlsxPanel' : undefined,
    });
  }

  if (isExportAllowed(ExportCode.RMS)) {
    dataExportItems.push({
      'data-testid': AD_RMS_KEY,
      iconName: 'rms',
      key: AD_RMS_KEY,
      label: RMS_LABEL,
      onClick: () => {
        closePopover();
        setIsLoading(true);
        // Use the async RMS Export which is based off of the new Secondary Modifiers V2 functionality
        notifyMixPanel('Exported RMS', { currentOrDefaultSnapshot });
        rmsExportHandler(
          stream.slug,
          currentOrDefaultSnapshot, // snapshotTo
          stream.enabledFeatures,
          stream.orgName,
          apolloClient,
        )
          .then((downloadUrl) => {
            if (isMounted.current) {
              // don't handle the callback when user has navigated away
              window.location.href = downloadUrl;
            }
          })
          .catch(errHandler)
          .finally(() => {
            setIsLoading(false);
          });
      },
    });
  }

  if (isExportAllowed(ExportCode.AIR)) {
    dataExportItems.push({
      'data-testid': AD_AIR_KEY,
      iconName: 'air',
      key: AD_AIR_KEY,
      label: AIR_LABEL,
      onClick: () => {
        closePopover();
        setIsLoading(true);
        notifyMixPanel('Exported AIR', { currentOrDefaultSnapshot });
        // Use the async AIR Export which is based off of the new Secondary Modifiers V2 functionality
        airExportHandler(
          stream.slug,
          currentOrDefaultSnapshot, // snapshotTo
          stream.enabledFeatures,
          stream.orgName,
          apolloClient,
        )
          .then((downloadUrl) => {
            if (isMounted.current) {
              // don't handle the callback when user has navigated away
              window.location.href = downloadUrl;
            }
          })
          .catch(errHandler)
          .finally(() => {
            setIsLoading(false);
          });
      },
    });
  }

  if (isRapidExportEnabled && (isExportAllowed(ExportCode.RAPID) || account?.permissions?.admin)) {
    dataExportItems.push({
      'data-testid': AD_RAPID_KEY,
      iconName: 'rapid',
      key: AD_RAPID_KEY,
      label: RAPID_LABEL,
      onClick: () => {
        closePopover();
        setIsLoading(true);
        notifyMixPanel('Exported RAPID', { currentOrDefaultSnapshot });
        rapidExportClickHandler(
          stream.id,
          currentOrDefaultSnapshot,
          getCodingData(ExportCode.RAPID),
          token,
        )
          .catch(errHandler)
          .finally(() => {
            setIsLoading(false);
          });
      },
    });
  }

  const policyExportItems = [
    {
      'data-testid': PD_XLSX_KEY,
      icon: 'tableDensityExpanded',
      key: PD_XLSX_KEY,
      label: XLSX_LABEL,
      onClick: () => {
        closePopover();
        setIsLoading(true);
        notifyMixPanel('Exported Policies', { currentOrDefaultSnapshot });
        exportPalClickHandler(Pal.Policy, stream.id, currentOrDefaultSnapshot, token)
          .catch(errHandler)
          .finally(() => {
            setIsLoading(false);
          });
      },
    },
  ];

  const lossExportItems = [
    {
      'data-testid': LD_XLSX_KEY,
      icon: 'tableDensityExpanded',
      key: LD_XLSX_KEY,
      label: XLSX_LABEL,
      onClick: () => {
        closePopover();
        setIsLoading(true);
        notifyMixPanel('Exported Losses', { currentOrDefaultSnapshot });
        exportPalClickHandler(Pal.Loss, stream.id, currentOrDefaultSnapshot, token)
          .catch(errHandler)
          .finally(() => {
            setIsLoading(false);
          });
      },
    },
  ];

  const onPropertyStatusSelectedChange = (optionId: string) => {
    const newSelectedStatuses = {
      ...selectedPropertyStatuses,
      ...{
        [optionId]: !selectedPropertyStatuses[optionId],
      },
    };
    setSelectedPropertyStatuses(newSelectedStatuses);
  };

  const caeClickHandler = () => {
    closePopover();
    setIsLoading(true);
    notifyMixPanel('Exported Change Analysis', { changesSince, currentOrDefaultSnapshot });

    const statusFilter = [
      {
        name: 'propertyStatus',
        operator: 'IN',
        values: Object.keys(selectedPropertyStatuses).filter(
          (key) => selectedPropertyStatuses[key],
        ),
      },
    ];

    changeAnalysisExportHandler(
      stream.slug,
      currentOrDefaultSnapshot, // snapshotTo
      changesSince || '', // snapshotFrom
      stream.orgName,
      stream.enabledFeatures,
      apolloClient,
      statusFilter,
    )
      .then((downloadUrl) => {
        if (isMounted.current) {
          // don't handle the callback when user has navigated away
          window.location.href = downloadUrl;
        }
      })
      .catch(errHandler)
      .finally(() => {
        setIsLoading(false);
      });
  };
  const caeNestedPanel = (
    <EuiPanel hasBorder={false} hasShadow={false}>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiCheckboxGroup
            options={propertyStatusOptions}
            idToSelectedMap={selectedPropertyStatuses}
            onChange={onPropertyStatusSelectedChange}
          />
        </EuiFlexItem>
        <EuiHorizontalRule margin="none" />
        <EuiFlexItem grow={false} style={{ alignSelf: 'flex-end' }}>
          <Button
            style={{ width: '200px' }}
            label="Download"
            fullWidth={false}
            onClick={() => {
              caeClickHandler();
              closePopover();
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );

  const changeAnalysisExportItems = [
    {
      'data-testid': CA_XLSX_KEY,
      disabled: !changesSince,
      icon: 'tableDensityExpanded',
      label: CSV_LABEL,
      nestedPanel: {
        content: caeNestedPanel,
        title: 'Properties',
        width: 450,
      },
      onClick: undefined,
      panel: 'caeNestedPanel',
    },
  ];

  const [startReport] = useValidateChangeByStreamReportMutation({
    onCompleted: (data) => {
      setIsLoading(false);
      if (isMounted.current) {
        // don't handle the callback when user has navigated away
        window.location.href = data.validateChangeByStreamReport.downloadUrl;
      }
      // Download the file that is in this url
      return data.validateChangeByStreamReport.downloadUrl;
    },
    variables: {
      input: {
        fromSnapshotName: changesSince,
        streamSlug: currentStream?.slug,
      },
    },
  });
  const vhcClickHandler = () => {
    closePopover();
    setIsLoading(true);
    notifyMixPanel('Exported Historical Change Validation', {
      changesSince,
      currentOrDefaultSnapshot,
    });
    startReport();
  };

  const validateChangeExportItem = [
    {
      disabled: !changesSince,
      icon: 'document',
      label: 'Historical Change Validation',
      onClick: vhcClickHandler,
      panel: 'caeNestedPanel',
    },
  ];

  const hasHistoricalValidationExport = stream?.enabledFeatures?.includes(
    'HistoricalChangeValidation',
  );
  const hasPolicies = currentStream?.policiesCount > 0;

  const showDataExports = dataExportItems.length > 0;

  const showChangeAnalysis = isExportAllowed(ExportCode.CHANGE_ANALYSIS);
  const canExportLosses = permissions?.canExportLosses && isExportAllowed(ExportCode.LOSSES);

  const canExportPolicies = permissions?.canExportPolicies && hasPolicies;

  const defaultPanel = {
    id: 'defaultPanel',
    items: [],
    width: 145,
  };

  const horizontalLineItem = {
    disabled: true,
    label: <EuiHorizontalRule margin="xs" />,
    style: { padding: 0 },
  };

  const defaultSubtitle = `As of: ${snapshotName(currentOrDefaultSnapshot)}`;

  if (showDataExports) {
    defaultPanel.items.push({
      disabled: true,
      label: sectionTitle(ARCHIPELAGO_DATA_LABEL, defaultSubtitle),
    });

    defaultPanel.items = defaultPanel.items.concat(dataExportItems);
  }

  if (canExportLosses) {
    if (showDataExports) {
      defaultPanel.items.push(horizontalLineItem);
    }
    defaultPanel.items.push({
      disabled: true,
      label: sectionTitle(LOSS_DATA_LABEL, defaultSubtitle),
    });
    defaultPanel.items = defaultPanel.items.concat(lossExportItems);
  }

  if (showChangeAnalysis) {
    if (showDataExports || canExportLosses) {
      defaultPanel.items.push(horizontalLineItem);
    }
    defaultPanel.items.push({
      disabled: true,
      label: sectionTitle(
        CHANGE_ANALYSIS_LABEL,
        changesSince
          ? `Between: ${snapshotName(changesSince)} & ${snapshotName(currentOrDefaultSnapshot)}`
          : 'Enable to export',
      ),
    });
    defaultPanel.items = defaultPanel.items.concat(changeAnalysisExportItems);
  }

  if (hasHistoricalValidationExport) {
    defaultPanel.items = defaultPanel.items.concat(validateChangeExportItem);
  }

  if (canExportPolicies) {
    if (showChangeAnalysis || showDataExports || canExportLosses) {
      defaultPanel.items.push(horizontalLineItem);
    }
    defaultPanel.items.push({
      disabled: true,
      label: sectionTitle(POLICY_DATA_LABEL, defaultSubtitle),
    });
    defaultPanel.items = defaultPanel.items.concat(policyExportItems);
  }

  return showDataExports || showChangeAnalysis ? (
    <>
      {promptInfo.show && (
        <ConfirmationDialog
          isOpen={promptInfo.show}
          confirmBodyText="Export is still running. Are you sure you want to leave the page?"
          onSubmit={() => {
            history.push({
              pathname: promptInfo.pathname,
            });
          }}
          onClose={() => {
            setPromptInfo({
              pathname: undefined,
              show: false,
            });
          }}
        />
      )}
      <ContextMenu
        data-testid="export-popover"
        anchorPosition="downRight"
        button={button}
        open={isPopoverOpen}
        onClose={closePopover}
        panel={defaultPanel}
        panelPaddingSize="none"
      />
    </>
  ) : null;
};

export default ExportButton;
