import { useState } from 'react';
import * as React from 'react';
import { Checkbox, Spinner } from 'ui';
import { useAttributeMetadataEditorContext } from '../context/attributeMetadataEditorContext';
import { GroupedMetadata } from '../types';
import styles from './MetadataTableCheckbox.emotion';

interface Props {
  attribute: GroupedMetadata;
  id: number;
  updateBoolean: (attribute: GroupedMetadata, checked: boolean) => Promise<void>;
}

const MetadataTableCheckbox: React.FC<Props> = ({ attribute, id, updateBoolean }) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { checkboxes } = useAttributeMetadataEditorContext();

  const handleChange = async (attribute: GroupedMetadata, checked: boolean) => {
    setIsUpdating(true);

    await updateBoolean(attribute, checked);

    setIsUpdating(false);
  };

  const isChecked = (attribute: GroupedMetadata) => {
    if (Object.keys(checkboxes).includes(attribute.name)) {
      return checkboxes[attribute.name];
    }

    if (attribute.isCustom) {
      attribute.scope = 'consumer';
    }

    return attribute.includeInStreams;
  };

  return (
    <div data-testid="attribute-metadata-table-checkbox" className={styles.container(isUpdating)}>
      <Checkbox
        checked={isChecked(attribute)}
        id={String(id)}
        onChange={(event) => handleChange(attribute, event.target.checked)}
      />
      {isUpdating && <Spinner size="s" />}
    </div>
  );
};

export default MetadataTableCheckbox;
