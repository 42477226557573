import * as React from 'react';
import { IStream } from '@onarchipelago/cx/Stream/types';
import AttributeMetadataEditorContainer from '@onarchipelago/platform/AttributeMetadataEditor/AttributeMetadataEditorContainer';
import { EuiFlexGroup, EuiForm, Tabs } from 'ui';
import { RegisterUserForm } from '@app/precheck/Components/RegisterUserForm';
import AdvancedTab from './AdvancedTab/AdvancedTab';
import BasicInfoTab from './BasicInfoTab/BasicInfoTab';
import ExplorersConfigTab from './ExplorersConfigTab/ExplorersConfigTab';
import ExportsTab from './ExportsTab/ExportsTab';
import { HazardsTab } from './HazardsTab/HazardsTab';
import HighlightTabs from './HighlightsTab/HighlightsTab';
import SnapshotsTab from './SnapshotsTab/SnapshotsTab';
import styles from './Tabs.emotion';

const ContentWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <EuiForm className={styles.contentForm}>
    <EuiFlexGroup className={styles.contentFlexGroup} direction="column">
      {children}
    </EuiFlexGroup>
  </EuiForm>
);

interface Props {
  stream: IStream;
  onTabChange: (tabId: string) => void;
  simplifiedStreamSetting: boolean;
}

export const TabDefinitions = {
  Advanced: { id: 'advanced', label: 'Advanced' },
  AttributeMetadata: { id: 'attributeMetadata', label: 'Attribute Metadata' },
  BasicInfo: { id: 'basicInfo', label: 'Basic Info' },
  ExplorersConfig: { id: 'explorersConfig', label: 'Explorers Config' },
  Exports: { id: 'exports', label: 'Exports' },
  Hazards: { id: 'hazards', label: 'Hazards' },
  Highlights: { id: 'highlights', label: 'Highlights' },
  Precheck: { id: 'precheck', label: 'Precheck' },
  Sharing: { id: 'sharing', label: 'Sharing' },
  Snapshots: { id: 'snapshots', label: 'Snapshots' },
};

const ModalTabs: React.FC<Props> = ({ simplifiedStreamSetting, stream, onTabChange }) => {
  if (simplifiedStreamSetting) {
    return (
      <Tabs
        data-testid="stream-edit-modal-tabs"
        initialTabId={TabDefinitions.BasicInfo.id}
        size="s"
        tabs={[
          {
            content: (
              <ContentWrapper>
                <BasicInfoTab />
              </ContentWrapper>
            ),
            // FIX ME
            // @ts-ignore
            'data-testid': 'basic-info-tab',
            id: TabDefinitions.BasicInfo.id,
            label: TabDefinitions.BasicInfo.label,
            onClick: () => onTabChange(TabDefinitions.BasicInfo.id),
          },
          {
            content: (
              <ContentWrapper>
                <SnapshotsTab
                  simplifiedStreamSetting={simplifiedStreamSetting}
                  // FIX ME
                  // @ts-ignore
                  stream={stream}
                />
              </ContentWrapper>
            ),
            // FIX ME
            // @ts-ignore
            'data-testid': 'snapshots-tab',
            id: TabDefinitions.Snapshots.id,
            label: TabDefinitions.Snapshots.label,
            onClick: () => onTabChange(TabDefinitions.Snapshots.id),
          },
        ]}
      />
    );
  }

  const tabs = [
    {
      content: (
        <ContentWrapper>
          <AdvancedTab />
        </ContentWrapper>
      ),
      'data-testid': 'advanced-tab',
      id: TabDefinitions.Advanced.id,
      label: TabDefinitions.Advanced.label,
      onClick: () => onTabChange(TabDefinitions.Advanced.id),
    },
    {
      content: (
        <ContentWrapper>
          <div className={styles.attributeEditorContainer}>
            <AttributeMetadataEditorContainer
              organizationId={stream.organizationId as string}
              streamId={stream.id}
              // FIX ME
              // @ts-ignore
              stream={stream}
            />
          </div>
        </ContentWrapper>
      ),
      'data-testid': 'attribute-metadata-tab',
      id: TabDefinitions.AttributeMetadata.id,
      label: TabDefinitions.AttributeMetadata.label,
      onClick: () => onTabChange(TabDefinitions.AttributeMetadata.id),
    },
    // {
    //   content: (
    //     <ContentWrapper>
    //       <BasicInfoTab />
    //     </ContentWrapper>
    //   ),
    //   'data-testid': 'basic-info-tab',
    //   id: TabDefinitions.BasicInfo.id,
    //   label: TabDefinitions.BasicInfo.label,
    //   onClick: () => onTabChange(TabDefinitions.BasicInfo.id),
    // },
    {
      content: (
        <ContentWrapper>
          <ExplorersConfigTab />
        </ContentWrapper>
      ),
      'data-testid': 'explorers-config-tab',
      id: TabDefinitions.ExplorersConfig.id,
      label: TabDefinitions.ExplorersConfig.label,
      onClick: () => onTabChange(TabDefinitions.ExplorersConfig.id),
    },
    {
      content: (
        <ContentWrapper>
          <HighlightTabs />
        </ContentWrapper>
      ),
      'data-testid': 'highlights-tab',
      id: TabDefinitions.Highlights.id,
      label: TabDefinitions.Highlights.label,
      onClick: () => onTabChange(TabDefinitions.Highlights.id),
    },
    // {
    //   content: (
    //     <ContentWrapper>
    //       {/* @ts-ignore */}
    //       <SnapshotsTab stream={stream} />
    //     </ContentWrapper>
    //   ),
    //   'data-testid': 'snapshots-tab',
    //   id: TabDefinitions.Snapshots.id,
    //   label: TabDefinitions.Snapshots.label,
    //   onClick: () => onTabChange(TabDefinitions.Snapshots.id),
    // },
    {
      content: (
        <ContentWrapper>
          <ExportsTab stream={stream} />
        </ContentWrapper>
      ),
      'data-testid': 'exports-tab',
      id: TabDefinitions.Exports.id,
      label: TabDefinitions.Exports.label,
      onClick: () => onTabChange(TabDefinitions.Exports.id),
    },
    {
      content: (
        <ContentWrapper>
          <RegisterUserForm stream={stream} />
        </ContentWrapper>
      ),
      'data-testid': 'precheck-tab',
      id: TabDefinitions.Precheck.id,
      label: TabDefinitions.Precheck.label,
      onClick: () => onTabChange(TabDefinitions.Precheck.id),
    },
    {
      content: (
        <ContentWrapper>
          <HazardsTab stream={stream} />
        </ContentWrapper>
      ),
      'data-testid': 'hazards-tab',
      id: TabDefinitions.Hazards.id,
      label: TabDefinitions.Hazards.label,
      onClick: () => onTabChange(TabDefinitions.Hazards.id),
    },
  ];

  return (
    <Tabs
      data-testid="stream-edit-modal-tabs"
      initialTabId={TabDefinitions.Advanced.id}
      size="s"
      tabs={tabs}
    />
  );
};

export default ModalTabs;
