import { Dispatch, SetStateAction } from 'react';
import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText, EuiToolTip, Icon, Select } from 'ui';
import styles from './EditAttributeModal.emotion';

interface Props {
  scope: string | null;
  setScope: Dispatch<SetStateAction<string | null>>;
}

const EditAttributeModalRMCustom: React.FC<Props> = ({ scope, setScope }) => {
  const selectOptions = [
    { label: 'Public', value: 'consumer' },
    { label: 'Private', value: 'owner' },
  ];

  return (
    <>
      <EuiFlexItem data-testid="edit-attribute-modal-rm-custom-title" grow={false}>
        <EuiText className={styles.sectionHeader}>
          <h4>RM Custom</h4>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem data-testid="edit-attribute-modal-rm-custom-visibility" grow={false}>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem>
            <EuiFormRow label="Visibility">
              <Select
                fullWidth
                onChange={(value) => setScope(value)}
                options={selectOptions}
                value={scope}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem className={styles.icon} grow={false}>
            <EuiToolTip content="Owner == private. Consumer == public.">
              <Icon name="help" size="l" />
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </>
  );
};

export default EditAttributeModalRMCustom;
