import { useContext, useEffect } from 'react';
import * as React from 'react';
import { Prompt, useHistory } from 'react-router';
import { Location } from 'history';
import { ModalContext } from '@app/contexts/ModalContext';
import NavigationPromptModal from './NavigationPromptModal';

// `urlOverride`: the user will not be blocked if they try to navigate to this specific route.
interface Props {
  leaveButtonLabel?: string;
  urlOverride?: string;
  showModalPageSwitch?: boolean;
}

const NavigationPrompt: React.FC<Props> = ({
  leaveButtonLabel = 'Leave',
  urlOverride,
  showModalPageSwitch = true,
}) => {
  const history = useHistory();
  const { closeModal, showModal } = useContext(ModalContext);

  // If `confirmedNavigation=true`, then allow the user navigate away from the current page.
  const [confirmedNavigation, setConfirmedNavigation] = React.useState<boolean>(false);
  // `desiredPath`: the path that the user is trying to navigate to.
  const [desiredPath, setDesiredPath] = React.useState<string>('');

  // If a user tries to close the window, provide them with a navigation prompt.
  const handleUnload = (event: BeforeUnloadEvent): void => {
    event.preventDefault();

    event.returnValue = '';
  };

  // Add the before unload event listener
  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(desiredPath);
    }
  }, [confirmedNavigation]);

  // * `handleBlockedNavigation` returns a boolean: this boolean tells the react-router `Prompt`
  //    if it should navigate to the new desired location. `true` means it should navigate to
  //    the desired location while `false` means that the navigation change should be stopped.
  // * `nextLocation` is the browser location that the user is trying to get to.
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    // what path is the user trying to get to.
    setDesiredPath(nextLocation.pathname);

    if (!showModalPageSwitch) {
      setConfirmedNavigation(true);
      return true;
    }
    // `urlOverride`: do not block the user if they try to navigate to a specified route
    if (urlOverride && nextLocation.pathname === urlOverride) {
      return true;
    }

    // If the user confirms, navigate away
    if (confirmedNavigation) {
      return true;
    }

    if (showModal) {
      showModal(
        <NavigationPromptModal
          handleClick={() => setConfirmedNavigation(true)}
          handleClose={closeModal}
          leaveButtonLabel={leaveButtonLabel}
        />,
      );
    }

    return false;
  };

  return <Prompt message={handleBlockedNavigation} />;
};

export default NavigationPrompt;
