import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { useToast } from 'ui';
import NavigationPrompt from '@app/components/NavigationPrompt/NavigationPrompt';
import { API_BASE_URL } from '@app/config';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import useLogger from '@app/hooks/useLogger';
import { useTracker } from '@app/hooks/useTracker';
import { FEATURE_LIBRARY_V2 } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { DateStyle, formatDate } from '@app/utils/format';
import { getErrorMessage } from '@app/utils/getErrorMessage';

export const guid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export interface IFile {
  id: string;
  filename: string;
  progress: number;
  error: boolean;
  createdAt: string;
  classification: string;
  organization: {
    id: string;
  };
}
export interface IFileList {
  [key: string]: IFile;
}

interface IProgressUpdate {
  key: string;
  value: number;
}

interface IErrorUpdate {
  key: string;
  error: boolean;
}

interface IUpload {
  files: FileList | null;
  orgId?: string;
  submissionId?: string;
  propertyID?: string;
  classification?: string;
  eventPrefix: string;
}
interface UploadsContext {
  uploadFiles: (upload: {
    files: FileList | null;
    orgId?: string;
    submissionId?: string;
    propertyID?: string;
    classification?: string;
    eventPrefix: string;
  }) => void;
  uploads: IFileList;
  allUploadsComplete: boolean;
  recentlyCompleted: number;
  hadErrors: boolean;
}

export const UploadsContext = createContext({} as UploadsContext);

export const UploadsProvider = ({ children }: { children: ReactNode }) => {
  const toast = useToast();
  const { account, checkSession, isExpired } = useAuth();
  const apiTracker = useTracker();

  const { selectedOrganization } = useContext(UserSessionContext);
  const organizationId = selectedOrganization?.id;

  const [uploads, setUploads] = useState<IFileList>({});
  const [progressUpdate, setProgressUpdate] = useState<IProgressUpdate>();
  const [errorUpdate, showErrorUpdate] = useState<IErrorUpdate>();
  const [hadErrors, setHadErrors] = useState<boolean>(false);

  const isLibraryV2Enabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_LIBRARY_V2,
  );

  const [recentlyCompleted, setRecentlyCompleted] = useState(0);
  const logger = useLogger();
  const allUploadsComplete =
    Object.keys(uploads).length > 0 &&
    filter(uploads, (u: IFile) => u.filename !== undefined && u.progress !== 100 && !u.error)
      .length === 0;

  useEffect(() => {
    if (progressUpdate?.key && progressUpdate?.value >= 0) {
      setUploads((prev) => ({
        ...prev,
        [progressUpdate.key]: {
          ...prev[progressUpdate.key],
          progress: progressUpdate.value,
        },
      }));

      if (progressUpdate.value === 100) {
        setRecentlyCompleted((prev) => prev + 1);
      }
    }
  }, [progressUpdate]);

  useEffect(() => {
    if (errorUpdate?.key && errorUpdate?.error) {
      setUploads({
        ...uploads,
        [errorUpdate.key]: {
          ...uploads[errorUpdate.key],
          error: errorUpdate.error,
        },
      });
    }
  }, [errorUpdate]);

  // UPLOAD
  const [, executeUpload] = useAxios(
    {
      method: 'POST',
      url: `${API_BASE_URL}/organizations/${organizationId}/documents`,
    },
    {
      autoCancel: false,
      manual: true,
    },
  );

  const uploadProgress = (p: ProgressEvent, key: string) => {
    const progress = Math.round((p.loaded / p.total) * 100);
    setProgressUpdate((prev) => ({ ...prev, key, value: progress }));
  };

  const handleUpload = (upload: IUpload) => {
    const { files, orgId, submissionId, propertyID, classification, eventPrefix } = upload;
    logger.info('Files Uploaded', {
      classification,
      email: account?.email,
      file_count: files?.length || 0,
      files: (function () {
        const list = [];
        for (let i = 0; i < files.length; i++) {
          const item = files.item(i);
          list.push({
            name: item.name,
            size: item.size,
            type: item.type,
          });
        }
        return list;
      })(),
      orgId,
      propertyID,
      submissionId,
    });

    if (!files) {
      return;
    }
    const destinationRoute = submissionId ? 'submissions' : 'organizations';
    const destinationId = submissionId || orgId || organizationId;
    const notifySupport = !account?.email?.endsWith('@onarchipelago.com');

    // We are using this object to hold guids for each upload
    let keys = {};
    for (let i = 0; i < files.length; i++) {
      const uploadData = new FormData();
      uploadData.append('file', files[i], files[i].name);
      if (propertyID) {
        uploadData.append('propertyID', propertyID);
      }
      if (classification) {
        uploadData.append('classification', classification);
      }

      // For some reason, when doing `const key = guid();` the process was too
      // fast to assign a value in each loop, so when adding multiple files, they
      // all would have the same key, causing issues. Having an object with its
      // own key for each upload allowed to have separate guids and prevent issues.
      keys[i] = guid();
      // Aliasing for brevity
      const key = keys[i];

      const nextUploads = {};
      nextUploads[key] = {
        classification: 'Pending',
        created_at: formatDate(new Date(), DateStyle.Server),
        filename: files[i].name,
        id: '0',
        organization: {
          id: destinationId,
        },
        progress: 0,
      };
      setUploads((prevUploads) => ({ ...prevUploads, ...nextUploads }));

      executeUpload({
        data: uploadData,
        onUploadProgress: (p) => uploadProgress(p, key),
        params: {
          includeInStreams: false,
          notifySupport,
        },
        url: `${API_BASE_URL}/${destinationRoute}/${destinationId}/documents`,
      })
        .then((result: any) => {
          apiTracker.track('Document uploaded', {
            classification,
            documentId: result?.data?.document?.id,
            event_surface: eventPrefix.replace(' ', ''),
            organizationId: destinationId,
            propertyID,
            url: window.location.href,
            urlPathname: window.location.pathname,
          });

          if (result?.data?.document) {
            const document = result?.data?.document;
            setUploads((prevUploads) => ({
              ...prevUploads,
              [key]: {
                ...prevUploads[key],
                classification: document?.classification || 'Pending',
                createdAt: formatDate(new Date(), DateStyle.Server),
                id: document.id,
                progress: 100,
              },
            }));
          }
        })
        .catch((error) => {
          if (error) {
            toast({ title: getErrorMessage(error), type: 'danger' });
            showErrorUpdate({ error: true, key });
            setHadErrors(true);
          }
        });
    }
    // Remove values from the obj so it can be garbage collected
    keys = {};

    // On the first upload while no others are in flight, clear the count
    if (allUploadsComplete) {
      setRecentlyCompleted(0);
    }
  };

  const uploadFiles = async (upload: IUpload) => {
    if (isExpired()) {
      await checkSession(true);
      handleUpload(upload);
    } else {
      handleUpload(upload);
    }
  };

  return (
    <UploadsContext.Provider
      key={organizationId}
      value={{
        allUploadsComplete,
        hadErrors,
        recentlyCompleted,
        uploadFiles,
        uploads,
      }}
    >
      {children}
      {isLibraryV2Enabled && !isEmpty(uploads) && !allUploadsComplete && (
        <NavigationPrompt showModalPageSwitch={false} />
      )}
    </UploadsContext.Provider>
  );
};

export const useUploads = () => {
  const context = useContext(UploadsContext);
  return context;
};
