import { useState } from 'react';
import { ButtonEmpty, ContextMenu } from 'ui';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useFlyout } from '@app/contexts/FlyoutContext';
import AddDataFlyout from '@app/cx/Stream/AddDataFlyout/AddDataFlyout';
import { AddPropertyFlyout } from '@app/cx/Stream/AddPropertyFlyout/AddPropertyFlyout';
import { PanelItem } from '@app/cx/Stream/ManageStreamButtonPanels/types';
import useEditPropertiesPanel from '@app/cx/Stream/ManageStreamButtonPanels/useEditPropertiesPanel';
import useRestoreDeletedPropertiesPanel from '@app/cx/Stream/ManageStreamButtonPanels/useRestoreDeletedPropertiesPanel';
import useRestoreDisposedPanel from '@app/cx/Stream/ManageStreamButtonPanels/useRestoreDisposedPanel';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useTracker } from '@app/hooks/useTracker';
import { EJobType } from '@app/queries/organizations/types';
import { usePropertiesPageContext } from '../PropertiesPage.context';
export const AddPropertyButton = () => {
  const [open, setOpen] = useState(false);
  const tracker = useTracker();
  const { account } = useAuth();
  const { showFlyout } = useFlyout();
  // FIX ME
  // @ts-ignore
  const { stream, refetchPropertiesDataGrid, refetchPropertyGroups, lock } =
    usePropertiesPageContext();
  const { permissions, refetch } = useStreamContext();

  const isAdmin = account?.permissions?.admin;

  const inProgressJobs: Partial<Record<EJobType, Lock>> = {};

  (lock?.locks || []).forEach((jobInfo) => {
    if (!!inProgressJobs[jobInfo.type] || !jobInfo.canReuse) {
      return;
    }

    inProgressJobs[jobInfo.type] = jobInfo;
  });

  const inProgressJob = inProgressJobs[EJobType.AddProperties];
  const hasJobInProgress = !!inProgressJob;

  const handleBulkAdd = () => {
    tracker.track('Manage Properties - Add', {
      in_progress: hasJobInProgress,
      stream_id: stream?.id,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    });

    showFlyout(
      <AddDataFlyout
        orgName={stream?.orgName as string}
        // FIX ME
        // @ts-ignore
        inProgressJob={inProgressJobs[EJobType.AddProperties]}
        // FIX ME
        // @ts-ignore
        stream={stream as IGraphQLStream}
        jobType={EJobType.AddProperties}
      />,
    );

    setOpen(false);
  };

  const button = (
    <ButtonEmpty
      label="Manage properties"
      onClick={() => setOpen((prev) => !prev)}
      size="m"
      iconSide="right"
      iconName="chevronDown"
    />
  );

  const EditPropertiesPanel = useEditPropertiesPanel({
    // FIX ME
    // @ts-ignore
    inProgressJobs,
    refetch,
    setOpen,
  });

  let items: PanelItem[] = [
    {
      'data-testid': 'add-single-property-button',
      iconName: 'plus',
      label: 'Add new property',
      onClick: () => {
        // FIX ME
        // @ts-ignore
        sendMixpanelEvent(tracker, 'Add a property clicked', stream);
        showFlyout(
          <AddPropertyFlyout
            refetch={refetchPropertiesDataGrid}
            refetchGroups={refetchPropertyGroups}
          />,
        );
        setOpen(false);
      },
    },
    {
      'data-testid': 'add-properties-with-excel-button',
      iconName: 'upload',
      label: 'Add properties with an Excel file',
      onClick: () => {
        // FIX ME
        // @ts-ignore
        sendMixpanelEvent(tracker, 'Add properties with an Excel file clicked', stream);
        handleBulkAdd();
      },
    },
  ];

  if (permissions.canEditProperties) {
    items.push(EditPropertiesPanel);
  }

  if (permissions.canDeleteProperties || isAdmin) {
    const RestorePropertiesPanel = useRestoreDisposedPanel({ setOpen });
    const RestoreDeletedPropertiesPanel = useRestoreDeletedPropertiesPanel({
      setOpen,
    });
    items = [...items, RestorePropertiesPanel, RestoreDeletedPropertiesPanel];
  }

  return (
    <ContextMenu
      button={button}
      open={open}
      onClose={() => setOpen(false)}
      panel={{
        items,
      }}
    />
  );
};
