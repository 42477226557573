import { DragEvent, FC, useState } from 'react';
import { useDocumentsContext } from '@onarchipelago/platform/Library/context/DocumentsContext';
import { EuiText, Icon } from 'ui';
import DropZone from '@app/components/Upload/DropZone';
import FilesUploader from '@app/components/Upload/FilesUploader';
import styles from './documentUpload.scss';

interface DocumentUploadProps {
  organizationId?: string;
  propertyID?: string;
  children?: JSX.Element;
  isLibraryV2?: boolean;
}

const DocumentUpload: FC<DocumentUploadProps> = ({
  organizationId,
  propertyID,
  children,
  isLibraryV2,
}) => {
  const [dragOver, setDragOver] = useState<boolean>(false);
  const { orgId } = useDocumentsContext().meta;

  const preventDefaults = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    preventDefaults(event);
    if (!dragOver) {
      setDragOver(true);
    }
  };

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    preventDefaults(event);
    // If the drag leaves the parent for a child, then this condition is not invoked.
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setDragOver(false);
    }
  };

  return (
    <FilesUploader
      organizationId={organizationId || orgId}
      propertyID={propertyID}
      eventPrefix="Library"
      isLibraryV2={isLibraryV2}
    >
      <DropZone
        organizationId={organizationId || orgId}
        propertyID={propertyID}
        eventPrefix="Library"
        isLibraryV2={isLibraryV2}
      >
        <div data-testid="document-upload" onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
          {children ? (
            children
          ) : (
            <div className={[styles.uploadContainer].join(' ')}>
              <div className={styles.textContainer}>
                <Icon color="brandGreen" name="fileText" size="l" />
                <EuiText className={styles.text}>
                  Drag and drop your files here or <u>Browse files to upload</u>
                </EuiText>
              </div>
            </div>
          )}
        </div>
      </DropZone>
    </FilesUploader>
  );
};

export default DocumentUpload;
