import moment from 'moment';
import { formatPercentage as getPercentageValue } from '@app/queries/explorers/utils';

const DEFAULT_LOCALE = 'en-US';

export type Val = undefined | string | number | null;

// use the user's browser default
export const getLocale = (locale?: string) => {
  if (locale) {
    if (Intl.NumberFormat.supportedLocalesOf(locale).length === 0) {
      console.warn(`Provided locale ${locale} is not supported in this environment.`);
    }

    return locale;
  }

  if (window?.navigator?.languages?.length) {
    return window.navigator.languages[0];
  }

  return DEFAULT_LOCALE;
};

/* Number Formatting */
type FormatNumber = (val: Val, locale?: string, options?: Intl.NumberFormatOptions) => string;
export const formatNumber: FormatNumber = (val, locale, options) => {
  if (val || val === 0) {
    const { format } = new Intl.NumberFormat(getLocale(locale), {
      useGrouping: true,
      ...(options || {}),
    });
    return format(Number(val));
  }

  return '-';
};

export const getNumberFormatOptions = (decimals?: number | null): Intl.NumberFormatOptions => {
  const d = decimals as number;
  if (Number.isNaN(d)) {
    return {};
  }

  return {
    minimumFractionDigits: d,
  };
};

/* Currency Formatting */
interface ICurrencyValue {
  parsedValue: number;
  unit: string;
  shortAbbreviation: string;
  amount?: number;
}

const THOUSAND = 1000;
const MILLION = THOUSAND * 1000;
const BILLION = MILLION * 1000;
const TRILLION = BILLION * 1000;
const amounts = [
  {
    amount: THOUSAND,
    shortAbbreviation: 'k',
    unit: 'k',
  },
  {
    amount: MILLION,
    shortAbbreviation: 'Mln',
    unit: 'M',
  },
  {
    amount: BILLION,
    shortAbbreviation: 'Bln',
    unit: 'B',
  },
  {
    amount: TRILLION,
    shortAbbreviation: 'Tln',
    unit: 'T',
  },
];

export const getCurrencyValue = (_value: number): ICurrencyValue => {
  let value = _value;

  // Make sure the value passed in is processed as a positive.
  const isNegative = _value < 0;
  if (isNegative) {
    value = value * -1;
  }

  return amounts.reduce(
    (calculated, { unit, amount, shortAbbreviation }) => {
      if (value >= amount) {
        return {
          amount,
          // If the original amount was negative then convert it back to negative.
          parsedValue: isNegative ? (value / amount) * -1 : value / amount,
          shortAbbreviation,
          unit,
        };
      }

      return calculated;
    },
    {
      // If the original amount was negative then convert it back to negative.
      parsedValue: isNegative ? value * -1 : value,
      shortAbbreviation: '',
      unit: '',
    },
  );
};

export const formatCurrencyOptions = {
  currency: 'USD',
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
  style: 'currency',
};
export interface IFormatCurrencyOptions {
  locale?: string;
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  displayWithoutUnits?: boolean;
  displayPositiveSign?: boolean;
}

const removeUndefinedOptions = (options: IFormatCurrencyOptions) => {
  const newOptions = {};
  Object.keys(options).forEach((key) => {
    if (options[key] === Object(options[key])) {
      newOptions[key] = removeUndefinedOptions(options[key]);
    } else if (options[key] !== undefined && options[key] !== null) {
      newOptions[key] = options[key];
    }
  });
  return newOptions;
};

export type FormatCurrency = (val: Val, options?: IFormatCurrencyOptions) => string;

const addPositiveSign = (displayNumber: string) => `+${displayNumber}`;

export const formatCurrency: FormatCurrency = (val, options = {}) => {
  const { locale, displayWithoutUnits, displayPositiveSign } = options;
  if (val === undefined) {
    return '-';
  }

  const value = Number(`${val}`.split(',').join(''));
  if (Number.isNaN(value)) {
    console.warn(`Invalid currency provided: ${val}`);
    return '-';
  }

  const { parsedValue, unit } = getCurrencyValue(value);

  const filteredOptions: IFormatCurrencyOptions = removeUndefinedOptions(options);

  const style = filteredOptions?.currency === 'ZZZ' ? undefined : formatCurrencyOptions.style;

  const isPositive = value > 0;

  const currencyOptions = {
    ...formatCurrencyOptions,
    ...filteredOptions,
    style,
  };

  if (unit === '' || displayWithoutUnits === true) {
    const unitlessValue = displayWithoutUnits ? value : parsedValue;

    const effectiveValue = formatNumber(unitlessValue, locale, {
      ...currencyOptions,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    return displayPositiveSign && isPositive ? addPositiveSign(effectiveValue) : effectiveValue;
  }

  // Round value only if it doesn't change multiple of 10 (ex: 999.99 -> 1000).
  const stringValue = parsedValue.toFixed(1);

  const logParsedValue = Math.floor(Math.log10(parsedValue));
  const logRoundedValue = Math.floor(Math.log10(Number(stringValue)));
  const shouldRound = (isPositive && logParsedValue !== 0) || logRoundedValue / logParsedValue > 1;

  const finalVal = shouldRound ? parsedValue.toString() : parsedValue.toFixed(1);

  const [wholeNumber, decimal] = finalVal.split('.');
  const truncatedValue = `${wholeNumber}.${
    decimal && decimal.length > 0 ? decimal.slice(0, 1) : 0
  }`;

  const effectiveValue = `${formatNumber(truncatedValue, locale, currencyOptions)}${unit}`;
  return displayPositiveSign && isPositive ? addPositiveSign(effectiveValue) : effectiveValue;
};

// sc-73142
/*
export const formatCurrencyWithoutSign = (val: Val) =>
  val === null ? '-' : formatCurrency?.(val, { currency: 'ZZZ' }); */

export const formatPercentage = (val: Val, multiply: boolean = true): string => {
  if (val === undefined) {
    return '-';
  }

  const value = Number(`${val}`);
  if (Number.isNaN(value)) {
    console.warn(`Invalid percentage provided: ${val}`);
    return '-';
  }

  return `${getPercentageValue(value, multiply)}%`;
};

export const formatStringAsPercentage = (val: Val): string => {
  if (val === undefined) {
    return '-';
  }

  const value = Number(`${val}`);
  if (Number.isNaN(value)) {
    console.warn(`Invalid percentage provided: ${val}`);
    return '-';
  }

  return `${value}%`;
};

/* Date Formatting */
export enum DateStyle {
  Standard = 'standard',
  Short = 'short',
  Server = 'server',
}

const utcTimeZone = 'UTC';
const dateFormatters = {
  // 'Jan 2, 2019'
  [DateStyle.Standard]: new Intl.DateTimeFormat(getLocale(), {
    day: 'numeric',
    month: 'short',
    timeZone: utcTimeZone,
    year: 'numeric',
  }).format,
  // '2019-01-02'
  [DateStyle.Short]: (date: Date) => moment(date).format('YYYY-MM-DD'),
  // '2019-01-02T00:00:00.000Z'.
  // The characters in the format string wrapped in brackets are escaped.
  [DateStyle.Server]: (date: Date) => moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
};
type FormatDate = (date: Date | number | string, style?: DateStyle) => string;
export const formatDate: FormatDate = (date, style = DateStyle.Standard) => {
  if (!date) {
    return '-';
  }
  if (!dateFormatters[style]) {
    throw new Error(`Invalid date style ${style} specified`);
  }

  try {
    if (typeof date === 'string') {
      return dateFormatters[style](new Date(date));
    }

    if (typeof date === 'number') {
      return dateFormatters[style](new Date(date * 1000));
    }

    return dateFormatters[style](date);
  } catch (err) {
    throw new Error(`${err.message} | Value: ${date} | Style: ${style}`);
  }
};

// parseAndFormatUTCDate - parses the date string and returns a human readable formatted date string. It treats it's input and output as UTC - making sure that what's in the database is correctly displayed.
// Example parse '2017-05-31T00:00:00Z' to date and return 'May 31, 2017';
export const parseAndFormatUTCDate = (date: string) => {
  // TODO : We have values of 'Invalid date' on our database
  if (date.length < 1 || date === 'Invalid date') {
    return '';
  }
  if (date.length <= 10) {
    // If it's just a date like '2021-01-01' then treat the input as UTC.
    return moment.utc(date).format('MMM DD, YYYY');
  }
  return moment.utc(date).format('MMM DD, YYYY');
};

const roundOneDecimal = (i: number): number => Math.round(i * 10) / 10;

export const formatFileSize = (size: number): string => {
  const gb = size / (1024 * 1024 * 1024);
  if (gb > 1) {
    return `${roundOneDecimal(gb)} GB`;
  }

  const mb = size / (1024 * 1024);
  if (mb > 1) {
    return `${roundOneDecimal(mb)} MB`;
  }

  const kb = size / 1024;
  if (kb > 1) {
    return `${roundOneDecimal(kb)} KB`;
  }

  return `${roundOneDecimal(size)} B`;
};

export const formatMaxThreshold = (value: number | string, max: number): string =>
  value <= max ? (value as string) : `> ${max}`;

// formatDefault returns the value or replaces it if falsy (except if 0 and false)
export const formatDefault = (value: any, defaultString: string): string => {
  if (value === 0 || value === false) {
    return value;
  }

  return value || defaultString;
};

export const formatBoolean = (bool: any, defaultString: string): string => {
  if (bool === false || bool === 0) {
    return 'No';
  }
  return bool ? 'Yes' : defaultString;
};

// Format booleans exclusively for Prov Enrichment
export const formatPEBoolean = (bool: null | boolean | 0 | string): string => {
  if (bool === null || bool === '' || bool === 'Blank') {
    return null;
  }

  if (bool === false || bool === 0) {
    return 'No';
  }

  return 'Yes';
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes <= 0) {
    return '';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const formatFilename = (filename: string, extension?: string): string =>
  `${filename}${extension ? '.' + extension : ''}`;
