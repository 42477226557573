import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ISnapshot } from '@onarchipelago/cx/Stream/types';
import { GraphQLError } from 'graphql';
import moment from 'moment';
import { useToast } from 'ui';
// FIX ME
// @ts-ignore
import GET_STREAM_SNAPSHOT_CONFIG from '@app/queries/streams/snapshots/getSnapshotConfig.gql';
import {
  IMutationVariables,
  IUpdateSnapshotConfigInput,
} from '@app/queries/streams/snapshots/types';
// FIX ME
// @ts-ignore
import UPDATE_SNAPSHOT_CONFIG from '@app/queries/streams/snapshots/updateSnapshotConfig.gql';
import { IGraphQLStream } from '@app/queries/streams/types';

interface Props {
  onClose: () => void;
  snapshot: ISnapshot;
  stream: IGraphQLStream;
}

interface Return {
  isSaving: boolean;
  onSave: () => Promise<void>;
}

export default ({ onClose, snapshot, stream }: Props): Return => {
  const [updateSnapshotConfig] = useMutation<
    boolean,
    IMutationVariables<IUpdateSnapshotConfigInput>
  >(UPDATE_SNAPSHOT_CONFIG);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const toast = useToast();

  const onSave = async () => {
    setIsSaving(true);
    let err = false;
    await updateSnapshotConfig({
      refetchQueries: [
        { query: GET_STREAM_SNAPSHOT_CONFIG, variables: { streamSlug: stream.slug } },
      ],
      variables: {
        input: {
          bound: !!snapshot.bound,
          description: snapshot.description,
          displayDate: snapshot.displayDate
            ? moment(snapshot.displayDate).utc().format()
            : undefined,
          // FIX ME
          // @ts-ignore
          marketVisible: !!snapshot.marketVisible,
          name: snapshot.name,
          snapshotId: snapshot.id,
          streamSlug: stream.slug,
          visible: !!snapshot.visible,
        },
      },
    }).catch((e) => {
      err = true;
      const message =
        (e as GraphQLError).message.split('GraphQL error:')[1] ||
        'Could not update snapshot configuration';
      toast({ title: message, type: 'danger' });
    });

    setIsSaving(false);

    if (!err) {
      toast({ title: 'Successfully updated snapshot configuration', type: 'success' });
      onClose();
    }
  };

  return {
    isSaving,
    onSave,
  };
};
