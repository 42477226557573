import { IDocumentModalStream } from '@onarchipelago/cx/DocumentsModal//types';
import gql from 'graphql-tag';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';

// ----- QUERY ----- //

export const GET_STREAM = gql`
  query GetStream($slug: ID!, $userCode: String!) {
    stream(slug: $slug, userCode: $userCode) {
      id
      name
      slug
      organizationId
      orgName
      allowedExports
      defaultSnapshot
      snapshots {
        id
        name
        date
        description
        bound
        displayDate
      }
    }

    propertyAttributeMetadata(streamSlug: $slug) {
      name
    }
  }
`;

// ----- TYPES ----- //

export interface GetStreamData {
  stream: IDocumentModalStream;
  propertyAttributeMetadata: Array<Pick<IGraphQLAttributeMetadata, 'name'>>;
}

export interface GetStreamVariables {
  slug: string;
  userCode: string;
}
