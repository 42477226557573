import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationInfoFragment = { __typename?: 'OrgInfo', id: string, name: string, type: string, industry?: string | null, businessType?: string | null, enableUploadPortal: boolean, enableRedirectOnSOVUpload: boolean, enableSubmissionDashboard: boolean, enableUnderwriterEdit: boolean, enabledFeatures?: Array<string> | null, enableSubValues: boolean, enableSOVManager: boolean, enablePreCheck: boolean, orgLevel: Types.OrgLevelType, enterpriseOrg?: { __typename?: 'OrgInfo', name: string } | null };

export type OrgPermissionsFragment = { __typename?: 'OrgPermissions', viewEnterpriseReports: boolean, chat: boolean, canManageRoles?: Array<string> | null, canViewInsights: boolean, canAddDocumentToStream: boolean, canLinkUnlinkDocumentProperty: boolean };

export type OrgInfoQueryVariables = Types.Exact<{
  input: Types.OrgInfoInput;
}>;


export type OrgInfoQuery = { __typename?: 'Query', orgInfo: { __typename?: 'OrgInfoResponse', orgInfo: { __typename?: 'OrgInfo', id: string, name: string, type: string, industry?: string | null, businessType?: string | null, enableUploadPortal: boolean, enableRedirectOnSOVUpload: boolean, enableSubmissionDashboard: boolean, enableUnderwriterEdit: boolean, enabledFeatures?: Array<string> | null, enableSubValues: boolean, enableSOVManager: boolean, enablePreCheck: boolean, orgLevel: Types.OrgLevelType, enterpriseOrg?: { __typename?: 'OrgInfo', name: string } | null }, permissions: { __typename?: 'OrgPermissions', viewEnterpriseReports: boolean, chat: boolean, canManageRoles?: Array<string> | null, canViewInsights: boolean, canAddDocumentToStream: boolean, canLinkUnlinkDocumentProperty: boolean }, permissionsV2?: Array<{ __typename?: 'PermissionV2', resource: string, action: string }> | null } };

export const OrganizationInfoFragmentDoc = gql`
    fragment organizationInfo on OrgInfo {
  id
  name
  type
  industry
  businessType
  enableUploadPortal
  enableRedirectOnSOVUpload
  enableSubmissionDashboard
  enableUnderwriterEdit
  enabledFeatures
  enableSubValues
  enableSOVManager
  enablePreCheck
  orgLevel
  enterpriseOrg {
    name
  }
}
    `;
export const OrgPermissionsFragmentDoc = gql`
    fragment orgPermissions on OrgPermissions {
  viewEnterpriseReports
  chat
  canManageRoles
  canViewInsights
  canAddDocumentToStream
  canLinkUnlinkDocumentProperty
}
    `;
export const OrgInfoDocument = gql`
    query OrgInfo($input: OrgInfoInput!) {
  orgInfo(input: $input) {
    orgInfo {
      ...organizationInfo
    }
    permissions {
      ...orgPermissions
    }
    permissionsV2 {
      resource
      action
    }
  }
}
    ${OrganizationInfoFragmentDoc}
${OrgPermissionsFragmentDoc}`;

/**
 * __useOrgInfoQuery__
 *
 * To run a query within a React component, call `useOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrgInfoQuery(baseOptions: Apollo.QueryHookOptions<OrgInfoQuery, OrgInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgInfoQuery, OrgInfoQueryVariables>(OrgInfoDocument, options);
      }
export function useOrgInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgInfoQuery, OrgInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgInfoQuery, OrgInfoQueryVariables>(OrgInfoDocument, options);
        }
export type OrgInfoQueryHookResult = ReturnType<typeof useOrgInfoQuery>;
export type OrgInfoLazyQueryHookResult = ReturnType<typeof useOrgInfoLazyQuery>;
export type OrgInfoQueryResult = Apollo.QueryResult<OrgInfoQuery, OrgInfoQueryVariables>;