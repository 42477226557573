import { memo, useContext } from 'react';
import { isEmpty } from 'lodash';
import {
  ButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageHeader,
  EuiSpacer,
  EuiText,
  EuiToolTip,
  Icon,
} from 'ui';
import Currency from '@app/components/Currency/Currency';
import { Explore } from '@app/components/Explore/Explore';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import SovProcessingPage from '@app/components/SovProcessingPage/SovProcessingPage';
import ProtectedModalRoutes from '@app/containers/App/Routes/ProtectedModalRoutes';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { IGraphQLStream } from '@app/queries/streams/types';
import { formatDate } from '@app/utils/format';
import EditSOVBasicInfoFlyout from '../Stream/EditSOVBasicInfoFlyout/EditSOVBasicInfoFlyout';
import ExportButton from '../Stream/ExportButton/ExportButton';
import { StreamProvider, useStreamContext } from '../Stream/StreamProvider';
import { canRequestAccess } from '../Stream/StreamRender/canRequestAccess';
import RequestAccess from '../Stream/StreamRender/RequestAccess/RequestAccess';
import { AddPropertyButton } from './Header/AddPropertyButton';
import { HeaderSnapshotButton } from './Header/HeaderSnapshotButton';
import { OtherSettingsButton } from './Header/OtherSettingsButton';
import { MarketVisibleToggle } from './MarketVisibleToggle';
import { PropertiesPageProvider, usePropertiesPageContext } from './PropertiesPage.context';
import { PageSectionContainer } from './PropertiesPage.emotion';

const PropertiesPageContent = () => {
  const { account } = useAuth();
  // FIX ME
  // @ts-ignore
  const { groups } = usePropertiesPageContext();
  const { permissions, stream, loading, marketVisibleToggle, marketVisibleSnapshots } =
    useStreamContext();
  const isAdmin = account?.permissions?.admin;

  if (loading) {
    return <LoadingSpinnerV2 />;
  }

  const hasEditPropertyPanels = permissions?.canEditProperties || permissions?.canAddProperties;
  const hasEditLossPanels = permissions?.canEditLosses || permissions?.canAddLosses;
  const hasEditDataPanels = hasEditPropertyPanels || hasEditLossPanels;
  const hasManageSnapshots = isAdmin || (permissions.canManageSnapshots && !marketVisibleToggle);

  const manageRightSideButtons = [<OtherSettingsButtonMemo key="other-settings-button" />];

  manageRightSideButtons.push(<ExportButtonMemo key="export-button" />);

  if (hasEditDataPanels && permissions?.canAddProperties) {
    manageRightSideButtons.push(<AddPropertyButtonMemo key="add-property-button" />);
  }

  const pageTitle = (
    <EuiFlexGroup alignItems="center" gutterSize="m">
      <EuiFlexItem grow={false}>
        <EuiFlexGroup alignItems="center" justifyContent="flexStart" gutterSize="m">
          <EuiFlexItem grow={false}>
            <EuiText>
              <h1>{stream?.name}</h1>
            </EuiText>
          </EuiFlexItem>
          {hasManageSnapshots && !isEmpty(stream) && (
            <EuiFlexItem grow={false}>
              <EditSOVButton stream={stream} />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
      <HeaderSnapshotButton />
      {stream?.effectiveDate && stream?.expiryDate && (
        <EuiToolTip
          position="bottom"
          content={
            <EuiText>
              {formatDate(stream?.effectiveDate)}
              {' - '}
              {formatDate(stream?.expiryDate)}
            </EuiText>
          }
          anchorProps={{ style: { display: 'flex' } }}
        >
          {/* @ts-ignore */}
          <Icon name="info" size="m" color="primary" style={{ verticalAlign: 'middle' }} />
        </EuiToolTip>
      )}
      {!stream?.isMyProperties && <MarketVisibleToggle />}
    </EuiFlexGroup>
  );

  const hideContent = marketVisibleToggle && marketVisibleSnapshots.length === 0;

  const isProcessing = stream?.submissionStatus === 'Processing In Progress';

  if (isProcessing) {
    return <SovProcessingPage streamName={stream?.name} />;
  }

  return (
    <PageSectionContainer paddingSize="xl">
      <EuiPageHeader
        pageTitle={pageTitle}
        description={
          !hideContent && (
            <EuiText>
              <b>{groups?.filteredPropertyCount}</b> properties with{' '}
              <b>
                <Currency value={groups?.stats?.tivChart?.sum} />
              </b>{' '}
              TIV
            </EuiText>
          )
        }
        rightSideItems={manageRightSideButtons}
      />
      {hideContent ? (
        <>
          <EuiSpacer size="l" />
          <h2>There are no market visible snapshots</h2>
        </>
      ) : (
        <Explore />
      )}
    </PageSectionContainer>
  );
};

export const PropertiesPage = () => (
  <PropertiesPageProvider>
    <StreamProvider>
      <ProtectRoutesContainer />
    </StreamProvider>
  </PropertiesPageProvider>
);

const ProtectRoutesContainer = () => {
  const { loading, error, stream } = useStreamContext();

  if (loading && !error) {
    return <LoadingSpinnerV2 dataTestId="loading-spinner" />;
  }

  if (canRequestAccess(error) || stream === undefined) {
    return <RequestAccess />;
  }

  return (
    <>
      <ProtectedModalRoutes />
      <PropertiesPageContent />
    </>
  );
};

// FIX ME
// @ts-ignore
const ExportButtonMemo = memo(() => <ExportButton />);

const AddPropertyButtonMemo = memo(() => <AddPropertyButton />);

const OtherSettingsButtonMemo = memo(() => <OtherSettingsButton />);

const EditSOVButton = memo(({ stream }: { stream: IGraphQLStream }) => {
  const { showFlyout } = useContext(FlyoutContext);
  const onClick = () => showFlyout(<EditSOVBasicInfoFlyout stream={stream} />);
  return <ButtonIconMemo onClick={onClick} />;
});

const ButtonIconMemo = memo(({ onClick }: { onClick: () => void }) => (
  <ButtonIcon color="primary" iconName="pencil" size={'m'} onClick={onClick} />
));
