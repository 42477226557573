import { FC, ReactNode, useContext, useState } from 'react';
import { useToast } from 'ui';
import { UploadsContext } from '@app/contexts/UploadsContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { getFilteredFiles } from './utils/utils';
interface IProps {
  organizationId?: string;
  submissionId?: string;
  propertyID?: string;
  // eventPrefix is used for MixPanel tracking and is use to help indicate to source of the event.
  eventPrefix: string;
  children: ReactNode;
  isLibraryV2?: boolean;
}

const DropZone: FC<IProps> = ({
  children,
  organizationId,
  submissionId,
  propertyID,
  eventPrefix,
  isLibraryV2 = false,
}) => {
  const { selectedOrganization } = useContext(UserSessionContext);
  const orgId = organizationId || selectedOrganization?.id;
  const { uploadFiles } = useContext(UploadsContext);
  const toast = useToast();
  const [showDropZone, setShowDropZone] = useState(false);

  const preventDefaults = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragEnter = (event: any) => {
    preventDefaults(event);
    setShowDropZone(true);
  };

  const onDragLeave = (event: any) => {
    preventDefaults(event);
    setShowDropZone(false);
  };

  const onDrop = (event: any) => {
    preventDefaults(event);

    if (isLibraryV2) {
      const files: any = getFilteredFiles(event.dataTransfer.files, toast);
      if (files.length > 0) {
        uploadFiles({ eventPrefix, files, orgId, propertyID, submissionId });
      }
    } else {
      const files = event.dataTransfer.files;
      uploadFiles({ eventPrefix, files, orgId, propertyID, submissionId });
    }
    setShowDropZone(false);
  };

  return (
    <div id="dropZone" onDragEnter={onDragEnter} onDragOver={preventDefaults}>
      <div onDragLeave={onDragLeave} onDrop={onDrop} className={showDropZone ? 'showDropZone' : ''}>
        {children}
      </div>
    </div>
  );
};
export default DropZone;
