import { useContext } from 'react';
import { EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle } from 'ui';
import { ModalContext } from '@app/contexts/ModalContext';
import { IGraphQLStream } from '@app/queries/streams/types';
import SnapshotsTab from './Tabs/SnapshotsTab/SnapshotsTab';
import styles from './EditStreamModal.emotion';

interface Props {
  stream: IGraphQLStream;
}

const ManageSnapshots = ({ stream }: Props) => {
  const { closeModal } = useContext(ModalContext);
  return (
    <EuiModal
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      className={styles.modal}
      onClose={closeModal}
    >
      <EuiModalHeader data-testid="edit-stream-modal">
        <EuiModalHeaderTitle>Manage snapshots</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody className={styles.modalBody}>
        <SnapshotsTab stream={stream} simplifiedStreamSetting={false} />
      </EuiModalBody>
    </EuiModal>
  );
};

export default ManageSnapshots;
