import { memo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ButtonIcon, ContextMenu } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { integrationsApolloClient } from '@app/cx/Organizations/Tabs/IntegrationsTab/IntegrationsApolloClient';
import useAddLossDataPanel from '@app/cx/Stream/ManageStreamButtonPanels/useAddLossDataPanel';
import useEditLossDataPanel from '@app/cx/Stream/ManageStreamButtonPanels/useEditLossDataPanel';
import { useManageExportsPanel } from '@app/cx/Stream/ManageStreamButtonPanels/useManageExportsPanel';
import useMarketAccessPanel from '@app/cx/Stream/ManageStreamButtonPanels/useMarketAccessPanel';
import useRunIntegrationsPanel from '@app/cx/Stream/ManageStreamButtonPanels/useRunIntegrationsPanel';
import useStreamSettingsPanel from '@app/cx/Stream/ManageStreamButtonPanels/useStreamSettingsPanel';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { E2VALUE } from '@app/platform/Integrations/consts';
import {
  FEATURE_LOSS_DATA,
  FEATURE_TYPE_AIRTRAFFICCONTROL,
  FEATURE_TYPE_CONFIGURABLE_EXPORTS,
} from '@app/platform/SystemSettings/Flags/types';
//@ts-ignore
import GET_USAGE from '@app/queries/integrations/getUsage.gql';
import { IUsageData, IUsageVariables } from '@app/queries/integrations/types';
import { EJobType } from '@app/queries/organizations/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { usePropertiesPageContext } from '../PropertiesPage.context';
import Styles from './OtherSettingsButton.scss';

export const OtherSettingsButton = () => {
  const { stream } = usePropertiesPageContext();
  const { permissions, marketVisibleToggle, lock, refetch } = useStreamContext();
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  const { selectedOrganization } = useUserSession();
  const [open, setOpen] = useState(false);

  const { data } = useQuery<IUsageData, IUsageVariables>(GET_USAGE, {
    client: integrationsApolloClient,
    variables: {
      input: {
        organizationID: stream?.organizationId,
        partnerName: E2VALUE,
      },
    },
  });

  const inProgressJobs: Partial<Record<EJobType, Lock>> = {};
  (lock?.locks || []).forEach((jobInfo) => {
    if (!!inProgressJobs[jobInfo.type] || !jobInfo.canReuse) {
      return;
    }
    // FIX ME
    // @ts-ignore
    inProgressJobs[jobInfo.type] = jobInfo;
  });

  // const EditPropertiesPanel = useEditPropertiesPanel({
  //   // FIX ME
  //   // @ts-ignore
  //   inProgressJobs,
  //   refetch,
  //   setOpen,
  // });

  // get org level permissions (risk manager)
  const orgPermissions = getOrgPermissions(account, stream?.organizationId);

  const canConfigureExports =
    isFeatureEnabled(stream?.enabledFeatures, FEATURE_TYPE_CONFIGURABLE_EXPORTS) &&
    orgPermissions.includes('canManageProperties') &&
    marketVisibleToggle !== true;

  const isAirTrafficControlEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_TYPE_AIRTRAFFICCONTROL,
  );

  const isLossDataEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_LOSS_DATA,
  );

  const manageExportsPanel = useManageExportsPanel({ setOpen });
  const StreamSettingsPanel = useStreamSettingsPanel();
  const marketAccessPanel = useMarketAccessPanel();
  // FIX ME
  // @ts-ignore
  let items = [];

  if (!stream?.isMyProperties && isAirTrafficControlEnabled && isAdmin) {
    items.push(marketAccessPanel);
  }
  if (isAdmin) {
    items.push(StreamSettingsPanel);
  }
  if (canConfigureExports) {
    items.push(manageExportsPanel);
  }

  if (permissions.canAddLosses && isLossDataEnabled) {
    // FIX ME
    // @ts-ignore
    const AddLossDataPanel = useAddLossDataPanel({ inProgressJobs, setOpen });
    // FIX ME
    // @ts-ignore
    const EditLossDataPanel = useEditLossDataPanel({ inProgressJobs, refetch, setOpen });
    items = [...items, AddLossDataPanel, EditLossDataPanel];
  }

  const RunIntegrationsPanel = useRunIntegrationsPanel();

  if (isAdmin && data?.usage?.enabled) {
    items.push(RunIntegrationsPanel);
  }

  const button = <ButtonMemo setOpen={setOpen} open={open} />;

  if (items.length < 1) {
    return null;
  }

  return (
    <ContextMenu
      className={Styles.otherSettingsContextMenu}
      button={button}
      panel={{ items }}
      onClose={() => setOpen(false)}
      open={open}
    />
  );
};

const ButtonMemo = memo(
  ({ setOpen, open }: { setOpen: (open: boolean) => void; open: boolean }) => (
    <ButtonIcon
      iconName="moreHorizontal"
      size="l"
      border
      color="text"
      onClick={() => setOpen(!open)}
    />
  ),
);
