import { memo } from 'react';
import { Asset, AssetName, EuiFlexGroup, EuiFlexItem, EuiShowFor, useEuiTheme } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useWhiteLabel } from '@app/hooks/useWhiteLabel/useWhiteLabel';
import { HeaderLogo } from '../Layout.emotion';
import { StyledButtonIcon, StyledLogoContainer } from './SideNav.emotion';
import { useNavContext } from './SideNavContext';

export const NavLogo = () => {
  const { account } = useAuth();

  const { euiTheme } = useEuiTheme();
  const { isCollapsed, setIsCollapsed } = useNavContext();
  // FIX ME
  // @ts-ignore
  const { assetName, assetLink, logoStyle } = useWhiteLabel(account, isCollapsed);

  const displayLogo = !isCollapsed || (isCollapsed && assetName === AssetName.logoAlt);
  return (
    <EuiFlexGroup alignItems="center" gutterSize="none" justifyContent="spaceBetween">
      {displayLogo && (
        <StyledLogoContainer isCollapsed={isCollapsed} grow={false}>
          <HeaderLogo {...logoStyle} side="left">
            {assetLink ? (
              <img src={assetLink} />
            ) : (
              <Asset name={assetName} color={euiTheme.colors.primary} />
            )}
          </HeaderLogo>
        </StyledLogoContainer>
      )}
      <CollapsedButtonMemo isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
    </EuiFlexGroup>
  );
};

const CollapsedButtonMemo = memo(
  ({
    isCollapsed,
    setIsCollapsed,
  }: {
    isCollapsed: boolean;
    setIsCollapsed: (isCollapsed: boolean) => void;
  }) => (
    <EuiShowFor sizes={['m', 'l', 'xl']}>
      <EuiFlexItem grow={false}>
        <StyledButtonIcon
          iconName={isCollapsed ? 'chevronRight' : 'chevronLeft'}
          size="m"
          onClick={() => setIsCollapsed(!isCollapsed)}
          isCollapsed={isCollapsed}
        />
      </EuiFlexItem>
    </EuiShowFor>
  ),
);
