import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import DeleteOrgModal from '@onarchipelago/cx/Organizations/DeleteOrgModal';
import { ButtonIcon, ContextMenu, ContextMenuItem } from 'ui';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { canManageUsers } from '@app/containers/AuthProvider/helper';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { useModalState } from '@app/contexts/ModalContext';
import AddEditOrgModal from '@app/cx/Organizations/AddEditOrgModal';
import PreRegisterOrgModal from '@app/cx/Organizations/PreRegisterOrgModal';
import SubmissionSettingsModal from '@app/cx/Organizations/SubmissionSettings/SubmissionSettingsModal';
import { TrackGroupUserMgmt, useTracker } from '@app/hooks/useTracker';
import { IGraphQLOrganization } from '@app/queries/organizations/types';

export const OrgOptions: FC<{ org: IGraphQLOrganization }> = ({ org }) => {
  const { orgType = '' } = org;
  const { account } = useAuth();
  const { showModal } = useModalState();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { closeFlyout } = useFlyout();
  const tracker = useTracker();
  const closePopover = (e?: any) => {
    setIsOpen(false);
    e?.stopPropagation();
    e?.preventDefault();
  };

  const togglePopover = () => {
    if (isOpen === false) {
      // This closes the popover menu if the parent scrolls.
      // This is a hold over from when we used an EuiFlyoutBody but it works really well so
      // for now let's continue to use it. It is possible we could do something like this
      // with refs but this seems more simple / reliable.
      document?.querySelector('.orgSwitcherBody .List')?.addEventListener(
        'scroll',
        () => {
          closePopover();
        },
        { once: true },
      );
    }
    setIsOpen((prev: boolean) => !prev);
  };
  const items: ContextMenuItem[] = [
    {
      'data-testid': `org-edit-button-${org.name.replace(/\s/g, '-').toLowerCase()}`,
      iconName: 'pencil',
      label: 'Edit',
      onClick: (e) => {
        closePopover(e);
        showModal(<AddEditOrgModal orgId={org.id} orgName={org.name} action="edit" />);
      },
    },
    {
      iconName: 'user',
      label: 'Pre-register User',
      onClick: (e) => {
        closePopover(e);
        showModal(<PreRegisterOrgModal org={org} />);
      },
    },
  ];
  if (!org?.enabledFeatures?.includes('SelfServiceUserMgmt')) {
    items.push({
      'data-testid': 'personnel-button',
      iconName: 'user',
      label: 'Personnel',
      onClick: (e) => {
        closePopover(e);
        closeFlyout();
        history.push(encodeUrl`/organizations/${org.name}/personnel`);
      },
    });
  }

  if (canManageUsers(account, org?.id, org?.enabledFeatures)) {
    items.push({
      'data-testid': 'users-button',
      iconName: 'user',
      label: 'Users',
      onClick: (e) => {
        tracker.track(`${TrackGroupUserMgmt.prefix}: Users clicked from org dropdown`);
        closePopover(e);
        closeFlyout();
        history.push(encodeUrl`/organizations/${org.name}/users`);
      },
    });
  }

  if (orgType === 'Test') {
    items.push({
      iconName: 'trash',
      label: 'Delete',
      onClick: (e) => {
        closePopover(e);
        showModal(<DeleteOrgModal org={org} />);
      },
      type: 'danger',
    });
  }

  items.push({
    iconName: 'gear',
    label: 'Account Settings',
    onClick: (e) => {
      closePopover(e);
      showModal(<SubmissionSettingsModal orgName={org.name} />);
    },
  });

  return (
    <ContextMenu
      anchorPosition="leftCenter"
      button={
        <ButtonIcon
          data-testid="org-row-context-menu-button"
          iconName="ellipsisVertical"
          size="m"
          color="text"
          onClick={(e: any) => {
            togglePopover();
            e.stopPropagation();
          }}
          aria-label="more options"
        />
      }
      onClose={closePopover}
      open={isOpen}
      panel={{ items }}
    />
  );
};
