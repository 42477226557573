import * as Types from '../../../precheck.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetValueCollectionConfigQueryVariables = Types.Exact<{
  input: Types.GetValueCollectionConfigInput;
}>;


export type GetValueCollectionConfigQuery = { __typename?: 'Query', getValueCollectionConfig?: { __typename?: 'ValueCollectionConfigResponse', configs?: Array<{ __typename?: 'ValueCollectionConfig', id: string, name: string, editableAttributes?: Array<string> | null, viewableAttributes?: Array<string> | null, filterAttributes?: Array<string> | null, startDate: any, endDate: any, active: boolean }> | null } | null };


export const GetValueCollectionConfigDocument = gql`
    query GetValueCollectionConfig($input: GetValueCollectionConfigInput!) {
  getValueCollectionConfig(input: $input) {
    configs {
      id
      name
      editableAttributes
      viewableAttributes
      filterAttributes
      startDate
      endDate
      active
    }
  }
}
    `;

/**
 * __useGetValueCollectionConfigQuery__
 *
 * To run a query within a React component, call `useGetValueCollectionConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValueCollectionConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValueCollectionConfigQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetValueCollectionConfigQuery(baseOptions: Apollo.QueryHookOptions<GetValueCollectionConfigQuery, GetValueCollectionConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValueCollectionConfigQuery, GetValueCollectionConfigQueryVariables>(GetValueCollectionConfigDocument, options);
      }
export function useGetValueCollectionConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValueCollectionConfigQuery, GetValueCollectionConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValueCollectionConfigQuery, GetValueCollectionConfigQueryVariables>(GetValueCollectionConfigDocument, options);
        }
export type GetValueCollectionConfigQueryHookResult = ReturnType<typeof useGetValueCollectionConfigQuery>;
export type GetValueCollectionConfigLazyQueryHookResult = ReturnType<typeof useGetValueCollectionConfigLazyQuery>;
export type GetValueCollectionConfigQueryResult = Apollo.QueryResult<GetValueCollectionConfigQuery, GetValueCollectionConfigQueryVariables>;