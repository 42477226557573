import * as React from 'react';
import { Button, ButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiText, useEuiTheme } from 'ui';
import { ButtonContainer, ModalContainer } from './NavigationPrompt.emotion';

interface Props {
  handleClick: () => void;
  handleClose: () => void;
  leaveButtonLabel: string;
}

const NavigationPromptModal: React.FC<Props> = ({ handleClick, handleClose, leaveButtonLabel }) => {
  const onClick = (): void => {
    handleClick();
    handleClose();
  };
  const { euiTheme } = useEuiTheme();
  const { size } = euiTheme;

  return (
    <ModalContainer size={size.l} onClose={handleClose}>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiText>
            <h2>You have unsaved changes</h2>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            You haven&apos;t saved your work. Leaving now means you will lose whatever progress
            you&apos;ve made.
          </EuiText>
        </EuiFlexItem>
        <ButtonContainer size={size.l}>
          <Button fill label={leaveButtonLabel} onClick={onClick} type="submit" />
          <ButtonEmpty onClick={handleClose} label="Cancel" />
        </ButtonContainer>
      </EuiFlexGroup>
    </ModalContainer>
  );
};

export default NavigationPromptModal;
