import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { EuiBasicTable, EuiLink, EuiProgress, EuiText, Icon, Select, useEuiTheme } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { IFile, IFileList, UploadsContext } from '@app/contexts/UploadsContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { IGraphQLSort } from '@app/cx/Stream/Reef/PropertiesPageQuery/types';
import { BinaryOperator } from '@app/graphql/types';
// FIX ME
// @ts-ignore
import GET_FILES from '@app/queries/documents/getOrganizationDocumentsPage.gql';
import {
  IGraphQLDocument,
  OrganizationDocumentsPageData,
  OrganizationDocumentsPageVariables,
} from '@app/queries/documents/types';
import { formatFilename } from '@app/utils/format';
import {
  ActionComplete,
  ActionError,
  ActionProcessing,
  ActionUploading,
  DocumentColumn,
} from './FilesTable.emotion';

const iconClass = (classification: string = ''): string => {
  switch (classification?.toLowerCase()) {
    case 'drawing':
      return 'drawing';
    case 'environmental report':
    case 'loss engineering report':
    case 'engineering report':
    case 'thermography report':
    case 'valuation report':
    case 'seismic inspection':
      return 'reports';
    case 'image':
      return 'image';
    case 'roof inspection':
    case 'roof warranty':
      return 'roofing';
    case 'pca':
      return 'pca';
    case 'sov':
      return 'sov';
    case 'error':
      return 'error';
    case 'unknown':
    case 'loading':
      return 'unknown';
    case 'all documents':
      return 'all';
    default:
      return 'unknown';
  }
};

type File = IGraphQLDocument & IFile & { age: number };

const isComplete = (item: File): boolean => item?.progress === 100;

const isUploading = (item: File): boolean => item?.progress < 100;

const isProcessing = (item: File): boolean =>
  isComplete(item) && item?.classification === 'Pending' && item?.age < 5;

const isError = (item: File): boolean => item?.error;

type IProps = {
  showTitle?: boolean;
  organizationName?: string;
  organizationId?: string;
  submissionId?: string;
  onlyShowNew?: boolean;
  isLibraryUploadsView?: boolean;
};
const FilesTable: FC<IProps> = ({
  organizationName,
  organizationId,
  submissionId,
  onlyShowNew = false,
  isLibraryUploadsView = false,
}) => {
  const { account } = useAuth();
  const { selectedOrganization } = useContext(UserSessionContext);
  const { euiTheme } = useEuiTheme();
  const orgName =
    organizationName || selectedOrganization?.name || account?.docUploadOrgs?.[0]?.name || '';

  // Polyfill for fromEntries
  function fromEntries<T>(iterable: any): T {
    return [...iterable].reduce((obj, [key, val]) => {
      obj[key] = val;
      return obj;
    }, {} as T);
  }

  const destinationId = submissionId || organizationId;

  const filterByDest = (list: IFileList) =>
    fromEntries<IFileList>(
      Object.entries(list).filter(([, v]) => v?.organization?.id === destinationId),
    );

  const { uploads: allUploads } = useContext(UploadsContext);
  const [uploads, setUploads] = useState(destinationId ? filterByDest(allUploads) : allUploads);

  useEffect(() => {
    if (allUploads) {
      setUploads(destinationId ? filterByDest(allUploads) : allUploads);
    }
  }, [allUploads]);

  const [items, setItems] = useState([] as IFile[]);

  const [queryCount, setQueryCount] = useState(0);

  const ALL_DOCUMENTS = 'All Documents';
  const [filterByClass, setFilterByClass] = useState(ALL_DOCUMENTS);

  const sortableColumns = ['filename', 'createdAt'];
  const sortableOrders = ['ASCENDING', 'DESCENDING'];

  const sortBy = {
    attributeName: sortableColumns[1],
    order: sortableOrders[1],
  } as IGraphQLSort;

  const cursor = null;

  const { error, data, startPolling, stopPolling } = useQuery<
    OrganizationDocumentsPageData,
    OrganizationDocumentsPageVariables
  >(GET_FILES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      setQueryCount((prev) => prev + 1);
    },
    skip: orgName === undefined || onlyShowNew,
    variables: {
      cursor,
      filter: {
        filters: [{ name: 'origin', operator: BinaryOperator.Equal, values: ['customer'] }],
        orgName,
        pageSize: 100,
        sortBy: [sortBy],
      },
    },
  });

  const columns = [
    {
      id: 'filename',
      name: 'Document',
      render: (item: any) => (
        <>
          <DocumentColumn
            className={`fileIcon ${iconClass(item?.classification)}`}
            title={item?.classification}
          >
            <Icon name="fileText" size="l" />
          </DocumentColumn>
          <span style={{ fontSize: '16px', lineHeight: '24px', marginLeft: '24px' }}>
            {formatFilename(item.filename, item.extension)}
          </span>
        </>
      ),
    },
    {
      id: 'classification',
      name: onlyShowNew ? 'Progress' : 'Type',
      render: (item: any) => {
        let classification = item.classification;

        if (
          isComplete(item) &&
          !isProcessing(item) &&
          (item?.classification === 'Pending' || !item?.classification)
        ) {
          classification = 'Uploaded';
        }

        const showError = isError(item);
        const showClassification =
          !!item.id && item.id !== '0' && !isProcessing(item) && !onlyShowNew && !showError;
        const showProcessing = isProcessing(item) && !onlyShowNew && !showError;
        const showProgress =
          !showError && (onlyShowNew || ((!item.id || item.id === '0') && !isProcessing(item)));

        return (
          <>
            {showClassification && (
              <span style={{ fontSize: '16px', lineHeight: '24px' }}>{classification}</span>
            )}
            {showError && (
              <span style={{ color: euiTheme.colors.danger, fontSize: '16px', lineHeight: '24px' }}>
                File upload failed. Please re-try or contact{' '}
                <Link to="/support">
                  <EuiLink>support</EuiLink>
                </Link>
              </span>
            )}
            {showProcessing && (
              <span
                style={{
                  color: '#98a2b3',
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'right',
                  width: '100%',
                }}
              >
                Upload complete! Detecting document type...
              </span>
            )}
            {showProgress && (
              <>
                <div style={{ display: 'block', width: '250px' }}>
                  <EuiProgress
                    value={item.progress}
                    valueText={`${item.progress}%`}
                    max={100}
                    size="m"
                    color="success"
                    key={item.key}
                  />
                </div>
              </>
            )}
          </>
        );
      },
      width: '250px',
    },
    {
      id: 'action',
      name: '',
      render: (item: any) => (
        <>
          {isError(item) && (
            <ActionError data-testid="upload-failed-message">
              <Icon color="white" name="x" size="l" />
            </ActionError>
          )}
          {isComplete(item) && (!isProcessing(item) || onlyShowNew) && !isError(item) && (
            <ActionComplete data-testid="upload-success-message">
              <Icon color="white" name="check" size="l" />
            </ActionComplete>
          )}
          {isUploading(item) && !isError(item) && (
            <ActionUploading>
              <Icon color="black" name="x" size="l" />
            </ActionUploading>
          )}
          {isProcessing(item) && !onlyShowNew && !isError(item) && (
            <ActionProcessing>
              <Icon color="black" name="loader" size="l" />
            </ActionProcessing>
          )}
        </>
      ),
      width: '48px',
    },
  ];

  useEffect(() => {
    /*
      The items are all the uploads and all the existing files in the smartfolders.

      The two lists of files (uploads and smartfolders documents) are merged together with the
      uploaded items taking precedence over the smartfolder items, but keeping the
      smartfolders classification.
      The goal is to prevent the upload items from re-sorting themselves as the createdAt time would
      be different - the uploads createdAt time is the start time of the upload, the smartfolder's
      createdAt time is the finish time of the upload.
      Finally the files are filtered to make sure that uploading files are always shown regardless
      of filters and that the filtering dropdown is respected when applicable.
    */

    setItems(
      (Object.values(uploads) || [])
        .map((value: any) => {
          const originalItem = data?.organizationDocumentsPage?.documents.find(
            (item) => item.id === value.id,
          );

          // If this exists from smart folders, use that classification
          const classification =
            originalItem?.classification !== null && originalItem?.classification !== undefined
              ? originalItem.classification
              : value.classification;

          return {
            ...value,
            age: moment().diff(moment(value.created_at), 'seconds'),
            classification,
          };
        })
        .sort((a: any, b: any) => (a?.date < b?.date ? 1 : -1))
        .concat(
          // Remove duplicate from the smartfolders
          (data?.organizationDocumentsPage?.documents || []).filter(
            (file) =>
              !Object.values(uploads).find(
                (item) =>
                  item.id === file.id || item.filename === `${file.filename}.${file.extension}`,
              ),
          ),
        )
        .filter(
          (file) =>
            file?.progress < 100 ||
            filterByClass === ALL_DOCUMENTS ||
            file?.classification === filterByClass ||
            (!file?.classification && filterByClass === 'Unknown'),
        ) as File[],
    );
  }, [data, uploads, filterByClass, queryCount]);

  useEffect(() => {
    const pending = items.filter(
      (item) => item.progress === 100 && item.classification === 'Pending',
    );
    if (pending.length > 0) {
      startPolling(1000);
    } else {
      stopPolling();
    }
  }, [items, queryCount]);

  const classifications = [
    ...new Set(
      (data?.organizationDocumentsPage?.documents || []).map(
        (doc) => doc?.classification || 'Unknown',
      ),
    ),
  ].sort((a: any, b: any) => (a > b ? 1 : -1));

  const classificationsOptions = [ALL_DOCUMENTS, ...classifications].map(
    (classification: string) => ({
      label:
        classification?.length > 4
          ? classification.charAt(0).toUpperCase() + classification.slice(1).toLowerCase()
          : classification.toUpperCase(),
      value: classification,
    }),
  );

  return (
    <>
      {(items?.length > 0 || filterByClass !== ALL_DOCUMENTS) && (
        <>
          {classifications?.length > 1 && (
            <div style={{ margin: '12px 0px 0px 0px', width: '275px' }}>
              <Select
                options={classificationsOptions}
                value={filterByClass}
                onChange={setFilterByClass}
              />
            </div>
          )}
          <EuiBasicTable
            items={isLibraryUploadsView ? items.filter((item) => item.progress < 100) : items}
            columns={columns}
            key={queryCount}
            data-testid="files-table"
          />
        </>
      )}
      {error && <EuiText>{error?.message}</EuiText>}
    </>
  );
};

export default FilesTable;
