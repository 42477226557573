import { css } from '@emotion/css';

const modal = css`
  height: 750px;
  left: 50%;
  max-width: inherit !important;
  position: absolute !important;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: none !important;
  width: 90%;
  .euiModal__flex {
    max-height: inherit !important;
  }
`;

const modalBody = css`
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export default {
  modal,
  modalBody,
};
