import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  ButtonEmpty,
  EuiContext,
  EuiDataGrid,
  EuiDataGridColumn,
  EuiDataGridRefProps,
  EuiProgress,
} from 'ui';
import { IGroupedProps } from '@app/components/PropertiesGroupedList/types';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { usePagination } from '@app/contexts/PaginationContext/PaginationContext';
import { SelectionProvider } from '@app/contexts/SelectionContext/SelectionContext';
import PropertyAttributesEditFlyout from '@app/cx/PropertyPage/PropertyAttributesEditFlyout';
import { resolveFilters } from '@app/cx/Stream/groupbyv2';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { usePageState } from '@app/cx/Stream/utils';
import { useStreamPropertiesPageQuery } from '@app/graphql/queries/streams/__generated__/StreamPropertiesPage.generated';
import {
  AttributeFilter,
  PropertyStatusType,
  SortOrder,
  StreamPropertiesPageInput,
} from '@app/graphql/types';
import useLocalStorage from '@app/hooks/useLocalStorage';
import { useTracker } from '@app/hooks/useTracker';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import { prepareColumn } from '../PropertiesGrid/utils';
import { SelectionButton } from '../SelectionButton/SelectionButton';
import { SelectionHeaderCell } from '../SelectionHeaderCell/SelectionHeaderCell';
import { SelectionRowCell } from '../SelectionRowCell/SelectionRowCell';
import { CellPopoverContent } from './CellPopoverContent/CellPopoverContent';
import { PropertiesDataGridProvider } from './context/PropertiesDataGridContext';
import { useGridDOMTrackers } from './hooks/useGridDOMTracker';
import { Cell } from './Cell';
import { Panel } from './PropertiesDataGrid.emotion';
import { IEffectiveMetaData } from './types';
import {
  defaultColumns,
  getAdjustedRowIndex,
  getMoveOnClick,
  getSortColumnsByVisibleColumnOrder,
  getTextWidth,
  sendMixpanelEvent,
} from './utils';

interface Props {
  group: IGroupedProps;
  filters: Array<AttributeFilter>;

  selectedProperties: Array<{ label: string; value: string }>;
}

export const PropertiesDataGrid: FC<Props> = ({ filters, group, selectedProperties }) => {
  const [isGridLoaded, setIsGridLoaded] = useState(false);
  const [initialColumnNames, setInitialColumnNames] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pageState] = usePageState();
  const [sortingColumns, setSortingColumns] = useState([]);
  const [isEditingCell, setIsEditingCell] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedColumnId, setSelectedColumnId] = useState('');

  const dataGridRef = useRef<EuiDataGridRefProps | null>(null);
  useGridDOMTrackers({ dataGridRef });

  const selectedPropertiesIds = selectedProperties.map((property) => property.value);
  const changesSince = pageState.changesSince;
  const { account } = useAuth();
  const { setPageSize, pagination, setPagination } = usePagination();

  const {
    propertyAttributeMetadata,
    stream,
    permissions,
    setRefetchPropertiesDataGrid,
    refetchPropertyGroups,
    isLatestSnapshot,
    marketVisibleToggle,
  } = useStreamContext();

  const isAdmin = !!account?.permissions?.admin;

  const canEditProperties = permissions?.canEditProperties && isLatestSnapshot;

  const isEditable = (isAdmin && !marketVisibleToggle) || canEditProperties;

  const compare: { compare?: StreamPropertiesPageInput['compare'] } = {};
  const tracker = useTracker();
  useEffect(() => {
    if (stream) {
      sendMixpanelEvent(tracker, 'Grid viewed', stream);

      return () => {
        sendMixpanelEvent(tracker, 'Grid closed', stream);
      };
    }
  }, [stream?.slug]);

  if (changesSince) {
    compare.compare = {
      fromSnapshotName: changesSince,
      type: group.label as PropertyStatusType,
    };
  }

  useEffect(() => {
    setPageSize(pagination.pageSize);
  }, [pagination.pageSize]);

  const { data, loading, refetch } = useStreamPropertiesPageQuery({
    variables: {
      input: {
        filter: resolveFilters(filters, pageState.groupByV2, selectedPropertiesIds, true),
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        snapshotName: pageState.currentSnapshot ? pageState.currentSnapshot : null,
        sortBy: sortingColumns.map((sc) => ({
          attributeName: sc.id,
          order: sc.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
        })),
        streamSlug: stream?.slug,
        ...compare,
      },
    },
  });

  useEffect(() => {
    setRefetchPropertiesDataGrid(() => refetch);
  }, [refetch]);

  const properties = data?.streamPropertiesPage?.properties || [];

  const attributeLocks = data?.streamPropertiesPage?.attributeLocks;

  const onChangeItemsPerPage = useCallback(
    (newPageSize) =>
      setPagination((pagination) => ({
        ...pagination,
        pageIndex: 0,
        pageSize: newPageSize,
      })),
    [setPagination],
  );
  const onChangePage = useCallback(
    (pageIndex) => setPagination((pagination) => ({ ...pagination, pageIndex })),
    [setPagination],
  );

  const [visibleColumns, setVisibleColumns, clearVisibleColumns] = useLocalStorage(
    account,
    `visibleColumns|${stream.id}.v1`,
    [],
  );

  const [columnWidths, setColumnWidths, clearColumnWidths] = useLocalStorage(
    account,
    `columnWidths|${stream.id}.v1`,
    {},
  );

  const handleSetVisibleColumns = (newColumns: string[]) => {
    setVisibleColumns((prev: string[]) => {
      if (prev.length > newColumns.length) {
        // column removed
        if (prev.length - newColumns.length > 1) {
          sendMixpanelEvent(tracker, 'Attributes - Hide all columns', stream);
        } else {
          const removedColumn = prev.filter((c) => !newColumns.includes(c))[0];
          sendMixpanelEvent(tracker, 'Attributes - Removed Column', stream, {
            removed_column: removedColumn,
          });
        }
      } else if (prev.length < newColumns.length) {
        if (newColumns.length - prev.length > 1) {
          sendMixpanelEvent(tracker, 'Attributes - Show all columns', stream);
        } else {
          const addedColumn = newColumns.filter((c) => !prev.includes(c))[0];
          sendMixpanelEvent(tracker, 'Attributes - Added Column', stream, {
            added_column: addedColumn,
          });
        }
      } else {
        sendMixpanelEvent(tracker, 'Attributes - Reordered columns', stream, {
          columns: newColumns,
        });
      }
      return newColumns;
    });
  };

  const effectivePropertyAttributeMetadata: IEffectiveMetaData = useMemo(
    () =>
      propertyAttributeMetadata.reduce(
        (acc, { name, displayName, parent, hiddenInGrid, ...rest }) => {
          if (hiddenInGrid) {
            return { ...acc };
          }

          const { formattingOptions, type } = prepareColumn({
            displayName,
            hiddenInGrid,
            name,
            parent,
            ...rest,
          });

          return {
            ...acc,
            [name]: {
              actions: {
                showHide: {
                  label: 'Hide column',
                  // override the original click behavior to prevent the grid from shifting back to the left
                  onClick: () => {
                    sendMixpanelEvent(tracker, 'Column Action - Hide column', stream, {
                      attribute: name,
                    });

                    const index = visibleColumns.indexOf(name);
                    setVisibleColumns((prev) => {
                      const next = [...prev];
                      if (index > -1) {
                        next.splice(index, 1);
                      }
                      return next;
                    });
                  },
                },
                showMoveLeft: {
                  label: 'Move left',
                  // override the original to add mixpanel tracking
                  onClick: getMoveOnClick(
                    'left',
                    name,
                    stream,
                    tracker,
                    visibleColumns,
                    setVisibleColumns,
                  ),
                },
                showMoveRight: {
                  label: 'Move right',
                  // override the original to add mixpanel tracking
                  onClick: getMoveOnClick(
                    'right',
                    name,
                    stream,
                    tracker,
                    visibleColumns,
                    setVisibleColumns,
                  ),
                },
              } as EuiDataGridColumn['actions'],

              displayAsText: displayName,

              formattingOptions,

              id: name,
              initialWidth: columnWidths[name] || getTextWidth(displayName),
              isExpandable: !isEditable,
              parent,
              type,
            } as EuiDataGridColumn,
          };
        },
        {},
      ),
    // without this memoization, the grid will experience performance issues, however, this memoization
    // is also causing issues rendering certain cells on the grid -- for example, the comments
    // button does not render the existing chats appropriately since it thinks the id is missing
    // the only way to fix this to add the properties array to the dependency array, but this causes
    // the grid to encounter peformance issues when the properties array change (for example in search)
    // therefore, we're using the properties.length as a proxy for the properties array, but this could
    // cause other issues in the future
    [propertyAttributeMetadata, visibleColumns, properties.length, marketVisibleToggle],
  );

  useEffect(() => {
    const newInitialColumnNames = [];
    const propertyAttributeMetadataSet = new Set(
      propertyAttributeMetadata.map((metadata) => metadata.name),
    );
    defaultColumns.forEach((name) => {
      // FIX ME
      // @ts-ignore
      if (propertyAttributeMetadataSet.has(name)) {
        newInitialColumnNames.push(name);
      }
    });
    setInitialColumnNames(newInitialColumnNames);
    if (!visibleColumns.length) {
      setVisibleColumns(newInitialColumnNames);
    }
  }, [propertyAttributeMetadata]);

  useEffect(() => {
    const columnOrder = visibleColumns?.length ? visibleColumns : initialColumnNames;
    const effectiveDataArray = Object.values(effectivePropertyAttributeMetadata).sort(
      getSortColumnsByVisibleColumnOrder(columnOrder),
    );
    setColumns(effectiveDataArray);
  }, [effectivePropertyAttributeMetadata, initialColumnNames]);

  const onSort = useCallback(
    (sortingColumns) => {
      if (!sortingColumns.length) {
        sendMixpanelEvent(tracker, 'Sort default', stream);
      } else {
        sendMixpanelEvent(tracker, 'Sort applied', stream, {
          filters: sortingColumns,
        });
      }
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns],
  );

  const selectedString = `Attributes (${visibleColumns.length}/${columns.length})`;

  const mappings = {
    en: {
      'euiColumnSelector.button': selectedString,
      'euiColumnSelector.buttonActivePlural': selectedString,
      'euiColumnSelector.buttonActiveSingular': selectedString,
    },
  };

  const i18n = {
    mapping: mappings.en,
  };

  useEffect(() => {
    if (!loading && !isGridLoaded) {
      setIsGridLoaded(true);
    }
  }, [loading]);

  if (!isGridLoaded) {
    return <LoadingSpinnerV2 />;
  }

  const propertyCount =
    group.filteredPropertyCount > properties.length
      ? group.filteredPropertyCount
      : properties.length;
  return (
    <SelectionProvider properties={properties} pagination={pagination}>
      <PropertiesDataGridProvider
        stream={stream}
        isEditingCell={isEditingCell}
        setIsEditingCell={setIsEditingCell}
        refetch={refetch}
        attributeLocks={attributeLocks}
        refetchGroups={refetchPropertyGroups}
      >
        <EuiContext i18n={i18n}>
          <Panel>
            {loading && (
              <EuiProgress size="xs" color="primary" position="absolute" style={{ zIndex: 3 }} />
            )}
            <EuiDataGrid
              // FIX ME
              // @ts-ignore
              key={marketVisibleToggle}
              ref={dataGridRef}
              aria-label="property data grid"
              style={{ zIndex: 9999 }}
              onColumnResize={(eventData) => {
                setColumnWidths((prevColumnWidths) => ({
                  ...prevColumnWidths,
                  [eventData.columnId]: eventData.width,
                }));
              }}
              leadingControlColumns={
                isAdmin || canEditProperties
                  ? [
                      {
                        headerCellRender: (props) => (
                          <SelectionHeaderCell {...props} data={properties} />
                        ),
                        id: 'selection',
                        rowCellRender: (props) => (
                          <SelectionRowCell {...props} data={properties} pagination={pagination} />
                        ),
                        width: 32,
                      },
                    ]
                  : undefined
              }
              pagination={{
                ...pagination,
                onChangeItemsPerPage,
                onChangePage,
                pageSizeOptions: [20, 40, 60],
              }}
              sorting={{ columns: sortingColumns, onSort }}
              columns={columns}
              toolbarVisibility={{
                additionalControls: {
                  left: {
                    prepend:
                      isAdmin || canEditProperties ? <SelectionButton group={group} /> : undefined,
                  },
                  right: (
                    <ButtonEmpty
                      size="m"
                      disabled={
                        Object.values(columnWidths).length === 0 &&
                        isEqual(visibleColumns, initialColumnNames)
                      }
                      onClick={(e) => {
                        sendMixpanelEvent(tracker, 'Reset view', stream);
                        e.stopPropagation();
                        clearVisibleColumns();
                        setVisibleColumns(initialColumnNames);
                        clearColumnWidths();
                        const effectiveDataArray = Object.values(
                          effectivePropertyAttributeMetadata,
                        ).sort(getSortColumnsByVisibleColumnOrder(initialColumnNames));
                        setColumns(effectiveDataArray);
                      }}
                      aria-label="reset view"
                      color="primary"
                      label="Reset view"
                    />
                  ),
                },
                showDisplaySelector: false,
                showFullScreenSelector: false,
                showKeyboardShortcuts: false,
                showSortSelector: false,
              }}
              columnVisibility={{
                setVisibleColumns: handleSetVisibleColumns,
                visibleColumns,
              }}
              rowCount={propertyCount}
              renderCellValue={(props) => (
                <Cell
                  // FIX ME
                  // @ts-ignore
                  key={marketVisibleToggle}
                  {...props}
                  properties={properties}
                  isEditable={isEditable}
                  pagination={pagination}
                  effectivePropertyAttributeMetadata={effectivePropertyAttributeMetadata}
                  setSelectedProperty={setSelectedProperty}
                  setSelectedColumnId={setSelectedColumnId}
                />
              )}
              renderCellPopover={(props) => {
                const { rowIndex } = props;
                const adjustedRowIndex = getAdjustedRowIndex(rowIndex, pagination);
                const property = properties?.[adjustedRowIndex];
                return (
                  // FIX ME
                  // @ts-ignore
                  <CellPopoverContent {...props} dataGridRef={dataGridRef} property={property} />
                );
              }}
            />
          </Panel>
        </EuiContext>
        {!isEmpty(selectedProperty) && (
          <PropertyAttributesEditFlyout
            property={selectedProperty}
            onClose={() => {
              setSelectedProperty(null);
              setSelectedColumnId('');
            }}
            attributeLocks={attributeLocks}
            columnId={selectedColumnId}
          />
        )}
      </PropertiesDataGridProvider>
    </SelectionProvider>
  );
};
