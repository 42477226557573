import { ToastProps } from 'ui/dist/atoms/display/toast/Toast.types';

export const convertBytesToMB = (sizeInBytes: number): number => {
  const sizeInMB = sizeInBytes / (1024 * 1024);
  return sizeInMB;
};

export const getFilteredFiles = (
  files: FileList | null,
  toast: (toast: ToastProps) => void,
): FileList => {
  const filteredFiles = new DataTransfer();
  const largeFilesNames = [];
  if (!files) {
    return new FileList();
  }

  if (files) {
    for (const file of files) {
      if (convertBytesToMB(file.size) < 1024) {
        filteredFiles.items.add(file);
      } else {
        largeFilesNames.push(file.name);
      }
    }
  }

  if (largeFilesNames.length > 0) {
    toast({
      label: `The following ${largeFilesNames.length > 1 ? 'files are' : 'file is'} too large to upload: ${largeFilesNames.join(', ')}`,
      title: 'Large files detected. Please upload files smaller than 1 GB',
    });
  }

  return filteredFiles.files;
};
