import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import {
  Button,
  ButtonEmpty,
  EuiDatePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiText,
  EuiTextColor,
  EuiTitle,
  Select,
  TextField,
  useEuiTheme,
} from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { ModalContext } from '@app/contexts/ModalContext';
import { GET_STREAM } from '@app/cx/DocumentsModal/getStream';
import { getIndustryEnumData } from '@app/cx/utils/utils';
import {
  getUpdateStreamInput,
  IUpdateStreamData,
  IUpdateStreamVariables,
} from '@app/queries/streams/inputs';
import { IGraphQLStream } from '@app/queries/streams/types';
// FIX ME
// @ts-ignore
import UPDATE_STREAM from '@app/queries/streams/updateStream.gql';
import ManageSnapshots from '../EditModal/ManageSnapshotsModal';
import { localFormatDate } from '../EditModal/Tabs/utils';

interface IEditSOVBasicInfoFlyoutProps {
  stream: IGraphQLStream;
}

const EditSOVBasicInfoFlyout = ({ stream }: IEditSOVBasicInfoFlyoutProps) => {
  const { closeFlyout } = useContext(FlyoutContext);
  const { showModal } = useContext(ModalContext);
  const { account } = useAuth();
  const { euiTheme } = useEuiTheme();
  const [copyOfStream, setCopyOfStream] = useState(stream);
  const [availableBusinessTypes, setAvailableBusinessTypes] = useState([stream?.businessType]);

  const [orgEnumData, setOrgEnumData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enumDataLoading, setEnumDataLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [updateStream] = useMutation<IUpdateStreamData, IUpdateStreamVariables>(UPDATE_STREAM);

  const fetchData = () =>
    getIndustryEnumData().then((data) => {
      setOrgEnumData(data);
      setEnumDataLoading(false);
    });

  useEffect(() => {
    fetchData();
    setLoading(false);
  }, []);

  useEffect(
    () => setAvailableBusinessTypes(getBusinessTypes(copyOfStream?.industry)),
    [enumDataLoading],
  );

  const industryOptions = orgEnumData?.map((industry: any) => ({
    label: industry.value,
    value: industry.value,
  }));

  const businessOptions =
    availableBusinessTypes?.map((businessType) => ({
      label: businessType,
      value: businessType,
    })) || [];

  const getBusinessTypes = (industry: string | undefined) => {
    const industryBusinesses = orgEnumData?.filter(
      (industryItem: any) => industryItem.value === industry,
    );

    return industryBusinesses[0]?.list;
  };

  const handleIndustryChange = (val?: string) => {
    setCopyOfStream({ ...copyOfStream, industry: val });
    setAvailableBusinessTypes(getBusinessTypes(val));
  };

  const formRowRequiredProps = (value: string) =>
    value === ''
      ? {
          error: ['This field is required'],
          isInvalid: true,
        }
      : {};

  const refetchQueries = [
    {
      query: GET_STREAM,
      variables: {
        isAdmin: account.permissions.admin,
        slug: stream.slug,
        userCode: account.userCode,
      },
    },
  ];

  const onSubmit = async () => {
    try {
      setIsSaving(true);
      await updateStream({
        refetchQueries: refetchQueries,
        variables: {
          input: getUpdateStreamInput(copyOfStream as any, message),
        },
      });
      setIsSaving(false);
      closeFlyout();
    } catch (error) {
      setIsSaving(false);
      console.error(error);
    }
  };

  const isUpdated = !isEqual(copyOfStream, stream);

  return (
    <EuiFlyout
      data-testid="document-edit-flyout"
      ownFocus
      onClose={closeFlyout}
      aria-labelledby="flyoutTitle"
      style={{ maxWidth: '600px' }}
    >
      <EuiFlyoutHeader style={{ borderBottom: euiTheme.border.thin, paddingBottom: '24px' }}>
        <EuiTitle size="s">
          <EuiText style={{ fontWeight: 600 }}>Edit SOV</EuiText>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <Button
              label="Manage snapshots"
              onClick={() => showModal(<ManageSnapshots stream={stream} />)}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup gutterSize="l" direction="column">
          <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-name">
            <EuiFormRow
              {...formRowRequiredProps(get(copyOfStream, 'name'))}
              fullWidth
              label="Enter Name"
            >
              <TextField
                fullWidth
                disabled={copyOfStream?.isMyProperties}
                value={get(copyOfStream, 'name') || ''}
                onChange={(event) => setCopyOfStream({ ...copyOfStream, name: event.target.value })}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-status">
            <EuiFormRow fullWidth label="Enter Status">
              <TextField
                fullWidth
                value={get(copyOfStream, 'status') || ''}
                onChange={(event) =>
                  setCopyOfStream({ ...copyOfStream, status: event.target.value })
                }
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-effective">
            <EuiFormRow fullWidth label="Effective Date">
              <EuiDatePicker
                fullWidth
                onChange={(date) => {
                  // convert to base utc time from timezone time
                  const utcDate = moment(date).utc().unix();
                  const newExpiryDate = moment(date).add(1, 'year').utc().unix();
                  setCopyOfStream({
                    ...copyOfStream,
                    effectiveDate: utcDate,
                    expiryDate: newExpiryDate,
                  });
                }}
                value={localFormatDate(get(copyOfStream, 'effectiveDate'))}
                selected={moment(localFormatDate(get(copyOfStream, 'effectiveDate')))}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-expiry">
            <EuiFormRow fullWidth label="Expiry Date">
              <EuiDatePicker
                fullWidth
                onChange={(date) => {
                  // convert to base utc time from timezone time
                  const utcDate = moment(date).utc().unix();
                  setCopyOfStream({
                    ...copyOfStream,
                    expiryDate: utcDate,
                  });
                }}
                value={localFormatDate(get(copyOfStream, 'expiryDate'))}
                selected={moment(localFormatDate(get(copyOfStream, 'expiryDate')))}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-industry">
            <EuiFormRow fullWidth label="Industry">
              <Select
                fullWidth
                loading={loading}
                onClear={() => handleIndustryChange(null)}
                onChange={(value) => handleIndustryChange(value)}
                options={industryOptions}
                value={copyOfStream?.industry}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem data-testid="stream-edit-modal-basic-info-tab-business-type">
            <EuiFormRow fullWidth label="Business Type">
              <Select
                fullWidth
                disabled={!businessOptions.length}
                options={businessOptions}
                loading={loading}
                onClear={() => setCopyOfStream({ ...copyOfStream, businessType: null })}
                onChange={(value) => setCopyOfStream({ ...copyOfStream, businessType: value })}
                value={copyOfStream?.businessType}
              />
            </EuiFormRow>
          </EuiFlexItem>
          {isUpdated && (
            <EuiFlexItem>
              <EuiFormRow
                fullWidth
                label={
                  <>
                    Reason for changes
                    <EuiTextColor color="danger">
                      {' ('}
                      <i>required</i>
                      {')'}
                    </EuiTextColor>
                  </>
                }
              >
                <TextField
                  data-testid="stream-change-reason"
                  fullWidth
                  onChange={(event) => {
                    setMessage(event.target?.value);
                  }}
                  value={message || ''}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <ButtonEmpty onClick={closeFlyout} label="Cancel" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <Button
              onClick={onSubmit}
              disabled={!message || isSaving}
              loading={isSaving}
              data-testid="document-submit"
              fill
              iconSide="right"
              label="Save"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
};

export default EditSOVBasicInfoFlyout;
