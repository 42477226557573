import { Route, Router } from 'react-router-dom';
import { UIProvider } from 'ui';
import { QueryParamProvider } from 'use-query-params';
import Apollo from '@app/components/Apollo/Apollo';
import { ChatProvider } from '@app/components/Chat/ChatProvider';
import { Helmet, HelmetProvider } from '@app/components/Helmet';
import Tracker from '@app/components/Tracker/Tracker';
import Auth from '@app/containers/Auth/Auth';
import { UserSessionProvider } from '@app/contexts/UserSessionContext';
import { AuthProvider } from '../AuthProvider/AuthProvider';
import { UpdateProvider } from '../UpdateProvider/UpdateProvider';
import Routes from './Routes/Routes';
import history from './history';
import { Widgets } from './Widgets';
import '!style-loader!css-loader!sass-loader!ui/src/index.scss';

const DefaultHelmet = () => <Helmet title="Archipelago" />;
// onRedirectCallback - redirect to targetUrl from the appstate, or the / from the current location
const onRedirectCallback = (appState: any) => {
  history.push(appState?.targetUrl ? appState.targetUrl : (window as any).location.pathname);
};

export default () => (
  <HelmetProvider>
    <UIProvider>
      <Tracker>
        <AuthProvider onRedirectCallback={onRedirectCallback}>
          <Router history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <Auth>
                <Widgets>
                  <Apollo>
                    <UserSessionProvider>
                      {() => (
                        <UpdateProvider>
                          <ChatProvider>
                            <DefaultHelmet />
                            <Routes />
                          </ChatProvider>
                        </UpdateProvider>
                      )}
                    </UserSessionProvider>
                  </Apollo>
                </Widgets>
              </Auth>
            </QueryParamProvider>
          </Router>
        </AuthProvider>
      </Tracker>
    </UIProvider>
  </HelmetProvider>
);
