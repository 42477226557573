export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  Time: { input: any; output: any };
  /** The `Upload` scalar type represents a multipart file upload. This is a built-in type in `gqlgen` */
  Upload: { input: any; output: any };
};

export type AddPreCheckUserInput = {
  email: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  type: PreCheckShareType;
};

export type AddPreCheckUserResponse = {
  __typename?: 'AddPreCheckUserResponse';
  email: Scalars['String']['output'];
  type: PreCheckShareType;
};

export type AllPreCheckUsersInput = {
  orgName: Scalars['String']['input'];
};

export type AllPreCheckUsersResponse = {
  __typename?: 'AllPreCheckUsersResponse';
  users: Array<PreCheckUser>;
};

export type AssignmentDetails = {
  assigneeEmails: Array<Scalars['String']['input']>;
  resourceIDs: Array<Scalars['String']['input']>;
};

export type Attribute = {
  __typename?: 'Attribute';
  attributeMetadata?: Maybe<AttributeMetadata>;
  documents?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  reasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recommendationType: RecommendationType;
};

export type AttributeFilter = {
  name: Scalars['String']['input'];
  operator?: InputMaybe<BinaryOperator>;
  /** A candidate object with any of these values will match this filter */
  values: Array<Scalars['String']['input']>;
};

export type AttributeFilterOutput = {
  __typename?: 'AttributeFilterOutput';
  name: Scalars['String']['output'];
  operator?: Maybe<BinaryOperator>;
  /** A candidate object with any of these values will match this filter */
  values: Array<Scalars['String']['output']>;
};

export type AttributeMetadata = {
  __typename?: 'AttributeMetadata';
  columnPosition: Scalars['Int']['output'];
  dataType: Scalars['String']['output'];
  decimals?: Maybe<Scalars['Int']['output']>;
  derived: Scalars['Boolean']['output'];
  dimension?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  displayWidth: Scalars['Int']['output'];
  enumMetadata?: Maybe<Array<EnumValueMetadata>>;
  filterable: Scalars['Boolean']['output'];
  groupable: Scalars['Boolean']['output'];
  hiddenInGrid: Scalars['Boolean']['output'];
  includeInStreams: Scalars['Boolean']['output'];
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  maxThreshold?: Maybe<Scalars['Int']['output']>;
  name: Scalars['ID']['output'];
  parent?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  subdimension?: Maybe<Scalars['String']['output']>;
};

export type AttributeRecommendation = {
  __typename?: 'AttributeRecommendation';
  definition?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BatchOperationResult = {
  __typename?: 'BatchOperationResult';
  success: Scalars['Boolean']['output'];
};

export type BatchTaskAssignmentInput = {
  assign?: InputMaybe<Array<InputMaybe<AssignmentDetails>>>;
  orgName: Scalars['String']['input'];
  unassign?: InputMaybe<Array<InputMaybe<AssignmentDetails>>>;
};

export enum BinaryOperator {
  Contains = 'CONTAINS',
  Equal = 'EQUAL',
  NotContains = 'NOT_CONTAINS',
  NotEqual = 'NOT_EQUAL',
}

export type BulkEditTasksProgressInput = {
  jobID: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
};

export type BulkEditTasksProgressResponse = {
  __typename?: 'BulkEditTasksProgressResponse';
  jobID: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type CreatePropertyRecommendationsInput = {
  /**  Deprecated. Always current property data is used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type CreatePropertyRecommendationsResponse = {
  __typename?: 'CreatePropertyRecommendationsResponse';
  mlRecommendationsStatus: RecommendationsStatus;
  streamRecommendationID: Scalars['String']['output'];
};

export type CreateValueCollectionConfigInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  editableAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate: Scalars['Date']['input'];
  filterAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  orgName: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  viewableAttributes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CustomAttributeInput = {
  ownerDecimal1?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal2?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal3?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal4?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal5?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal6?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal7?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal8?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal9?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal10?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal11?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal12?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal13?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal14?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal15?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal101?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal102?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal103?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal104?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal105?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal106?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal107?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal108?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal109?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal110?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal111?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal112?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal113?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal114?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal115?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal116?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal117?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal118?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal119?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal120?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal121?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal122?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal123?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal124?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal125?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal126?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal127?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal128?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal129?: InputMaybe<Scalars['Float']['input']>;
  ownerDecimal130?: InputMaybe<Scalars['Float']['input']>;
  ownerText1?: InputMaybe<Scalars['String']['input']>;
  ownerText2?: InputMaybe<Scalars['String']['input']>;
  ownerText3?: InputMaybe<Scalars['String']['input']>;
  ownerText4?: InputMaybe<Scalars['String']['input']>;
  ownerText5?: InputMaybe<Scalars['String']['input']>;
  ownerText6?: InputMaybe<Scalars['String']['input']>;
  ownerText7?: InputMaybe<Scalars['String']['input']>;
  ownerText8?: InputMaybe<Scalars['String']['input']>;
  ownerText9?: InputMaybe<Scalars['String']['input']>;
  ownerText10?: InputMaybe<Scalars['String']['input']>;
  ownerText11?: InputMaybe<Scalars['String']['input']>;
  ownerText12?: InputMaybe<Scalars['String']['input']>;
  ownerText13?: InputMaybe<Scalars['String']['input']>;
  ownerText14?: InputMaybe<Scalars['String']['input']>;
  ownerText15?: InputMaybe<Scalars['String']['input']>;
  ownerText101?: InputMaybe<Scalars['String']['input']>;
  ownerText102?: InputMaybe<Scalars['String']['input']>;
  ownerText103?: InputMaybe<Scalars['String']['input']>;
  ownerText104?: InputMaybe<Scalars['String']['input']>;
  ownerText105?: InputMaybe<Scalars['String']['input']>;
  ownerText106?: InputMaybe<Scalars['String']['input']>;
  ownerText107?: InputMaybe<Scalars['String']['input']>;
  ownerText108?: InputMaybe<Scalars['String']['input']>;
  ownerText109?: InputMaybe<Scalars['String']['input']>;
  ownerText110?: InputMaybe<Scalars['String']['input']>;
  ownerText111?: InputMaybe<Scalars['String']['input']>;
  ownerText112?: InputMaybe<Scalars['String']['input']>;
  ownerText113?: InputMaybe<Scalars['String']['input']>;
  ownerText114?: InputMaybe<Scalars['String']['input']>;
  ownerText115?: InputMaybe<Scalars['String']['input']>;
  ownerText116?: InputMaybe<Scalars['String']['input']>;
  ownerText117?: InputMaybe<Scalars['String']['input']>;
  ownerText118?: InputMaybe<Scalars['String']['input']>;
  ownerText119?: InputMaybe<Scalars['String']['input']>;
  ownerText120?: InputMaybe<Scalars['String']['input']>;
  ownerText121?: InputMaybe<Scalars['String']['input']>;
  ownerText122?: InputMaybe<Scalars['String']['input']>;
  ownerText123?: InputMaybe<Scalars['String']['input']>;
  ownerText124?: InputMaybe<Scalars['String']['input']>;
  ownerText125?: InputMaybe<Scalars['String']['input']>;
  ownerText126?: InputMaybe<Scalars['String']['input']>;
  ownerText127?: InputMaybe<Scalars['String']['input']>;
  ownerText128?: InputMaybe<Scalars['String']['input']>;
  ownerText129?: InputMaybe<Scalars['String']['input']>;
  ownerText130?: InputMaybe<Scalars['String']['input']>;
};

export type DataCompletenessByOrgInput = {
  orgID: Scalars['String']['input'];
};

export type DataCompletenessByOrgResponse = {
  __typename?: 'DataCompletenessByOrgResponse';
  CATData?: Maybe<ExposureData>;
  COPEData?: Maybe<ExposureData>;
  orgID: Scalars['String']['output'];
  taskStats?: Maybe<Array<TaskStat>>;
  valuationData?: Maybe<ExposureData>;
};

export type DeletePropertyRecommendationsInput = {
  /**  Deprecated. Always current property data is used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type DeletePropertyRecommendationsResponse = {
  __typename?: 'DeletePropertyRecommendationsResponse';
  deletedCount: Scalars['Int']['output'];
  mlRecommendationsStatus: RecommendationsStatus;
};

export type Document = {
  __typename?: 'Document';
  classification?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: UserProfile;
  extension?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  httpURL: Scalars['String']['output'];
  id: Scalars['String']['output'];
  origin?: Maybe<Scalars['String']['output']>;
  previewURL?: Maybe<Scalars['String']['output']>;
  sizeInBytes: Scalars['Int']['output'];
  sourcedAttributes?: Maybe<Array<AttributeMetadata>>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  thumbnailURLs?: Maybe<ImageUrLs>;
};

export type EnumValueMetadata = {
  __typename?: 'EnumValueMetadata';
  clusterValue?: Maybe<Scalars['String']['output']>;
  externalMappings?: Maybe<Array<ExternalMapping>>;
  hexColorCode?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  softMappings?: Maybe<Array<Maybe<SoftMapping>>>;
  value: Scalars['String']['output'];
  valueDisplay?: Maybe<Scalars['String']['output']>;
  valueID: Scalars['String']['output'];
};

/**
 *  THIS FILE IS COPIED FROM API REPO
 * Archipelago extensions to GraphQL error. This type defines the names of keys returned in GraphQL response
 * map field `errors.extensions` as defined in http://spec.graphql.org/October2021/#sec-Errors
 */
export enum ErrorExtension {
  /**  Denotes type of error. Value of this map key is a string -- see #ErrorType for possible values  */
  Code = 'Code',
  /**  Value of this key describes the error type  */
  Details = 'Details',
  /**
   * Value of this key contains an opaque id of the error instance for reference. Include this value when
   *   reporting any errors.
   */
  Id = 'ID',
}

/**  Possible values for ErrorExtension 'Code'  */
export enum ErrorType {
  /**
   * There was a unexpected error on the server while processing this request. If the request fails, repeatedly
   *   this should be reported including the `ID` field in the error.extensions field.
   */
  InternalServerError = 'InternalServerError',
  /**  Input provided for request is invalid. Caller should review Details field and modify the request.  */
  InvalidUserInput = 'InvalidUserInput',
  /**  Caller does not have permissions for requested resources. */
  UnAuthorized = 'UnAuthorized',
}

export type ExportBulkEditTasksInput = {
  assignees?: InputMaybe<Array<Scalars['String']['input']>>;
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgName: Scalars['String']['input'];
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
  sortBy?: InputMaybe<Array<Sort>>;
  /**  If specified, only export tasks with one of the specified statuses  */
  status?: InputMaybe<Array<TaskStatus>>;
  taskIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  taskType?: InputMaybe<TaskType>;
};

export type ExportBulkEditTasksResponse = {
  __typename?: 'ExportBulkEditTasksResponse';
  httpURL: Scalars['String']['output'];
};

export type ExposureData = {
  __typename?: 'ExposureData';
  TIVMissingData: Scalars['Float']['output'];
};

export enum ExternalCoding {
  Air = 'AIR',
  Atc = 'ATC',
  Default = 'DEFAULT',
  Iso = 'ISO',
  Naics = 'NAICS',
  Rms = 'RMS',
  RmsEq = 'RMS_EQ',
  Sic = 'SIC',
  Unknown = 'UNKNOWN',
}

export type ExternalMapping = {
  __typename?: 'ExternalMapping';
  externalCoding: ExternalCoding;
  value: Scalars['String']['output'];
  valueID: Scalars['String']['output'];
};

export type GenerateValueCollectionInput = {
  orgName: Scalars['String']['input'];
};

export type GenerateValueCollectionResponse = {
  __typename?: 'GenerateValueCollectionResponse';
  successful: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
};

export type GetTasksInput = {
  /**  Emails of assignees  */
  assignees?: InputMaybe<Array<Scalars['String']['input']>>;
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  pageOffset?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
  sortBy?: InputMaybe<Array<Sort>>;
  /**  If specified, only dismiss tasks with one of the specified statuses  */
  status?: InputMaybe<Array<TaskStatus>>;
  taskIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  taskType?: InputMaybe<TaskType>;
};

export type GetValueCollectionConfigInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  orgName: Scalars['String']['input'];
};

export type ImageUrLs = {
  __typename?: 'ImageURLs';
  medium: Scalars['String']['output'];
  original: Scalars['String']['output'];
  small: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Registers a user email as the specified type of PreCheck user.
   * Only for admins.
   */
  addPreCheckUser: AddPreCheckUserResponse;
  /** Assign one or more tasks to users */
  assignTasks: TaskResponse;
  /** Assign / Unassign one or more tasks to users by resource IDs */
  batchAssignUnassignTasks?: Maybe<BatchOperationResult>;
  /**
   * Generates pre-check recommendations for a stream snapshot.
   * Only for admins.
   */
  createPropertyRecommendations: CreatePropertyRecommendationsResponse;
  /** Create value collection config is used to define attributes and other info needed for tasks */
  createValueCollectionConfig?: Maybe<Scalars['ID']['output']>;
  /**
   * Deletes pre-check recommendations for a stream snapshot.
   * Only for admins.
   */
  deletePropertyRecommendations: DeletePropertyRecommendationsResponse;
  /** Dismiss one or more tasks */
  dismissTasks: TaskResponse;
  /** Generate value collection tasks */
  generateValueCollectionTasks: GenerateValueCollectionResponse;
  /** A notification is sent to the support alias indicating that user has requested for additional recommendations or AIR results. */
  requestPreCheckRecommendations: RequestPreCheckRecommendationsResponse;
  /**  Configure an organization's Pre-Check related settings  */
  setOrgPreCheckConfig: OrgPreCheckConfig;
  /**
   * Sets the property recommendations grade for my properties stream.
   * Only for admins.
   */
  setPropertyRecommendationsGrade: SetPropertyRecommendationsGradeResponse;
  /**
   * Assigns collaborators (other brokers) and prospects as Risk Manager on the organization and sends them an email
   * notification containing a link to the organization's pre-check page.
   * Reminder emails will be sent to prospects if recommendations are not queried by the user before then.
   */
  sharePreCheck: SharePreCheckResponse;
  /** Submit worksheet containing edits for processing */
  submitBulkEditTasks: SubmitBulkEditTasksResponse;
  /**
   * Submit task will apply the property attribute values to the platform and
   * send a completed task email to the assignee
   */
  submitTasks: SubmitTasksResponse;
  /** Un-dismiss one or more tasks */
  unDismissTasks: TaskResponse;
  /** Unassign one or more tasks to users */
  unassignTasks: TaskResponse;
  /** Update value collection config information */
  updateValueCollectionConfig: Scalars['Boolean']['output'];
};

export type MutationAddPreCheckUserArgs = {
  input: AddPreCheckUserInput;
};

export type MutationAssignTasksArgs = {
  input: TaskAssignmentInput;
};

export type MutationBatchAssignUnassignTasksArgs = {
  input: BatchTaskAssignmentInput;
};

export type MutationCreatePropertyRecommendationsArgs = {
  input: CreatePropertyRecommendationsInput;
};

export type MutationCreateValueCollectionConfigArgs = {
  input: CreateValueCollectionConfigInput;
};

export type MutationDeletePropertyRecommendationsArgs = {
  input: DeletePropertyRecommendationsInput;
};

export type MutationDismissTasksArgs = {
  input: TaskDismissalInput;
};

export type MutationGenerateValueCollectionTasksArgs = {
  input: GenerateValueCollectionInput;
};

export type MutationRequestPreCheckRecommendationsArgs = {
  input: RequestPreCheckRecommendationsInput;
};

export type MutationSetOrgPreCheckConfigArgs = {
  input: SetOrgPreCheckConfigInput;
};

export type MutationSetPropertyRecommendationsGradeArgs = {
  input: SetPropertyRecommendationsGradeInput;
};

export type MutationSharePreCheckArgs = {
  input: SharePreCheckInput;
};

export type MutationSubmitBulkEditTasksArgs = {
  input: SubmitBulkEditTasksInput;
};

export type MutationSubmitTasksArgs = {
  input: SubmitTasksInput;
};

export type MutationUnDismissTasksArgs = {
  input: TaskDismissalInput;
};

export type MutationUnassignTasksArgs = {
  input: TaskAssignmentInput;
};

export type MutationUpdateValueCollectionConfigArgs = {
  input: UpdateValueCollectionConfigInput;
};

export type OrgPreCheckConfig = {
  __typename?: 'OrgPreCheckConfig';
  /** Email domain which determines branding in Pre-Check for the org. */
  brandDomain?: Maybe<Scalars['String']['output']>;
  /** Enables org members to access Pre-Check. */
  enablePreCheck: Scalars['Boolean']['output'];
  orgID: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
};

export type OrgPreCheckConfigByOrgIDsInput = {
  orgIDs: Array<Scalars['ID']['input']>;
};

export type OrgPreCheckConfigInput = {
  orgName: Scalars['String']['input'];
};

/**  THIS FILE IS COPIED FROM API REPO  */
export type OwnerCustomAttributes = {
  __typename?: 'OwnerCustomAttributes';
  ownerDecimal1?: Maybe<Scalars['Float']['output']>;
  ownerDecimal2?: Maybe<Scalars['Float']['output']>;
  ownerDecimal3?: Maybe<Scalars['Float']['output']>;
  ownerDecimal4?: Maybe<Scalars['Float']['output']>;
  ownerDecimal5?: Maybe<Scalars['Float']['output']>;
  ownerDecimal6?: Maybe<Scalars['Float']['output']>;
  ownerDecimal7?: Maybe<Scalars['Float']['output']>;
  ownerDecimal8?: Maybe<Scalars['Float']['output']>;
  ownerDecimal9?: Maybe<Scalars['Float']['output']>;
  ownerDecimal10?: Maybe<Scalars['Float']['output']>;
  ownerDecimal11?: Maybe<Scalars['Float']['output']>;
  ownerDecimal12?: Maybe<Scalars['Float']['output']>;
  ownerDecimal13?: Maybe<Scalars['Float']['output']>;
  ownerDecimal14?: Maybe<Scalars['Float']['output']>;
  ownerDecimal15?: Maybe<Scalars['Float']['output']>;
  ownerDecimal101?: Maybe<Scalars['Float']['output']>;
  ownerDecimal102?: Maybe<Scalars['Float']['output']>;
  ownerDecimal103?: Maybe<Scalars['Float']['output']>;
  ownerDecimal104?: Maybe<Scalars['Float']['output']>;
  ownerDecimal105?: Maybe<Scalars['Float']['output']>;
  ownerDecimal106?: Maybe<Scalars['Float']['output']>;
  ownerDecimal107?: Maybe<Scalars['Float']['output']>;
  ownerDecimal108?: Maybe<Scalars['Float']['output']>;
  ownerDecimal109?: Maybe<Scalars['Float']['output']>;
  ownerDecimal110?: Maybe<Scalars['Float']['output']>;
  ownerDecimal111?: Maybe<Scalars['Float']['output']>;
  ownerDecimal112?: Maybe<Scalars['Float']['output']>;
  ownerDecimal113?: Maybe<Scalars['Float']['output']>;
  ownerDecimal114?: Maybe<Scalars['Float']['output']>;
  ownerDecimal115?: Maybe<Scalars['Float']['output']>;
  ownerDecimal116?: Maybe<Scalars['Float']['output']>;
  ownerDecimal117?: Maybe<Scalars['Float']['output']>;
  ownerDecimal118?: Maybe<Scalars['Float']['output']>;
  ownerDecimal119?: Maybe<Scalars['Float']['output']>;
  ownerDecimal120?: Maybe<Scalars['Float']['output']>;
  ownerDecimal121?: Maybe<Scalars['Float']['output']>;
  ownerDecimal122?: Maybe<Scalars['Float']['output']>;
  ownerDecimal123?: Maybe<Scalars['Float']['output']>;
  ownerDecimal124?: Maybe<Scalars['Float']['output']>;
  ownerDecimal125?: Maybe<Scalars['Float']['output']>;
  ownerDecimal126?: Maybe<Scalars['Float']['output']>;
  ownerDecimal127?: Maybe<Scalars['Float']['output']>;
  ownerDecimal128?: Maybe<Scalars['Float']['output']>;
  ownerDecimal129?: Maybe<Scalars['Float']['output']>;
  ownerDecimal130?: Maybe<Scalars['Float']['output']>;
  ownerText1?: Maybe<Scalars['String']['output']>;
  ownerText2?: Maybe<Scalars['String']['output']>;
  ownerText3?: Maybe<Scalars['String']['output']>;
  ownerText4?: Maybe<Scalars['String']['output']>;
  ownerText5?: Maybe<Scalars['String']['output']>;
  ownerText6?: Maybe<Scalars['String']['output']>;
  ownerText7?: Maybe<Scalars['String']['output']>;
  ownerText8?: Maybe<Scalars['String']['output']>;
  ownerText9?: Maybe<Scalars['String']['output']>;
  ownerText10?: Maybe<Scalars['String']['output']>;
  ownerText11?: Maybe<Scalars['String']['output']>;
  ownerText12?: Maybe<Scalars['String']['output']>;
  ownerText13?: Maybe<Scalars['String']['output']>;
  ownerText14?: Maybe<Scalars['String']['output']>;
  ownerText15?: Maybe<Scalars['String']['output']>;
  ownerText101?: Maybe<Scalars['String']['output']>;
  ownerText102?: Maybe<Scalars['String']['output']>;
  ownerText103?: Maybe<Scalars['String']['output']>;
  ownerText104?: Maybe<Scalars['String']['output']>;
  ownerText105?: Maybe<Scalars['String']['output']>;
  ownerText106?: Maybe<Scalars['String']['output']>;
  ownerText107?: Maybe<Scalars['String']['output']>;
  ownerText108?: Maybe<Scalars['String']['output']>;
  ownerText109?: Maybe<Scalars['String']['output']>;
  ownerText110?: Maybe<Scalars['String']['output']>;
  ownerText111?: Maybe<Scalars['String']['output']>;
  ownerText112?: Maybe<Scalars['String']['output']>;
  ownerText113?: Maybe<Scalars['String']['output']>;
  ownerText114?: Maybe<Scalars['String']['output']>;
  ownerText115?: Maybe<Scalars['String']['output']>;
  ownerText116?: Maybe<Scalars['String']['output']>;
  ownerText117?: Maybe<Scalars['String']['output']>;
  ownerText118?: Maybe<Scalars['String']['output']>;
  ownerText119?: Maybe<Scalars['String']['output']>;
  ownerText120?: Maybe<Scalars['String']['output']>;
  ownerText121?: Maybe<Scalars['String']['output']>;
  ownerText122?: Maybe<Scalars['String']['output']>;
  ownerText123?: Maybe<Scalars['String']['output']>;
  ownerText124?: Maybe<Scalars['String']['output']>;
  ownerText125?: Maybe<Scalars['String']['output']>;
  ownerText126?: Maybe<Scalars['String']['output']>;
  ownerText127?: Maybe<Scalars['String']['output']>;
  ownerText128?: Maybe<Scalars['String']['output']>;
  ownerText129?: Maybe<Scalars['String']['output']>;
  ownerText130?: Maybe<Scalars['String']['output']>;
};

export type PreCheckInvitee = {
  __typename?: 'PreCheckInvitee';
  email: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: PreCheckShareType;
};

export type PreCheckPermissions = {
  __typename?: 'PreCheckPermissions';
  canShareWithCollaborators: Scalars['Boolean']['output'];
  canShareWithProspects: Scalars['Boolean']['output'];
};

export enum PreCheckRecommendationStatus {
  BrokerAccepted = 'BROKER_ACCEPTED',
  New = 'NEW',
  ProspectAccepted = 'PROSPECT_ACCEPTED',
}

export type PreCheckRecommendationSummaryInput = {
  orgName: Scalars['String']['input'];
  /**  If snapshotName is not provided, the latest authorized snapshot with recommendations will be used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  /**  If streamSlug is not provided, the My Properties stream will be used.  */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type PreCheckRecommendationSummaryResponse = {
  __typename?: 'PreCheckRecommendationSummaryResponse';
  mlRecommendationsStatus: RecommendationsStatus;
  permissions: PreCheckPermissions;
  status: PreCheckRecommendationStatus;
};

export enum PreCheckRequestType {
  AdditionalRecommendations = 'ADDITIONAL_RECOMMENDATIONS',
  AirResults = 'AIR_RESULTS',
  CopePrefillData = 'COPE_PREFILL_DATA',
}

export type PreCheckShareInvite = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  /**  Type affects the notifications that the invitee receives.  */
  type: PreCheckShareType;
};

export enum PreCheckShareType {
  Broker = 'BROKER',
  Collaborator = 'COLLABORATOR',
  Editor = 'EDITOR',
  Prospect = 'PROSPECT',
  Viewer = 'VIEWER',
}

export type PreCheckUser = {
  __typename?: 'PreCheckUser';
  email: Scalars['String']['output'];
  type: PreCheckShareType;
  typeV2: PreCheckUserType;
};

export enum PreCheckUserType {
  Editor = 'Editor',
  Viewer = 'Viewer',
}

export type Property = {
  __typename?: 'Property';
  acquiredOrBuilt?: Maybe<Scalars['String']['output']>;
  acquisitionOrOccupancyDate?: Maybe<Scalars['Date']['output']>;
  airEqBrickVeneer?: Maybe<Scalars['String']['output']>;
  airEqBuildingCondition?: Maybe<Scalars['String']['output']>;
  airEqBuildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  airEqBuildingShape?: Maybe<Scalars['String']['output']>;
  airEqChimney?: Maybe<Scalars['String']['output']>;
  airEqColdFormedTub?: Maybe<Scalars['String']['output']>;
  airEqColumnBasementType?: Maybe<Scalars['String']['output']>;
  airEqEquipment?: Maybe<Scalars['String']['output']>;
  airEqFoundationConnection?: Maybe<Scalars['String']['output']>;
  airEqFoundationType?: Maybe<Scalars['String']['output']>;
  airEqInternalPartitions?: Maybe<Scalars['String']['output']>;
  airEqIsFireSprinklerAvailable?: Maybe<Scalars['String']['output']>;
  airEqIsValueType?: Maybe<Scalars['String']['output']>;
  airEqLatticeType?: Maybe<Scalars['String']['output']>;
  airEqMultiStoryHallType?: Maybe<Scalars['String']['output']>;
  airEqOrnamentation?: Maybe<Scalars['String']['output']>;
  airEqPounding?: Maybe<Scalars['String']['output']>;
  airEqRedundancy?: Maybe<Scalars['String']['output']>;
  airEqRetrofitMeasures?: Maybe<Scalars['String']['output']>;
  airEqShortColumn?: Maybe<Scalars['String']['output']>;
  airEqSoftStory?: Maybe<Scalars['String']['output']>;
  airEqSpecialEQResistiveSystems?: Maybe<Scalars['String']['output']>;
  airEqStructuralIrregularity?: Maybe<Scalars['String']['output']>;
  airEqTallOneStory?: Maybe<Scalars['String']['output']>;
  airEqTank?: Maybe<Scalars['String']['output']>;
  airEqTorsion?: Maybe<Scalars['String']['output']>;
  airEqTransitionInSRCType?: Maybe<Scalars['String']['output']>;
  airEqWallSiding?: Maybe<Scalars['String']['output']>;
  airEqWallType?: Maybe<Scalars['String']['output']>;
  airEqWaterHeater?: Maybe<Scalars['String']['output']>;
  airEqWeldingDetail?: Maybe<Scalars['String']['output']>;
  airModifiers?: Maybe<Scalars['String']['output']>;
  airWindAdjacentBuildingHeight?: Maybe<Scalars['String']['output']>;
  airWindAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevation?: Maybe<Scalars['String']['output']>;
  airWindBaseFloodElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindBasementFinishType?: Maybe<Scalars['String']['output']>;
  airWindBasementLevelCount?: Maybe<Scalars['String']['output']>;
  airWindCertifiedStructuresIbhs?: Maybe<Scalars['String']['output']>;
  airWindContentVulnerability?: Maybe<Scalars['String']['output']>;
  airWindCustomElevation?: Maybe<Scalars['String']['output']>;
  airWindCustomElevationUnit?: Maybe<Scalars['String']['output']>;
  airWindCustomFloodStandard?: Maybe<Scalars['Float']['output']>;
  airWindCustomFloodZone?: Maybe<Scalars['String']['output']>;
  airWindExteriorDoors?: Maybe<Scalars['String']['output']>;
  airWindFirmCompliance?: Maybe<Scalars['String']['output']>;
  airWindFirstFloorHeight?: Maybe<Scalars['Float']['output']>;
  airWindFirstFloorHeightUnit?: Maybe<Scalars['String']['output']>;
  airWindFloorOfInterest?: Maybe<Scalars['String']['output']>;
  airWindGlassPercentage?: Maybe<Scalars['String']['output']>;
  airWindGlassType?: Maybe<Scalars['String']['output']>;
  airWindLargeMissiles?: Maybe<Scalars['String']['output']>;
  airWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  airWindRoofAttachedStructures?: Maybe<Scalars['String']['output']>;
  airWindRoofCovering?: Maybe<Scalars['String']['output']>;
  airWindRoofCoveringAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofDeck?: Maybe<Scalars['String']['output']>;
  airWindRoofDeckAttachment?: Maybe<Scalars['String']['output']>;
  airWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  airWindRoofPitch?: Maybe<Scalars['String']['output']>;
  airWindRoofYearBuilt?: Maybe<Scalars['Int']['output']>;
  airWindSealOfApproval?: Maybe<Scalars['String']['output']>;
  airWindServiceEquipmentProtection?: Maybe<Scalars['String']['output']>;
  airWindSmallDebris?: Maybe<Scalars['String']['output']>;
  airWindTerrainRoughness?: Maybe<Scalars['String']['output']>;
  airWindTreeExposure?: Maybe<Scalars['String']['output']>;
  airWindWallAttachedStructure?: Maybe<Scalars['String']['output']>;
  airWindWindowProtection?: Maybe<Scalars['String']['output']>;
  archipelagoFloodZone?: Maybe<Scalars['String']['output']>;
  archipelagoId: Scalars['String']['output'];
  archipelagoSeismicZone?: Maybe<Scalars['String']['output']>;
  archipelagoWindZone?: Maybe<Scalars['String']['output']>;
  backupGenerator?: Maybe<Scalars['String']['output']>;
  backupGeneratorDieselStorageCapacity?: Maybe<Scalars['String']['output']>;
  backupGeneratorLocation?: Maybe<Scalars['String']['output']>;
  baseFloodElevation?: Maybe<Scalars['Float']['output']>;
  buildingCondition?: Maybe<Scalars['String']['output']>;
  buildingDescription?: Maybe<Scalars['String']['output']>;
  buildingExteriorOpening?: Maybe<Scalars['String']['output']>;
  buildingFootprintClass?: Maybe<Scalars['String']['output']>;
  buildingFoundationType?: Maybe<Scalars['String']['output']>;
  buildingRedundancy?: Maybe<Scalars['String']['output']>;
  buildingReplacementCost?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPartner?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifference?: Maybe<Scalars['Float']['output']>;
  buildingReplacementCostPercentDifferenceRange?: Maybe<Scalars['String']['output']>;
  buildingReplacementCostValueDifference?: Maybe<Scalars['Float']['output']>;
  buildingSprinklerType?: Maybe<Scalars['String']['output']>;
  buildingSprinklered?: Maybe<Scalars['String']['output']>;
  buildingValue?: Maybe<Scalars['Float']['output']>;
  buildingValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessContinuityPlans?: Maybe<Scalars['String']['output']>;
  businessIncomeValue?: Maybe<Scalars['Float']['output']>;
  businessIncomeValueDisplay?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCost?: Maybe<Scalars['Float']['output']>;
  businessInterruptionCostDisplay?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commodityHazardClass?: Maybe<Scalars['String']['output']>;
  constructionDescription?: Maybe<Scalars['String']['output']>;
  constructionQuality?: Maybe<Scalars['String']['output']>;
  constructionType?: Maybe<Scalars['String']['output']>;
  constructionTypeAIR?: Maybe<Scalars['String']['output']>;
  constructionTypeATC?: Maybe<Scalars['String']['output']>;
  constructionTypeArchipelago?: Maybe<Scalars['String']['output']>;
  constructionTypeCluster?: Maybe<Scalars['String']['output']>;
  constructionTypeISO?: Maybe<Scalars['String']['output']>;
  constructionTypeRMS?: Maybe<Scalars['String']['output']>;
  contentsReplacementCost?: Maybe<Scalars['Float']['output']>;
  contentsReplacementCostDisplay?: Maybe<Scalars['Float']['output']>;
  contentsVulnerabilityFlood?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityVerticalDistributionOfContents?: Maybe<Scalars['String']['output']>;
  contentsVulnerabilityWind?: Maybe<Scalars['String']['output']>;
  contingency?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionComments?: Maybe<Scalars['String']['output']>;
  contingentBusinessInterruptionFlag?: Maybe<Scalars['Boolean']['output']>;
  controlOfCombustiblesProgram?: Maybe<Scalars['String']['output']>;
  convectiveStormZone?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Time']['output']>;
  crippleWalls?: Maybe<Scalars['String']['output']>;
  currencyPair?: Maybe<Scalars['String']['output']>;
  customerProvidedGeocode?: Maybe<Scalars['Boolean']['output']>;
  dependencyComments?: Maybe<Scalars['String']['output']>;
  dependencyCoveragePercentage?: Maybe<Scalars['Float']['output']>;
  dependencyFlag?: Maybe<Scalars['Boolean']['output']>;
  dependencyValue?: Maybe<Scalars['Float']['output']>;
  dependencyValueDisplay?: Maybe<Scalars['Float']['output']>;
  dispositionDate?: Maybe<Scalars['Date']['output']>;
  distanceToCoast?: Maybe<Scalars['Float']['output']>;
  distanceToNearestBuilding?: Maybe<Scalars['Float']['output']>;
  distanceToNearestFault?: Maybe<Scalars['Float']['output']>;
  documents?: Maybe<Array<Document>>;
  doorAndFrameType?: Maybe<Scalars['String']['output']>;
  effectiveFrom?: Maybe<Scalars['Time']['output']>;
  electronicsValue?: Maybe<Scalars['Float']['output']>;
  electronicsValueDisplay?: Maybe<Scalars['Float']['output']>;
  elevationOfGroundOrBasementLevelMEPEquipment?: Maybe<Scalars['String']['output']>;
  emergencyFloodProtectionMeasures?: Maybe<Scalars['String']['output']>;
  enriched?: Maybe<Scalars['Boolean']['output']>;
  eqSpecialSystems?: Maybe<Scalars['String']['output']>;
  equipmentEarthquakeBracing?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<Scalars['Float']['output']>;
  exchangeRateDate?: Maybe<Scalars['Date']['output']>;
  exclusions?: Maybe<Scalars['String']['output']>;
  extraExpenseValue?: Maybe<Scalars['Float']['output']>;
  extraExpenseValueDisplay?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValue?: Maybe<Scalars['Float']['output']>;
  fineArtJewelryValueDisplay?: Maybe<Scalars['Float']['output']>;
  fireDetectionSystems?: Maybe<Scalars['String']['output']>;
  fireEstimatedMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProbableMaximumLoss?: Maybe<Scalars['Float']['output']>;
  fireProtectionDescription?: Maybe<Scalars['String']['output']>;
  fireProtectionInspectionProgram?: Maybe<Scalars['String']['output']>;
  firePumpBackupPeriod?: Maybe<Scalars['Float']['output']>;
  firePumpChurnRate?: Maybe<Scalars['Float']['output']>;
  firePumpExcessCapacity?: Maybe<Scalars['String']['output']>;
  firePumpFlowRate?: Maybe<Scalars['String']['output']>;
  firePumpPowerSupply?: Maybe<Scalars['String']['output']>;
  firePumpTestResults?: Maybe<Scalars['String']['output']>;
  firstFloorElevation?: Maybe<Scalars['Float']['output']>;
  firstFloorHeight?: Maybe<Scalars['Float']['output']>;
  flammableLiquidCapacity?: Maybe<Scalars['Float']['output']>;
  flammableLiquidDescription?: Maybe<Scalars['String']['output']>;
  flammableLiquidGasLineManagement?: Maybe<Scalars['String']['output']>;
  flammableLiquidProgram?: Maybe<Scalars['String']['output']>;
  flammableLiquidStorageLocation?: Maybe<Scalars['String']['output']>;
  flammableLiquidsOnsite?: Maybe<Scalars['String']['output']>;
  floodMissiles?: Maybe<Scalars['String']['output']>;
  floodZone?: Maybe<Scalars['String']['output']>;
  floorArea?: Maybe<Scalars['Float']['output']>;
  floorSystem?: Maybe<Scalars['String']['output']>;
  foundationToFrameConnection?: Maybe<Scalars['String']['output']>;
  generatorTestingProgram?: Maybe<Scalars['String']['output']>;
  geoCodeType?: Maybe<Scalars['String']['output']>;
  glassPercentage?: Maybe<Scalars['String']['output']>;
  glassType?: Maybe<Scalars['String']['output']>;
  hailHazardClass?: Maybe<Scalars['String']['output']>;
  hasSeismicInspectionReport?: Maybe<Scalars['Boolean']['output']>;
  hazardSummary?: Maybe<Scalars['Boolean']['output']>;
  heightOfRackStorage?: Maybe<Scalars['Float']['output']>;
  highHazardAreaSprinklerType?: Maybe<Scalars['String']['output']>;
  hotWorkProgram?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsResidual?: Maybe<Scalars['String']['output']>;
  hydrantFlowTestResultsStatic?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  improvementsValue?: Maybe<Scalars['Float']['output']>;
  improvementsValueDisplay?: Maybe<Scalars['Float']['output']>;
  inRackSprinklerType?: Maybe<Scalars['String']['output']>;
  interiorPartitions?: Maybe<Scalars['String']['output']>;
  inventoryValue?: Maybe<Scalars['Float']['output']>;
  inventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  landslideSusceptibility?: Maybe<Scalars['String']['output']>;
  lastEngineeringVisitDate?: Maybe<Scalars['Date']['output']>;
  lastValuationDate?: Maybe<Scalars['String']['output']>;
  latestRevisionId?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  leasedOrOwned?: Maybe<Scalars['String']['output']>;
  lightningHazardClass?: Maybe<Scalars['String']['output']>;
  liquefactionSusceptibility?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationId: Scalars['String']['output'];
  locationName?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  lossEngineeringReportPresent?: Maybe<Scalars['String']['output']>;
  machineryValue?: Maybe<Scalars['Float']['output']>;
  machineryValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValue?: Maybe<Scalars['Float']['output']>;
  miscBuildingReplacementCostValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValue?: Maybe<Scalars['Float']['output']>;
  miscBusinessInterruptionValueDisplay?: Maybe<Scalars['Float']['output']>;
  miscContentsValue?: Maybe<Scalars['Float']['output']>;
  miscContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  modelingAvailable?: Maybe<Scalars['Boolean']['output']>;
  multiTenant?: Maybe<Scalars['Boolean']['output']>;
  naturalGasAutomaticShutoff?: Maybe<Scalars['String']['output']>;
  naturalGasPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  numberOfBuildings?: Maybe<Scalars['Int']['output']>;
  numberOfFireHazardAreas?: Maybe<Scalars['Int']['output']>;
  numberOfHighPriorityRecommendations?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesAboveGround?: Maybe<Scalars['Int']['output']>;
  numberOfStoriesBelowGround?: Maybe<Scalars['Int']['output']>;
  numberOfUnits?: Maybe<Scalars['Int']['output']>;
  occupancyDescription?: Maybe<Scalars['String']['output']>;
  occupancyType?: Maybe<Scalars['String']['output']>;
  occupancyTypeATC?: Maybe<Scalars['String']['output']>;
  occupancyTypeNAICS?: Maybe<Scalars['String']['output']>;
  occupancyTypeSIC?: Maybe<Scalars['String']['output']>;
  orgName: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  organizationLevelFour?: Maybe<Scalars['String']['output']>;
  organizationLevelOne?: Maybe<Scalars['String']['output']>;
  organizationLevelThree?: Maybe<Scalars['String']['output']>;
  organizationLevelTwo?: Maybe<Scalars['String']['output']>;
  originalAddress?: Maybe<Scalars['String']['output']>;
  ornamentation?: Maybe<Scalars['String']['output']>;
  otherContentsValue?: Maybe<Scalars['Float']['output']>;
  otherContentsValueDisplay?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  ownerAttributes?: Maybe<OwnerCustomAttributes>;
  payrollValue?: Maybe<Scalars['Float']['output']>;
  payrollValueDisplay?: Maybe<Scalars['Float']['output']>;
  percentageSprinklered?: Maybe<Scalars['Float']['output']>;
  permanentFloodMeasuresPresent?: Maybe<Scalars['String']['output']>;
  portableFireExtinguisherProgram?: Maybe<Scalars['String']['output']>;
  portfolio?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryTenantNAICS?: Maybe<Scalars['String']['output']>;
  propertyManager?: Maybe<Scalars['String']['output']>;
  propertyName?: Maybe<Scalars['String']['output']>;
  propertyStatus?: Maybe<Scalars['String']['output']>;
  quakeScenarioEstimatedLoss?: Maybe<Scalars['Float']['output']>;
  quakeScenarioUpperLoss?: Maybe<Scalars['Float']['output']>;
  rackStoragePresent?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  remoteMonitoringOfSprinklerSystem?: Maybe<Scalars['Boolean']['output']>;
  replacementCostMethodology?: Maybe<Scalars['String']['output']>;
  replacementCostPerFloorAreaPartner?: Maybe<Scalars['Float']['output']>;
  replacementCostPerFloorAreaPartnerDifference?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootage?: Maybe<Scalars['Float']['output']>;
  replacementCostPerSquareFootageDisplay?: Maybe<Scalars['Float']['output']>;
  revisionId?: Maybe<Scalars['String']['output']>;
  rmsEqAppendagesAndOrnamentation?: Maybe<Scalars['String']['output']>;
  rmsEqBaseIsolation?: Maybe<Scalars['String']['output']>;
  rmsEqConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsEqContentsRateGradeEQ?: Maybe<Scalars['String']['output']>;
  rmsEqCrippleWalls?: Maybe<Scalars['String']['output']>;
  rmsEqEarthquakeSprinklerCoverageFlag?: Maybe<Scalars['String']['output']>;
  rmsEqEngineeredFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqEnvelopeOpening?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentEQBracing?: Maybe<Scalars['String']['output']>;
  rmsEqEquipmentSupportMaintenance?: Maybe<Scalars['String']['output']>;
  rmsEqExteriorWallsCladding?: Maybe<Scalars['String']['output']>;
  rmsEqFrameBoltedtoFoundation?: Maybe<Scalars['String']['output']>;
  rmsEqPounding?: Maybe<Scalars['String']['output']>;
  rmsEqPurlinAnchoringTiltUpRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqRedundancy?: Maybe<Scalars['String']['output']>;
  rmsEqShapeConfiguration?: Maybe<Scalars['String']['output']>;
  rmsEqShortColumnCondition?: Maybe<Scalars['String']['output']>;
  rmsEqSoftStory?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerLeakageSusceptibility?: Maybe<Scalars['String']['output']>;
  rmsEqSprinklerType?: Maybe<Scalars['String']['output']>;
  rmsEqStructuralUpgradeNonURM?: Maybe<Scalars['String']['output']>;
  rmsEqTank?: Maybe<Scalars['String']['output']>;
  rmsEqTorsion?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryPartition?: Maybe<Scalars['String']['output']>;
  rmsEqUnreinforcedMasonryRetrofit?: Maybe<Scalars['String']['output']>;
  rmsEqVerticalIrregularitySetbackandOverhangs?: Maybe<Scalars['String']['output']>;
  rmsModifiers?: Maybe<Scalars['String']['output']>;
  rmsOtherBIPreparedness?: Maybe<Scalars['String']['output']>;
  rmsOtherBIRedundancy?: Maybe<Scalars['String']['output']>;
  rmsOtherPercentageContentsBelowGrade?: Maybe<Scalars['Float']['output']>;
  rmsRoofSheathingAttachment?: Maybe<Scalars['String']['output']>;
  rmsWindBIDependency?: Maybe<Scalars['String']['output']>;
  rmsWindBasementProtection?: Maybe<Scalars['String']['output']>;
  rmsWindCauseOfLossFlag?: Maybe<Scalars['String']['output']>;
  rmsWindCladding?: Maybe<Scalars['String']['output']>;
  rmsWindCommercialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindConstructionQuality?: Maybe<Scalars['String']['output']>;
  rmsWindContentsRateGradeWind?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToFlood?: Maybe<Scalars['String']['output']>;
  rmsWindContentsVulnerabilityDueToWind?: Maybe<Scalars['String']['output']>;
  rmsWindCopingAndFlashingQuality?: Maybe<Scalars['String']['output']>;
  rmsWindFinishedFloorHeightAboveGround?: Maybe<Scalars['Float']['output']>;
  rmsWindFinishedFloorHeightMatchLevel?: Maybe<Scalars['Float']['output']>;
  rmsWindFirstFloorElevation?: Maybe<Scalars['Float']['output']>;
  rmsWindFloodMissiles?: Maybe<Scalars['String']['output']>;
  rmsWindFloodProtection?: Maybe<Scalars['String']['output']>;
  rmsWindFloorType?: Maybe<Scalars['String']['output']>;
  rmsWindFrameFoundationConnection?: Maybe<Scalars['String']['output']>;
  rmsWindGroundLevelEquipment?: Maybe<Scalars['String']['output']>;
  rmsWindOpeningProtection?: Maybe<Scalars['String']['output']>;
  rmsWindResidentialAppurtenantStructures?: Maybe<Scalars['String']['output']>;
  rmsWindResistanceDoors?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAge?: Maybe<Scalars['String']['output']>;
  rmsWindRoofAnchorage?: Maybe<Scalars['String']['output']>;
  rmsWindRoofEquipmentWindBracing?: Maybe<Scalars['String']['output']>;
  rmsWindRoofFramingType?: Maybe<Scalars['String']['output']>;
  rmsWindRoofGeometry?: Maybe<Scalars['String']['output']>;
  rmsWindRoofMaintenance?: Maybe<Scalars['String']['output']>;
  rmsWindRoofParapetsChimneys?: Maybe<Scalars['String']['output']>;
  rmsWindRoofSystemCovering?: Maybe<Scalars['String']['output']>;
  rmsWindVerticalExposureDistribution?: Maybe<Scalars['String']['output']>;
  rmsWindWindMissiles?: Maybe<Scalars['String']['output']>;
  roofChimneysAnchorage?: Maybe<Scalars['String']['output']>;
  roofCopingAndFlashing?: Maybe<Scalars['String']['output']>;
  roofDeckAnchorage?: Maybe<Scalars['String']['output']>;
  roofDeckType?: Maybe<Scalars['String']['output']>;
  roofDescription?: Maybe<Scalars['String']['output']>;
  roofDrainageType?: Maybe<Scalars['String']['output']>;
  roofEquipmentAnchorage?: Maybe<Scalars['String']['output']>;
  roofEstimatedReplacementYear?: Maybe<Scalars['Int']['output']>;
  roofGeometry?: Maybe<Scalars['String']['output']>;
  roofHailMitigation?: Maybe<Scalars['String']['output']>;
  roofImage?: Maybe<Scalars['String']['output']>;
  roofInspectionProgram?: Maybe<Scalars['String']['output']>;
  roofInstallYear?: Maybe<Scalars['Int']['output']>;
  roofParapets?: Maybe<Scalars['String']['output']>;
  roofScreensSignsAnchorageandBracing?: Maybe<Scalars['String']['output']>;
  roofSolarPanelCoverage?: Maybe<Scalars['String']['output']>;
  roofSolarPanelDescription?: Maybe<Scalars['String']['output']>;
  roofSolarPanelOwnership?: Maybe<Scalars['Float']['output']>;
  roofStructures?: Maybe<Scalars['String']['output']>;
  roofSystem?: Maybe<Scalars['String']['output']>;
  roofSystemAnchorage?: Maybe<Scalars['String']['output']>;
  roofingMaterialAnchorage?: Maybe<Scalars['String']['output']>;
  rooftopSolarPanels?: Maybe<Scalars['String']['output']>;
  rooftopWaterTanks?: Maybe<Scalars['String']['output']>;
  securityDescription?: Maybe<Scalars['String']['output']>;
  seismicDesignQuality?: Maybe<Scalars['String']['output']>;
  seismicDesignRValue?: Maybe<Scalars['Float']['output']>;
  seismicHazardClass?: Maybe<Scalars['String']['output']>;
  seismicInspectionDate?: Maybe<Scalars['Date']['output']>;
  seismicStatus?: Maybe<Scalars['String']['output']>;
  seismicZone?: Maybe<Scalars['String']['output']>;
  shortColumnConcrete?: Maybe<Scalars['String']['output']>;
  siteSoilClassification?: Maybe<Scalars['String']['output']>;
  smokingControlsProgram?: Maybe<Scalars['String']['output']>;
  softStory?: Maybe<Scalars['String']['output']>;
  sovSortOrder?: Maybe<Scalars['Int']['output']>;
  specificOccupancy?: Maybe<Scalars['String']['output']>;
  sprinklerHeadSize?: Maybe<Scalars['Float']['output']>;
  sprinklerHeadTemperatureRating?: Maybe<Scalars['String']['output']>;
  sprinklerLeakDetectionSystem?: Maybe<Scalars['Boolean']['output']>;
  sprinklerPipesSeismicallyBraced?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stockThroughputExcessInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputExcessInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  stockThroughputFactor?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValue?: Maybe<Scalars['Float']['output']>;
  stockThroughputInventoryValueDisplay?: Maybe<Scalars['Float']['output']>;
  storageArrangementDescription?: Maybe<Scalars['String']['output']>;
  streamMembership?: Maybe<Array<Scalars['String']['output']>>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  structuralDescription?: Maybe<Scalars['String']['output']>;
  structuralSystemUpgraded?: Maybe<Scalars['String']['output']>;
  structuralUpgradeType?: Maybe<Scalars['String']['output']>;
  suitabilityOfFireProtectionMeasures?: Maybe<Scalars['String']['output']>;
  surfaceRoughnessWind?: Maybe<Scalars['String']['output']>;
  tornadoHazardClass?: Maybe<Scalars['String']['output']>;
  torsion?: Maybe<Scalars['String']['output']>;
  totalInsuredValue?: Maybe<Scalars['Float']['output']>;
  totalInsuredValueDisplay?: Maybe<Scalars['Float']['output']>;
  tsunamiHazardClass?: Maybe<Scalars['String']['output']>;
  valuationCurrency?: Maybe<Scalars['String']['output']>;
  valueInspectionProgram?: Maybe<Scalars['String']['output']>;
  verticalIrregularity?: Maybe<Scalars['String']['output']>;
  wallCladdingSystem?: Maybe<Scalars['String']['output']>;
  waterHeaterBracing?: Maybe<Scalars['String']['output']>;
  waterSupplyFlowRate?: Maybe<Scalars['String']['output']>;
  waterSupplyTime?: Maybe<Scalars['Float']['output']>;
  wildfireHazardClass?: Maybe<Scalars['String']['output']>;
  wildfireZone?: Maybe<Scalars['String']['output']>;
  windHazardClass?: Maybe<Scalars['String']['output']>;
  windMissiles?: Maybe<Scalars['String']['output']>;
  windZone?: Maybe<Scalars['String']['output']>;
  windowProtection?: Maybe<Scalars['String']['output']>;
  windowType?: Maybe<Scalars['String']['output']>;
  yearBuilt?: Maybe<Scalars['Int']['output']>;
  yearLastUpgraded?: Maybe<Scalars['Int']['output']>;
  yearsInPortfolio?: Maybe<Scalars['Int']['output']>;
};

export type PropertyDataInput = {
  accessoryStructures?: InputMaybe<Scalars['String']['input']>;
  acquiredOrBuilt?: InputMaybe<Scalars['String']['input']>;
  acquisitionOrOccupancyDate?: InputMaybe<Scalars['Date']['input']>;
  airEqBrickVeneer?: InputMaybe<Scalars['String']['input']>;
  airEqBuildingCondition?: InputMaybe<Scalars['String']['input']>;
  airEqBuildingExteriorOpening?: InputMaybe<Scalars['String']['input']>;
  airEqBuildingShape?: InputMaybe<Scalars['String']['input']>;
  airEqChimney?: InputMaybe<Scalars['String']['input']>;
  airEqColdFormedTub?: InputMaybe<Scalars['String']['input']>;
  airEqColumnBasementType?: InputMaybe<Scalars['String']['input']>;
  airEqEquipment?: InputMaybe<Scalars['String']['input']>;
  airEqFoundationConnection?: InputMaybe<Scalars['String']['input']>;
  airEqFoundationType?: InputMaybe<Scalars['String']['input']>;
  airEqInternalPartitions?: InputMaybe<Scalars['String']['input']>;
  airEqIsFireSprinklerAvailable?: InputMaybe<Scalars['String']['input']>;
  airEqIsValueType?: InputMaybe<Scalars['String']['input']>;
  airEqLatticeType?: InputMaybe<Scalars['String']['input']>;
  airEqMultiStoryHallType?: InputMaybe<Scalars['String']['input']>;
  airEqOrnamentation?: InputMaybe<Scalars['String']['input']>;
  airEqPounding?: InputMaybe<Scalars['String']['input']>;
  airEqRedundancy?: InputMaybe<Scalars['String']['input']>;
  airEqRetrofitMeasures?: InputMaybe<Scalars['String']['input']>;
  airEqShortColumn?: InputMaybe<Scalars['String']['input']>;
  airEqSoftStory?: InputMaybe<Scalars['String']['input']>;
  airEqSpecialEQResistiveSystems?: InputMaybe<Scalars['String']['input']>;
  airEqStructuralIrregularity?: InputMaybe<Scalars['String']['input']>;
  airEqTallOneStory?: InputMaybe<Scalars['String']['input']>;
  airEqTank?: InputMaybe<Scalars['String']['input']>;
  airEqTorsion?: InputMaybe<Scalars['String']['input']>;
  airEqTransitionInSRCType?: InputMaybe<Scalars['String']['input']>;
  airEqWallSiding?: InputMaybe<Scalars['String']['input']>;
  airEqWallType?: InputMaybe<Scalars['String']['input']>;
  airEqWaterHeater?: InputMaybe<Scalars['String']['input']>;
  airEqWeldingDetail?: InputMaybe<Scalars['String']['input']>;
  airModifiers?: InputMaybe<Scalars['String']['input']>;
  airWindAdjacentBuildingHeight?: InputMaybe<Scalars['String']['input']>;
  airWindAppurtenantStructures?: InputMaybe<Scalars['String']['input']>;
  airWindBaseFloodElevation?: InputMaybe<Scalars['String']['input']>;
  airWindBaseFloodElevationUnit?: InputMaybe<Scalars['String']['input']>;
  airWindBasementFinishType?: InputMaybe<Scalars['String']['input']>;
  airWindBasementLevelCount?: InputMaybe<Scalars['String']['input']>;
  airWindCertifiedStructuresIbhs?: InputMaybe<Scalars['String']['input']>;
  airWindContentVulnerability?: InputMaybe<Scalars['String']['input']>;
  airWindCustomElevation?: InputMaybe<Scalars['String']['input']>;
  airWindCustomElevationUnit?: InputMaybe<Scalars['String']['input']>;
  airWindCustomFloodStandard?: InputMaybe<Scalars['Float']['input']>;
  airWindCustomFloodZone?: InputMaybe<Scalars['String']['input']>;
  airWindExteriorDoors?: InputMaybe<Scalars['String']['input']>;
  airWindFirmCompliance?: InputMaybe<Scalars['String']['input']>;
  airWindFirstFloorHeight?: InputMaybe<Scalars['Float']['input']>;
  airWindFirstFloorHeightUnit?: InputMaybe<Scalars['String']['input']>;
  airWindFloorOfInterest?: InputMaybe<Scalars['String']['input']>;
  airWindGlassPercentage?: InputMaybe<Scalars['String']['input']>;
  airWindGlassType?: InputMaybe<Scalars['String']['input']>;
  airWindLargeMissiles?: InputMaybe<Scalars['String']['input']>;
  airWindRoofAnchorage?: InputMaybe<Scalars['String']['input']>;
  airWindRoofAttachedStructures?: InputMaybe<Scalars['String']['input']>;
  airWindRoofCovering?: InputMaybe<Scalars['String']['input']>;
  airWindRoofCoveringAttachment?: InputMaybe<Scalars['String']['input']>;
  airWindRoofDeck?: InputMaybe<Scalars['String']['input']>;
  airWindRoofDeckAttachment?: InputMaybe<Scalars['String']['input']>;
  airWindRoofGeometry?: InputMaybe<Scalars['String']['input']>;
  airWindRoofPitch?: InputMaybe<Scalars['String']['input']>;
  airWindRoofYearBuilt?: InputMaybe<Scalars['Int']['input']>;
  airWindSealOfApproval?: InputMaybe<Scalars['String']['input']>;
  airWindServiceEquipmentProtection?: InputMaybe<Scalars['String']['input']>;
  airWindSmallDebris?: InputMaybe<Scalars['String']['input']>;
  airWindTerrainRoughness?: InputMaybe<Scalars['String']['input']>;
  airWindTreeExposure?: InputMaybe<Scalars['String']['input']>;
  airWindWallAttachedStructure?: InputMaybe<Scalars['String']['input']>;
  airWindWindowProtection?: InputMaybe<Scalars['String']['input']>;
  annualBaseRent?: InputMaybe<Scalars['Float']['input']>;
  annualEstimatedRent?: InputMaybe<Scalars['Float']['input']>;
  annualGrossProfit?: InputMaybe<Scalars['Float']['input']>;
  annualLossOfRevenue?: InputMaybe<Scalars['Float']['input']>;
  annualServiceCharge?: InputMaybe<Scalars['Float']['input']>;
  archipelagoFloodZone?: InputMaybe<Scalars['String']['input']>;
  archipelagoSeismicZone?: InputMaybe<Scalars['String']['input']>;
  archipelagoWindZone?: InputMaybe<Scalars['String']['input']>;
  backupGenerator?: InputMaybe<Scalars['String']['input']>;
  backupGeneratorDieselStorageCapacity?: InputMaybe<Scalars['String']['input']>;
  backupGeneratorLocation?: InputMaybe<Scalars['String']['input']>;
  baseFloodElevation?: InputMaybe<Scalars['Float']['input']>;
  basementFinishType?: InputMaybe<Scalars['String']['input']>;
  buildingCondition?: InputMaybe<Scalars['String']['input']>;
  buildingDescription?: InputMaybe<Scalars['String']['input']>;
  buildingExteriorOpening?: InputMaybe<Scalars['String']['input']>;
  buildingFootprintClass?: InputMaybe<Scalars['String']['input']>;
  buildingFoundationType?: InputMaybe<Scalars['String']['input']>;
  buildingRedundancy?: InputMaybe<Scalars['String']['input']>;
  buildingReplacementCost?: InputMaybe<Scalars['Float']['input']>;
  buildingReplacementCostPartner?: InputMaybe<Scalars['Float']['input']>;
  buildingReplacementCostPercentDifference?: InputMaybe<Scalars['Float']['input']>;
  buildingReplacementCostPercentDifferenceRange?: InputMaybe<Scalars['String']['input']>;
  buildingReplacementCostValueDifference?: InputMaybe<Scalars['Float']['input']>;
  buildingSprinklerType?: InputMaybe<Scalars['String']['input']>;
  buildingSprinklered?: InputMaybe<Scalars['String']['input']>;
  buildingValue?: InputMaybe<Scalars['Float']['input']>;
  businessContinuityPlans?: InputMaybe<Scalars['String']['input']>;
  businessIncomeValue?: InputMaybe<Scalars['Float']['input']>;
  businessInterruptionCost?: InputMaybe<Scalars['Float']['input']>;
  businessInterruptionPreparedness?: InputMaybe<Scalars['String']['input']>;
  businessInterruptionRedundancy?: InputMaybe<Scalars['String']['input']>;
  chimneys?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  commodityHazardClass?: InputMaybe<Scalars['String']['input']>;
  constructionDescription?: InputMaybe<Scalars['String']['input']>;
  constructionQuality?: InputMaybe<Scalars['String']['input']>;
  constructionType?: InputMaybe<Scalars['String']['input']>;
  constructionTypeAIR?: InputMaybe<Scalars['String']['input']>;
  constructionTypeATC?: InputMaybe<Scalars['String']['input']>;
  constructionTypeArchipelago?: InputMaybe<Scalars['String']['input']>;
  constructionTypeCluster?: InputMaybe<Scalars['String']['input']>;
  constructionTypeISO?: InputMaybe<Scalars['String']['input']>;
  constructionTypeRMS?: InputMaybe<Scalars['String']['input']>;
  contentsReplacementCost?: InputMaybe<Scalars['Float']['input']>;
  contentsVulnerabilityFlood?: InputMaybe<Scalars['String']['input']>;
  contentsVulnerabilityVerticalDistributionOfContents?: InputMaybe<Scalars['String']['input']>;
  contentsVulnerabilityWind?: InputMaybe<Scalars['String']['input']>;
  contingency?: InputMaybe<Scalars['String']['input']>;
  contingentBusinessInterruptionComments?: InputMaybe<Scalars['String']['input']>;
  contingentBusinessInterruptionFlag?: InputMaybe<Scalars['Boolean']['input']>;
  controlOfCombustiblesProgram?: InputMaybe<Scalars['String']['input']>;
  convectiveStormZone?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  crippleWalls?: InputMaybe<Scalars['String']['input']>;
  /** Risk Manager/Owner Custom Attributes */
  customAttributes?: InputMaybe<CustomAttributeInput>;
  customerProvidedGeocode?: InputMaybe<Scalars['Boolean']['input']>;
  dependencyComments?: InputMaybe<Scalars['String']['input']>;
  dependencyCoveragePercentage?: InputMaybe<Scalars['Float']['input']>;
  dependencyFlag?: InputMaybe<Scalars['Boolean']['input']>;
  dispositionDate?: InputMaybe<Scalars['Date']['input']>;
  distanceToCoast?: InputMaybe<Scalars['Float']['input']>;
  distanceToNearestBuilding?: InputMaybe<Scalars['Float']['input']>;
  distanceToNearestFault?: InputMaybe<Scalars['Float']['input']>;
  doorAndFrameType?: InputMaybe<Scalars['String']['input']>;
  effectiveFrom?: InputMaybe<Scalars['Time']['input']>;
  electronicsValue?: InputMaybe<Scalars['Float']['input']>;
  elevationOfGroundOrBasementLevelMEPEquipment?: InputMaybe<Scalars['String']['input']>;
  emergencyFloodProtectionMeasures?: InputMaybe<Scalars['String']['input']>;
  enriched?: InputMaybe<Scalars['Boolean']['input']>;
  eqSpecialSystems?: InputMaybe<Scalars['String']['input']>;
  equipmentEarthquakeBracing?: InputMaybe<Scalars['String']['input']>;
  exchangeRate?: InputMaybe<Scalars['Float']['input']>;
  exchangeRateDate?: InputMaybe<Scalars['Date']['input']>;
  exclusions?: InputMaybe<Scalars['String']['input']>;
  extraExpenseValue?: InputMaybe<Scalars['Float']['input']>;
  fineArtJewelryValue?: InputMaybe<Scalars['Float']['input']>;
  fireDetectionSystems?: InputMaybe<Scalars['String']['input']>;
  fireEstimatedMaximumLoss?: InputMaybe<Scalars['Float']['input']>;
  fireProbableMaximumLoss?: InputMaybe<Scalars['Float']['input']>;
  fireProtectionDescription?: InputMaybe<Scalars['String']['input']>;
  fireProtectionInspectionProgram?: InputMaybe<Scalars['String']['input']>;
  firePumpBackupPeriod?: InputMaybe<Scalars['Float']['input']>;
  firePumpChurnRate?: InputMaybe<Scalars['Float']['input']>;
  firePumpExcessCapacity?: InputMaybe<Scalars['String']['input']>;
  firePumpFlowRate?: InputMaybe<Scalars['String']['input']>;
  firePumpPowerSupply?: InputMaybe<Scalars['String']['input']>;
  firePumpTestResults?: InputMaybe<Scalars['String']['input']>;
  firstFloorElevation?: InputMaybe<Scalars['Float']['input']>;
  firstFloorHeight?: InputMaybe<Scalars['Float']['input']>;
  flammableLiquidCapacity?: InputMaybe<Scalars['Float']['input']>;
  flammableLiquidDescription?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidGasLineManagement?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidProgram?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidStorageLocation?: InputMaybe<Scalars['String']['input']>;
  flammableLiquidsOnsite?: InputMaybe<Scalars['String']['input']>;
  floodMissiles?: InputMaybe<Scalars['String']['input']>;
  floodZone?: InputMaybe<Scalars['String']['input']>;
  floorArea?: InputMaybe<Scalars['Float']['input']>;
  floorOfInterest?: InputMaybe<Scalars['Int']['input']>;
  floorSystem?: InputMaybe<Scalars['String']['input']>;
  foundationToFrameConnection?: InputMaybe<Scalars['String']['input']>;
  generatorTestingProgram?: InputMaybe<Scalars['String']['input']>;
  geoCodeType?: InputMaybe<Scalars['String']['input']>;
  glassPercentage?: InputMaybe<Scalars['String']['input']>;
  glassType?: InputMaybe<Scalars['String']['input']>;
  hailHazardClass?: InputMaybe<Scalars['String']['input']>;
  hasSeismicInspectionReport?: InputMaybe<Scalars['Boolean']['input']>;
  hazardSummary?: InputMaybe<Scalars['Boolean']['input']>;
  heightOfRackStorage?: InputMaybe<Scalars['Float']['input']>;
  highHazardAreaSprinklerType?: InputMaybe<Scalars['String']['input']>;
  hotWorkProgram?: InputMaybe<Scalars['String']['input']>;
  hydrantFlowTestResultsResidual?: InputMaybe<Scalars['String']['input']>;
  hydrantFlowTestResultsStatic?: InputMaybe<Scalars['String']['input']>;
  improvementsValue?: InputMaybe<Scalars['Float']['input']>;
  inRackSprinklerType?: InputMaybe<Scalars['String']['input']>;
  interiorPartitions?: InputMaybe<Scalars['String']['input']>;
  inventoryValue?: InputMaybe<Scalars['Float']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  landslideSusceptibility?: InputMaybe<Scalars['String']['input']>;
  lastEngineeringVisitDate?: InputMaybe<Scalars['Date']['input']>;
  lastValuationDate?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  leasedOrOwned?: InputMaybe<Scalars['String']['input']>;
  lightningHazardClass?: InputMaybe<Scalars['String']['input']>;
  liquefactionSusceptibility?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  lossEngineeringReportPresent?: InputMaybe<Scalars['String']['input']>;
  machineryValue?: InputMaybe<Scalars['Float']['input']>;
  miscBuildingReplacementCostValue?: InputMaybe<Scalars['Float']['input']>;
  miscBusinessInterruptionValue?: InputMaybe<Scalars['Float']['input']>;
  miscContentsValue?: InputMaybe<Scalars['Float']['input']>;
  modelingAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  multiTenant?: InputMaybe<Scalars['Boolean']['input']>;
  naturalGasAutomaticShutoff?: InputMaybe<Scalars['String']['input']>;
  naturalGasPipesSeismicallyBraced?: InputMaybe<Scalars['String']['input']>;
  numberOfBuildings?: InputMaybe<Scalars['Int']['input']>;
  numberOfFireHazardAreas?: InputMaybe<Scalars['Int']['input']>;
  numberOfHighPriorityRecommendations?: InputMaybe<Scalars['Int']['input']>;
  numberOfStoriesAboveGround?: InputMaybe<Scalars['Int']['input']>;
  numberOfStoriesBelowGround?: InputMaybe<Scalars['Int']['input']>;
  numberOfUnits?: InputMaybe<Scalars['Int']['input']>;
  occupancyDescription?: InputMaybe<Scalars['String']['input']>;
  occupancyType?: InputMaybe<Scalars['String']['input']>;
  occupancyTypeATC?: InputMaybe<Scalars['String']['input']>;
  occupancyTypeNAICS?: InputMaybe<Scalars['String']['input']>;
  occupancyTypeSIC?: InputMaybe<Scalars['String']['input']>;
  organizationLevelFour?: InputMaybe<Scalars['String']['input']>;
  organizationLevelOne?: InputMaybe<Scalars['String']['input']>;
  organizationLevelThree?: InputMaybe<Scalars['String']['input']>;
  organizationLevelTwo?: InputMaybe<Scalars['String']['input']>;
  originalAddress?: InputMaybe<Scalars['String']['input']>;
  ornamentation?: InputMaybe<Scalars['String']['input']>;
  otherContentsValue?: InputMaybe<Scalars['Float']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  payrollValue?: InputMaybe<Scalars['Float']['input']>;
  percentageSprinklered?: InputMaybe<Scalars['Float']['input']>;
  permanentFloodMeasuresPresent?: InputMaybe<Scalars['String']['input']>;
  portableFireExtinguisherProgram?: InputMaybe<Scalars['String']['input']>;
  portfolio?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryTenantNAICS?: InputMaybe<Scalars['String']['input']>;
  propertyManager?: InputMaybe<Scalars['String']['input']>;
  propertyName?: InputMaybe<Scalars['String']['input']>;
  propertyStatus?: InputMaybe<Scalars['String']['input']>;
  quakeScenarioEstimatedLoss?: InputMaybe<Scalars['Float']['input']>;
  quakeScenarioUpperLoss?: InputMaybe<Scalars['Float']['input']>;
  rackStoragePresent?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  remoteMonitoringOfSprinklerSystem?: InputMaybe<Scalars['Boolean']['input']>;
  rentAndServiceIndemnityPeriod?: InputMaybe<Scalars['Int']['input']>;
  replacementCostMethodology?: InputMaybe<Scalars['String']['input']>;
  replacementCostPerFloorAreaPartner?: InputMaybe<Scalars['Float']['input']>;
  replacementCostPerFloorAreaPartnerDifference?: InputMaybe<Scalars['Float']['input']>;
  replacementCostPerSquareFootage?: InputMaybe<Scalars['Float']['input']>;
  residentialGarage?: InputMaybe<Scalars['String']['input']>;
  rmsEqAppendagesAndOrnamentation?: InputMaybe<Scalars['String']['input']>;
  rmsEqBaseIsolation?: InputMaybe<Scalars['String']['input']>;
  rmsEqConstructionQuality?: InputMaybe<Scalars['String']['input']>;
  rmsEqContentsRateGradeEQ?: InputMaybe<Scalars['String']['input']>;
  rmsEqCrippleWalls?: InputMaybe<Scalars['String']['input']>;
  rmsEqEarthquakeSprinklerCoverageFlag?: InputMaybe<Scalars['String']['input']>;
  rmsEqEngineeredFoundation?: InputMaybe<Scalars['String']['input']>;
  rmsEqEnvelopeOpening?: InputMaybe<Scalars['String']['input']>;
  rmsEqEquipmentEQBracing?: InputMaybe<Scalars['String']['input']>;
  rmsEqEquipmentSupportMaintenance?: InputMaybe<Scalars['String']['input']>;
  rmsEqExteriorWallsCladding?: InputMaybe<Scalars['String']['input']>;
  rmsEqFrameBoltedtoFoundation?: InputMaybe<Scalars['String']['input']>;
  rmsEqPounding?: InputMaybe<Scalars['String']['input']>;
  rmsEqPurlinAnchoringTiltUpRetrofit?: InputMaybe<Scalars['String']['input']>;
  rmsEqRedundancy?: InputMaybe<Scalars['String']['input']>;
  rmsEqShapeConfiguration?: InputMaybe<Scalars['String']['input']>;
  rmsEqShortColumnCondition?: InputMaybe<Scalars['String']['input']>;
  rmsEqSoftStory?: InputMaybe<Scalars['String']['input']>;
  rmsEqSprinklerLeakageSusceptibility?: InputMaybe<Scalars['String']['input']>;
  rmsEqSprinklerType?: InputMaybe<Scalars['String']['input']>;
  rmsEqStructuralUpgradeNonURM?: InputMaybe<Scalars['String']['input']>;
  rmsEqTank?: InputMaybe<Scalars['String']['input']>;
  rmsEqTorsion?: InputMaybe<Scalars['String']['input']>;
  rmsEqUnreinforcedMasonryPartition?: InputMaybe<Scalars['String']['input']>;
  rmsEqUnreinforcedMasonryRetrofit?: InputMaybe<Scalars['String']['input']>;
  rmsEqVerticalIrregularitySetbackandOverhangs?: InputMaybe<Scalars['String']['input']>;
  rmsModifiers?: InputMaybe<Scalars['String']['input']>;
  rmsOtherBIPreparedness?: InputMaybe<Scalars['String']['input']>;
  rmsOtherBIRedundancy?: InputMaybe<Scalars['String']['input']>;
  rmsOtherPercentageContentsBelowGrade?: InputMaybe<Scalars['Float']['input']>;
  rmsRoofSheathingAttachment?: InputMaybe<Scalars['String']['input']>;
  rmsWindBIDependency?: InputMaybe<Scalars['String']['input']>;
  rmsWindBasementProtection?: InputMaybe<Scalars['String']['input']>;
  rmsWindCauseOfLossFlag?: InputMaybe<Scalars['String']['input']>;
  rmsWindCladding?: InputMaybe<Scalars['String']['input']>;
  rmsWindCommercialAppurtenantStructures?: InputMaybe<Scalars['String']['input']>;
  rmsWindConstructionQuality?: InputMaybe<Scalars['String']['input']>;
  rmsWindContentsRateGradeWind?: InputMaybe<Scalars['String']['input']>;
  rmsWindContentsVulnerabilityDueToFlood?: InputMaybe<Scalars['String']['input']>;
  rmsWindContentsVulnerabilityDueToWind?: InputMaybe<Scalars['String']['input']>;
  rmsWindCopingAndFlashingQuality?: InputMaybe<Scalars['String']['input']>;
  rmsWindFinishedFloorHeightAboveGround?: InputMaybe<Scalars['Float']['input']>;
  rmsWindFinishedFloorHeightMatchLevel?: InputMaybe<Scalars['Float']['input']>;
  rmsWindFirstFloorElevation?: InputMaybe<Scalars['Float']['input']>;
  rmsWindFloodMissiles?: InputMaybe<Scalars['String']['input']>;
  rmsWindFloodProtection?: InputMaybe<Scalars['String']['input']>;
  rmsWindFloorType?: InputMaybe<Scalars['String']['input']>;
  rmsWindFrameFoundationConnection?: InputMaybe<Scalars['String']['input']>;
  rmsWindGroundLevelEquipment?: InputMaybe<Scalars['String']['input']>;
  rmsWindOpeningProtection?: InputMaybe<Scalars['String']['input']>;
  rmsWindResidentialAppurtenantStructures?: InputMaybe<Scalars['String']['input']>;
  rmsWindResistanceDoors?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofAge?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofAnchorage?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofEquipmentWindBracing?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofFramingType?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofGeometry?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofMaintenance?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofParapetsChimneys?: InputMaybe<Scalars['String']['input']>;
  rmsWindRoofSystemCovering?: InputMaybe<Scalars['String']['input']>;
  rmsWindVerticalExposureDistribution?: InputMaybe<Scalars['String']['input']>;
  rmsWindWindMissiles?: InputMaybe<Scalars['String']['input']>;
  roofChimneysAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofCopingAndFlashing?: InputMaybe<Scalars['String']['input']>;
  roofDeckAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofDeckType?: InputMaybe<Scalars['String']['input']>;
  roofDescription?: InputMaybe<Scalars['String']['input']>;
  roofDrainageType?: InputMaybe<Scalars['String']['input']>;
  roofEquipmentAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofEstimatedReplacementYear?: InputMaybe<Scalars['Int']['input']>;
  roofGeometry?: InputMaybe<Scalars['String']['input']>;
  roofHailMitigation?: InputMaybe<Scalars['String']['input']>;
  roofImage?: InputMaybe<Scalars['String']['input']>;
  roofInspectionProgram?: InputMaybe<Scalars['String']['input']>;
  roofInstallYear?: InputMaybe<Scalars['Int']['input']>;
  roofParapets?: InputMaybe<Scalars['String']['input']>;
  roofScreensSignsAnchorageandBracing?: InputMaybe<Scalars['String']['input']>;
  roofSolarPanelCoverage?: InputMaybe<Scalars['String']['input']>;
  roofSolarPanelDescription?: InputMaybe<Scalars['String']['input']>;
  roofSolarPanelOwnership?: InputMaybe<Scalars['Float']['input']>;
  roofStructures?: InputMaybe<Scalars['String']['input']>;
  roofSystem?: InputMaybe<Scalars['String']['input']>;
  roofSystemAnchorage?: InputMaybe<Scalars['String']['input']>;
  roofingMaterialAnchorage?: InputMaybe<Scalars['String']['input']>;
  rooftopSolarPanels?: InputMaybe<Scalars['String']['input']>;
  rooftopWaterTanks?: InputMaybe<Scalars['String']['input']>;
  securityDescription?: InputMaybe<Scalars['String']['input']>;
  seismicDesignQuality?: InputMaybe<Scalars['String']['input']>;
  seismicDesignRValue?: InputMaybe<Scalars['Float']['input']>;
  seismicHazardClass?: InputMaybe<Scalars['String']['input']>;
  seismicInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  seismicStatus?: InputMaybe<Scalars['String']['input']>;
  seismicZone?: InputMaybe<Scalars['String']['input']>;
  shortColumnConcrete?: InputMaybe<Scalars['String']['input']>;
  siteSoilClassification?: InputMaybe<Scalars['String']['input']>;
  smokingControlsProgram?: InputMaybe<Scalars['String']['input']>;
  softStory?: InputMaybe<Scalars['String']['input']>;
  sovSortOrder?: InputMaybe<Scalars['Int']['input']>;
  specificOccupancy?: InputMaybe<Scalars['String']['input']>;
  sprinklerHeadSize?: InputMaybe<Scalars['Float']['input']>;
  sprinklerHeadTemperatureRating?: InputMaybe<Scalars['String']['input']>;
  sprinklerLeakDetectionSystem?: InputMaybe<Scalars['Boolean']['input']>;
  sprinklerPipesSeismicallyBraced?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  stockThroughputFactor?: InputMaybe<Scalars['Float']['input']>;
  storageArrangementDescription?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  structuralDescription?: InputMaybe<Scalars['String']['input']>;
  structuralSystemUpgraded?: InputMaybe<Scalars['String']['input']>;
  structuralUpgradeType?: InputMaybe<Scalars['String']['input']>;
  suitabilityOfFireProtectionMeasures?: InputMaybe<Scalars['String']['input']>;
  surfaceRoughnessWind?: InputMaybe<Scalars['String']['input']>;
  tornadoHazardClass?: InputMaybe<Scalars['String']['input']>;
  torsion?: InputMaybe<Scalars['String']['input']>;
  totalInsuredValue?: InputMaybe<Scalars['Float']['input']>;
  treeExposure?: InputMaybe<Scalars['String']['input']>;
  tsunamiHazardClass?: InputMaybe<Scalars['String']['input']>;
  valuationCurrency?: InputMaybe<Scalars['String']['input']>;
  valueInspectionProgram?: InputMaybe<Scalars['String']['input']>;
  verticalIrregularity?: InputMaybe<Scalars['String']['input']>;
  wallCladdingSystem?: InputMaybe<Scalars['String']['input']>;
  waterHeaterBracing?: InputMaybe<Scalars['String']['input']>;
  waterSupplyFlowRate?: InputMaybe<Scalars['String']['input']>;
  waterSupplyTime?: InputMaybe<Scalars['Float']['input']>;
  wildfireHazardClass?: InputMaybe<Scalars['String']['input']>;
  wildfireZone?: InputMaybe<Scalars['String']['input']>;
  windHazardClass?: InputMaybe<Scalars['String']['input']>;
  windMissiles?: InputMaybe<Scalars['String']['input']>;
  windZone?: InputMaybe<Scalars['String']['input']>;
  windowProtection?: InputMaybe<Scalars['String']['input']>;
  windowType?: InputMaybe<Scalars['String']['input']>;
  yearBuilt?: InputMaybe<Scalars['Int']['input']>;
  yearLastUpgraded?: InputMaybe<Scalars['Int']['input']>;
  yearsInPortfolio?: InputMaybe<Scalars['Int']['input']>;
};

export type PropertyRecommendation = {
  __typename?: 'PropertyRecommendation';
  attributes: Array<AttributeRecommendation>;
  property: Property;
  /**  A higher score indicates a higher priority for improvement.  */
  score?: Maybe<Scalars['Float']['output']>;
};

export type PropertyRecommendationBucket = {
  __typename?: 'PropertyRecommendationBucket';
  name: Scalars['String']['output'];
  properties: Array<PropertyRecommendation>;
};

export type PropertyRecommendationStatusResult = {
  __typename?: 'PropertyRecommendationStatusResult';
  /**  Status of property ML-based recommendations generated for the snapshot.  */
  mlRecommendationsStatus: RecommendationsStatus;
};

export type PropertyRecommendationsInput = {
  /**  Deprecated. Always current property data is used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type PropertyRecommendationsPageInput = {
  /**
   * Count to limit the number of returned properties. Maximum is 100. If provided value is more than 100,
   * server returns at most 100 results.
   */
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  /**  Deprecated. Always current property data is used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type PropertyRecommendationsPageResponse = {
  __typename?: 'PropertyRecommendationsPageResponse';
  properties: Array<PropertyRecommendation>;
};

export type PropertyRecommendationsResponse = {
  __typename?: 'PropertyRecommendationsResponse';
  buckets: Array<PropertyRecommendationBucket>;
  grade?: Maybe<Scalars['Float']['output']>;
};

export type ProspectRecommendationsInput = {
  orgName: Scalars['String']['input'];
  /**  Deprecated. Always current property data is used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  /**  Optional stream slug. If not provided, the My Properties stream is used.  */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /** Returns all PreCheck users for an org */
  allPreCheckUsers: AllPreCheckUsersResponse;
  /** Check the progress of bulk edit tasks processing */
  bulkEditTasksProgress: BulkEditTasksProgressResponse;
  /** Returns data completeness per organization */
  dataCompletenessByOrg: DataCompletenessByOrgResponse;
  /** Export bulk edit tasks as a worksheet for editing */
  exportBulkEditTasks: ExportBulkEditTasksResponse;
  /** Returns tasks for a given org or a specified task id */
  getTasks?: Maybe<TaskResponse>;
  /** Returns value collection configs for a given org */
  getValueCollectionConfig?: Maybe<ValueCollectionConfigResponse>;
  orgPreCheckConfig: OrgPreCheckConfig;
  orgPreCheckConfigByOrgIDs: Array<OrgPreCheckConfig>;
  /** Returns a summary of pre-check recommendations. */
  preCheckRecommendationSummary: PreCheckRecommendationSummaryResponse;
  /** Returns recommendations to improve the data quality of stream properties, grouped and ordered by priority. */
  propertyRecommendations: PropertyRecommendationsResponse;
  /** Returns a page of properties recommended for data quality improvement in order of priority rank. */
  propertyRecommendationsPage: PropertyRecommendationsPageResponse;
  /**
   * Returns status of recommendations for each snapshot in a stream.
   * Only for admins.
   */
  recommendationStatusByStream: RecommendationStatusByStreamResponse;
};

export type QueryAllPreCheckUsersArgs = {
  input: AllPreCheckUsersInput;
};

export type QueryBulkEditTasksProgressArgs = {
  input: BulkEditTasksProgressInput;
};

export type QueryDataCompletenessByOrgArgs = {
  input: DataCompletenessByOrgInput;
};

export type QueryExportBulkEditTasksArgs = {
  input: ExportBulkEditTasksInput;
};

export type QueryGetTasksArgs = {
  input: GetTasksInput;
};

export type QueryGetValueCollectionConfigArgs = {
  input: GetValueCollectionConfigInput;
};

export type QueryOrgPreCheckConfigArgs = {
  input: OrgPreCheckConfigInput;
};

export type QueryOrgPreCheckConfigByOrgIDsArgs = {
  input: OrgPreCheckConfigByOrgIDsInput;
};

export type QueryPreCheckRecommendationSummaryArgs = {
  input: PreCheckRecommendationSummaryInput;
};

export type QueryPropertyRecommendationsArgs = {
  input: PropertyRecommendationsInput;
};

export type QueryPropertyRecommendationsPageArgs = {
  input: PropertyRecommendationsPageInput;
};

export type QueryRecommendationStatusByStreamArgs = {
  input: RecommendationStatusByStreamInput;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  archipelagoID: Scalars['String']['output'];
  attribute?: Maybe<Array<Attribute>>;
  bucket?: Maybe<Scalars['Int']['output']>;
  priorityRank?: Maybe<Scalars['Int']['output']>;
  valuationOutlierReason?: Maybe<ValuationOutlierReason>;
};

export type RecommendationStatusByStreamInput = {
  streamSlug: Scalars['String']['input'];
};

export type RecommendationStatusByStreamResponse = {
  __typename?: 'RecommendationStatusByStreamResponse';
  snapshots: Array<SnapshotRecommendationStatus>;
};

export enum RecommendationType {
  MissingAttribute = 'MISSING_ATTRIBUTE',
  ValuationOutlier = 'VALUATION_OUTLIER',
  ValueCollection = 'VALUE_COLLECTION',
}

export enum RecommendationsStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NotFound = 'NOT_FOUND',
  Ready = 'READY',
  Timeout = 'TIMEOUT',
}

export type RequestPreCheckRecommendationsInput = {
  orgName: Scalars['String']['input'];
  requestType: PreCheckRequestType;
};

export type RequestPreCheckRecommendationsResponse = {
  __typename?: 'RequestPreCheckRecommendationsResponse';
  supportTicketChatRoomID: Scalars['String']['output'];
  supportTicketChatRoomName: Scalars['String']['output'];
};

export type SetOrgPreCheckConfigInput = {
  /** Email domain which determines branding in Pre-Check for the org. */
  brandDomain?: InputMaybe<Scalars['String']['input']>;
  /** Enables org members to access Pre-Check. */
  enablePreCheck: Scalars['Boolean']['input'];
  orgName: Scalars['String']['input'];
};

export type SetPropertyRecommendationsGradeInput = {
  grade?: InputMaybe<Scalars['Float']['input']>;
  /**  Deprecated. Always current property data is used.  */
  snapshotName?: InputMaybe<Scalars['String']['input']>;
  streamSlug: Scalars['String']['input'];
};

export type SetPropertyRecommendationsGradeResponse = {
  __typename?: 'SetPropertyRecommendationsGradeResponse';
  grade?: Maybe<Scalars['Float']['output']>;
};

export type SharePreCheckInput = {
  invitees: Array<PreCheckShareInvite>;
  orgName: Scalars['String']['input'];
  /**  If streamSlug is not provided, the My Properties stream will be used.  */
  streamSlug?: InputMaybe<Scalars['String']['input']>;
};

export type SharePreCheckResponse = {
  __typename?: 'SharePreCheckResponse';
  invitees: Array<PreCheckInvitee>;
};

export type SnapshotRecommendationStatus = {
  __typename?: 'SnapshotRecommendationStatus';
  snapshotID?: Maybe<Scalars['String']['output']>;
  status: RecommendationsStatus;
};

export type SoftMapping = {
  __typename?: 'SoftMapping';
  modelSchema: SoftMappingModelSchema;
  secondaryModifier: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum SoftMappingModelSchema {
  Air = 'AIR',
  Arch = 'ARCH',
  Rms = 'RMS',
}

export type Sort = {
  attribute: SortAttribute;
  order: SortOrder;
};

export enum SortAttribute {
  BuildingName = 'BUILDING_NAME',
  PriorityRank = 'PRIORITY_RANK',
  TotalInsuredValueDisplay = 'TOTAL_INSURED_VALUE_DISPLAY',
}

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type SubmitBulkEditTasksInput = {
  orgName: Scalars['String']['input'];
  /**  Worksheet containing edits.  */
  worksheet: Scalars['Upload']['input'];
};

export type SubmitBulkEditTasksResponse = {
  __typename?: 'SubmitBulkEditTasksResponse';
  jobID: Scalars['String']['output'];
};

export type SubmitTaskInput = {
  propertyArchipelagoID: Scalars['String']['input'];
  propertyData: PropertyDataInput;
  taskID: Scalars['String']['input'];
};

export type SubmitTasksInput = {
  orgName: Scalars['String']['input'];
  tasksInput: Array<SubmitTaskInput>;
};

export type SubmitTasksResponse = {
  __typename?: 'SubmitTasksResponse';
  message: Scalars['String']['output'];
  updateCount: Scalars['Int']['output'];
};

export type Task = {
  __typename?: 'Task';
  assignees?: Maybe<Array<Maybe<TaskAssignee>>>;
  id: Scalars['ID']['output'];
  property: Property;
  propertyRecommendation: Recommendation;
  status?: Maybe<TaskStatus>;
};

export type TaskAssignee = {
  __typename?: 'TaskAssignee';
  assignedDate?: Maybe<Scalars['Date']['output']>;
  assignee?: Maybe<Scalars['String']['output']>;
  userInfo?: Maybe<UserProfile>;
};

export type TaskAssignmentInput = {
  assigneeEmails: Array<Scalars['String']['input']>;
  /**  If specified, only assign tasks assigned to the specified assignees  */
  assignees?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  If specified, only assign tasks matching the filter  */
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgName: Scalars['String']['input'];
  /**  If specified, only assign tasks with the specified reasons  */
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  If specified, only assign tasks with one of the specified statuses  */
  status?: InputMaybe<Array<TaskStatus>>;
  /**  If specified, only assign tasks with the specified IDs  */
  taskIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  If specified, only assign tasks with this type  */
  taskType?: InputMaybe<TaskType>;
};

export type TaskDismissalInput = {
  /**  If specified, only dismiss tasks assigned to the specified assignees  */
  assignees?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  If specified, only dismiss tasks matching the filter  */
  filter?: InputMaybe<Array<AttributeFilter>>;
  orgName: Scalars['String']['input'];
  /**  If specified, only dismiss tasks with the specified reasons  */
  reasons?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  If specified, only dismiss tasks with one of the specified statuses  */
  status?: InputMaybe<Array<TaskStatus>>;
  /**  If specified, only dismiss tasks with these IDs  */
  taskIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  /**  If specified, only dismiss tasks with this type  */
  taskType?: InputMaybe<TaskType>;
};

export type TaskPropertyDataInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TaskResponse = {
  __typename?: 'TaskResponse';
  taskSummary?: Maybe<TaskSummary>;
  tasks: Array<Task>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TaskStat = {
  __typename?: 'TaskStat';
  status: TaskStatus;
  totalCount: Scalars['Int']['output'];
};

export enum TaskStatus {
  Completed = 'COMPLETED',
  Dismissed = 'DISMISSED',
  Failed = 'FAILED',
  Ready = 'READY',
  Started = 'STARTED',
}

export type TaskSummary = {
  __typename?: 'TaskSummary';
  filteredTaskTIV?: Maybe<Scalars['Float']['output']>;
};

export enum TaskType {
  PreCheck = 'PRE_CHECK',
  ValueCollection = 'VALUE_COLLECTION',
}

export type UpdateValueCollectionConfigInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  editableAttributes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  filterAttributes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['ID']['input'];
  orgName: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  viewableAttributes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**  THESE TYPE DEFINITIONS ARE COPIED FROM API REPO  */
export type UserProfile = {
  __typename?: 'UserProfile';
  active: Scalars['Boolean']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  email: Scalars['ID']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['Time']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pictureURL?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  userCode?: Maybe<Scalars['String']['output']>;
  userID: Scalars['ID']['output'];
};

export enum ValuationOutlierReason {
  Overvalued = 'Overvalued',
  Undervalued = 'Undervalued',
}

export type ValueCollectionConfig = {
  __typename?: 'ValueCollectionConfig';
  active: Scalars['Boolean']['output'];
  editableAttributes?: Maybe<Array<Scalars['String']['output']>>;
  endDate: Scalars['Date']['output'];
  filterAttributes?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  viewableAttributes?: Maybe<Array<Scalars['String']['output']>>;
};

export type ValueCollectionConfigResponse = {
  __typename?: 'ValueCollectionConfigResponse';
  configs?: Maybe<Array<ValueCollectionConfig>>;
};
