import { ITaskContext } from '../types';

interface QueryStringObject {
  name?: string;
  users?: string;
  reasons?: string;
  dismissed?: 'true' | 'false';
  noAssignee?: 'true' | 'false';
  pageSize?: string;
  page?: string;
}

interface Filters {
  pageOffset: number;
  pageSize: number;
  reasons?: ITaskContext['filters']['reasons'];
  dismissed?: boolean;
  assignees?: string[];
  name?: string;
  users?: string[];
  page?: number;
  noAssignee?: boolean;
}

// convert query string to filters that can be applied to getTasks query
export const parseQueryFilters = (
  queryStringObject: QueryStringObject = {},
  customAttributeFilters: any[] = [],
) => {
  const pageSize = parseInt(queryStringObject?.pageSize || '25');
  const page = parseInt(queryStringObject?.page || '0');

  const filters: Filters = {
    page,
    pageOffset: page * pageSize,
    pageSize,
  };

  filters.name = queryStringObject.name;

  filters.users = queryStringObject?.users?.length > 0 ? queryStringObject?.users?.split(',') : [];
  filters.reasons =
    queryStringObject?.reasons?.length > 0
      ? (queryStringObject?.reasons?.split(',') as ITaskContext['filters']['reasons'])
      : [];
  filters.dismissed = queryStringObject?.dismissed && queryStringObject?.dismissed === 'true';
  filters.noAssignee = queryStringObject?.noAssignee && queryStringObject?.noAssignee === 'true';

  customAttributeFilters?.forEach((attribute) => {
    if (queryStringObject[attribute.name]) {
      attribute.value = queryStringObject[attribute.name];
    }
  });

  return filters;
};
