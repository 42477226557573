import { Dispatch, SetStateAction, useContext } from 'react';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { getMetadataByType } from '@onarchipelago/cx/Stream/utils';
import { EuiFlexGroup, EuiText } from 'ui';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { useTracker } from '@app/hooks/useTracker';
import { FEATURE_TYPE_SOV_MANAGER_8 } from '@app/platform/SystemSettings/Flags/types';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import EditDataFlyout from '../EditDataFlyout/EditDataFlyout';
import { PanelItem } from './types';
import { getReadableDuration } from './utils';

interface Props {
  inProgressJobs: Partial<Record<EJobType, Lock>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

export default ({ inProgressJobs, setOpen, refetch }: Props): PanelItem => {
  const tracker = useTracker();
  const { showFlyout } = useContext(FlyoutContext);
  const { stream, propertyAttributeMetadata } = useStreamContext();
  const { search } = useLocation();
  const history = useHistory();
  const filterable = getMetadataByType(propertyAttributeMetadata, 'filterable');
  const isSOV8Enabled = isFeatureEnabled(stream?.enabledFeatures, FEATURE_TYPE_SOV_MANAGER_8);
  const inProgressJob = inProgressJobs[EJobType.Enrichment];
  const hasJobInProgress = !!inProgressJob;

  const handleClick = () => {
    tracker.track('Manage Properties - Edit', {
      in_progress: hasJobInProgress,
      stream_id: stream?.id,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    });

    showFlyout(
      <EditDataFlyout
        filterable={filterable}
        inProgressJob={inProgressJob}
        orgName={stream?.orgName as string}
        stream={stream as IGraphQLStream}
        jobType={EJobType.Enrichment}
        refetch={refetch}
      />,
    );

    setOpen(false);
  };

  const handleBulkEdit = () => {
    sendMixpanelEvent(
      tracker,
      hasJobInProgress
        ? 'Resume edit properties with an excel file'
        : 'Edit properties with an Excel file',
      stream,
    );
    if (isSOV8Enabled) {
      history.push({ pathname: encodeUrl`/streams/${stream.slug}/edit-properties`, search });
    } else {
      handleClick();
    }
  };

  const readableDuration = getReadableDuration(inProgressJob?.expiryTime);
  let bulkEditLabel: React.ReactNode = 'Edit properties with an Excel file';
  if (hasJobInProgress && inProgressJob.expiryTime) {
    bulkEditLabel = (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiText>Resume edit properties with an Excel file</EuiText>
        <EuiText color="subdued" size="s">
          {readableDuration} to resume
        </EuiText>
      </EuiFlexGroup>
    );
  }
  return {
    'data-testid': 'edit-properties-button',
    iconName: 'pencil',
    label: bulkEditLabel,
    onClick: handleBulkEdit,
  };
};
