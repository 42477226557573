import { memo } from 'react';
import { Toggle } from 'ui';
import { useStreamContext } from '../Stream/StreamProvider';

export const MarketVisibleToggle = memo(() => {
  const { showmarketVisibleToggle, handleSetMarketVisible, marketVisibleToggle } =
    useStreamContext();

  if (!showmarketVisibleToggle) {
    return null;
  }

  return (
    <Toggle
      checked={marketVisibleToggle}
      label="Market visible view"
      onChange={(e) => {
        handleSetMarketVisible(e.target.checked);
      }}
    />
  );
});
