import * as React from 'react';
import moment from 'moment';
import { EuiFlexGroup, EuiFlexItem, EuiListGroup, EuiListGroupItem, EuiPanel } from 'ui';
import { SupportTicket } from '@app/graphql/types';

interface Props {
  ticket: SupportTicket;
}

export const TicketPreview: React.FC<Props> = ({ ticket }) => (
  <EuiPanel hasShadow={false} hasBorder={false} paddingSize="l">
    <EuiFlexGroup direction="column">
      <EuiFlexItem>Initial message here</EuiFlexItem>
      <EuiFlexItem>
        Created by {ticket.authorEmail} on {moment(ticket.createdAt).format('YYYY-MM-DD HH:mm')}
      </EuiFlexItem>
      <EuiFlexItem>Assign yourself to this ticket to join the conversation</EuiFlexItem>
      <EuiFlexItem>
        Assigned users:
        <EuiListGroup title="Assigned support users">
          {ticket.assigneeEmails?.map((e) => <EuiListGroupItem key={e} label={e} />)}
        </EuiListGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiPanel>
);
